import React from "react";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide } from "react-toastify";

const Notification = ({ autoClose = 3000 }) =>
  createJsxComponent(autoClose);

const createJsxComponent = (autoClose) => {
  let notificationContainerJsx = (
    <ToastContainer
      style={{width: 'auto'}}
      autoClose={autoClose}
      transition={Slide}
      closeButton={false}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnVisibilityChange={false}
      draggable
      pauseOnHover
    />
  );
  return notificationContainerJsx;
};

Notification.propTypes = {
  autoClose: PropTypes.number,
};

export default Notification;
