import { connect } from "react-redux";
import SigningForm from "../components/SigningForm";
import { checkLogin, checkGoogleLogin } from "../actions";

const mapStateToProps = (state, ownProps) => ({
  logoImage: ownProps.logoImage
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  login: (user, password) => dispatch(checkLogin(user, password)),
  googleLogin: (token) => dispatch(checkGoogleLogin(token))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SigningForm);
