import * as types from '../constants/actionTypes';
import initialState from './initialState';
import merge from 'lodash/merge'

const invoice = (state = initialState.invoice, action) => {
  switch (action.type) {
    case types.INVOICE_IS_LOADED: {
      let invoice = {
        items: action.items,
        indebted: action.indebted
      };
      return merge(state.invoice, invoice);
    }     
    default:
      return state
  }
}
export default invoice