/**
 * Labels
 */
export const eventTypeLabels = {
    CIENCIA_OPERACAO: 'Ciência da Operação',
    CONFIRMACAO_OPERACAO: 'Confirmação da Operação',
    DESCONHECIMENTO_OPERACAO: 'Desconhecimento da Operação',
    OPERACAO_NAO_REALIZADA: 'Operação não Realizada'
}
/**
 * Values
 */
export const eventTypeValues = {
    CIENCIA_OPERACAO: '210210',
    CONFIRMACAO_OPERACAO: '210200',
    DESCONHECIMENTO_OPERACAO: '210220',
    OPERACAO_NAO_REALIZADA: '210240'
}
