
/**
 * CSS Classes
 */
export const cfeStatusCss = {
    AUTHORIZED: 'label-success',
    CANCELED: 'label-success'
  }
  
  /**
   * Labels
   */
  export const cfeStatusLabels = {

      ALL: 'Todos',
      AUTHORIZED: 'Autorizados',
      CANCELED: 'Cancelados'
  }
  