import * as apiEndpoints from '../constants/apiEndpoints';
import * as httpMethods from '../constants/httpMethods';
import storageUtils from "../utils/storageUtils";
import axios from 'axios';
import * as global from '../constants/global';

/**
 * Configurations API
 */
class ConfigurationsApi {  
    static getAll() {
      const CONFIGURATIONS_ENDPOINT = apiEndpoints.CONFIGURATIONS_ENDPOINT;
      let user = storageUtils.getItem('user');
      let productCode = global.AUTH_PRODUCT_CODE;
      let token = '';
      if (user !== null) {
        token = user.accessToken;
      }
      var formData = new FormData();
      formData.append('token', token);
      formData.append('product_code', productCode);
      return axios({
        method: httpMethods.REQUEST_METHOD_POST,
        url: CONFIGURATIONS_ENDPOINT,
        data: formData
      }).then((response) => {
        return response.data;
      }).catch((error) => {
        return error.response;
      });
    }
}
export default ConfigurationsApi;  