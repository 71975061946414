import * as types from '../constants/actionTypes';
import initialState from './initialState';
import merge from 'lodash/merge'

const topbar = (state = initialState.topbar, action) => {
  switch (action.type) {
    case types.TOPBAR_IS_LOADED:
      let topbar = {
        logoUrl: action.topbar.logoUrl,
        cnpj: action.topbar.cnpj,
        companyName: action.topbar.companyName,
        userName: action.topbar.userName
      };
      return merge(state.topbar, topbar);
    default:
      return state
  }
}

export default topbar
