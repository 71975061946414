
/**
 * Labels
 */
export const mdeStatusLabels = {
  NAO_MANIFESTADA: 'Não Manifestada',
  CIENCIA_OPERACAO: 'Ciência da Operação',
  CONFIRMACAO_OPERACAO: 'Confirmação da Operação',
  DESCONHECIMENTO_OPERACAO: 'Desconhecimento da Operação',
  OPERACAO_NAO_REALIZADA: 'Operação não Realizada'
}

/**
 * CSS Classes
 */
export const mdeStatusCss = {
  CIENCIA_OPERACAO: 'label-info',
  CONFIRMACAO_OPERACAO: 'label-success',
  DESCONHECIMENTO_OPERACAO: 'label-warning',
  OPERACAO_NAO_REALIZADA: 'label-danger',
  NAO_MANIFESTADA: 'label-default'
}

/**
 * Icons
 */
export const mdeStatusIcons = {
  CIENCIA_OPERACAO: 'fas fa-thumbs-up',
  CONFIRMACAO_OPERACAO: 'fas fa-check',
  DESCONHECIMENTO_OPERACAO: 'fas fa-question',
  OPERACAO_NAO_REALIZADA: 'fas fa-times',
  NAO_MANIFESTADA: 'fas fa-exclamation-circle'
}