import { connect } from "react-redux";
import VaritusApps from "../components/VaritusApps";

const mapStateToProps = (state, ownProps) => ({
  
});

const mapDispatchToProps = (dispatch, ownProps) => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VaritusApps);
