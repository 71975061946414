import { connect } from "react-redux";
import TextBox from "../../components/form/TextBox";

const mapStateToProps = (state, ownProps) => ({
    tabIndex: ownProps.tabIndex,
    name: ownProps.name,
    id: ownProps.id,
    type: ownProps.type,
    className: ownProps.className,
    value: ownProps.value,
    onChange: ownProps.onChange,
    onBlur: ownProps.onBlur,
    disabled: ownProps.disabled,
    maxLength: ownProps.maxLength,
    validationOption: {
      name: ownProps.validationOption.name,
      check: ownProps.validationOption.check,
      type: ownProps.validationOption.type,
      min: ownProps.validationOption.min,
      max: ownProps.validationOption.max,
      length: ownProps.validationOption.length,
      reg: ownProps.validationOption.reg,
      regMsg: ownProps.validationOption.regMsg,
      customFunc: ownProps.validationOption.customFunc,
      required: ownProps.validationOption.required,
      mail: ownProps.validationOption.mail
    }
});

const mapDispatchToProps = (dispatch, ownProps) => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TextBox);
