import * as types from '../constants/actionTypes';
import initialState from './initialState';
import merge from 'lodash/merge'
import storageUtils from "../utils/storageUtils";

const user = (state = storageUtils.getStateFromLocalStorage('user'), action) => {
  switch (action.type) {
    case types.USER_SIGN_IN: {
      if (action.user !== undefined) {
        let user = {
          userId: action.user.userId,
          username: action.user.username,
          isFirstAccess: action.user.isFirstAccess,
          accessToken: action.user.accessToken,
          tokenType: action.user.tokenType,
          trialAccessPlanInfo: action.user.trialAccessPlanInfo
        };
        storageUtils.setItem('user', user);
        return merge(state.user, user);
      }
      return state;
    }
    case types.COMPANY_IS_LOADED: {
      let user = {
        ...state,
        isFirstAccess: action.firstAccess
      };
      return merge(state.user, user);
    }


    case types.USER_SIGN_OUT: {
      storageUtils.clearAll();
      return merge(state.user, initialState.user);
    }
    default:
      return state
  }
}
export default user
  