import * as types from '../constants/actionTypes';
import * as global from '../constants/global';
import * as httpStatus from '../constants/httpStatus';
import usersApi from '../api/usersApi';
import configurationsApi from '../api/configurationsApi';
import certificateApi from '../api/certificateApi';
import nfeApi from '../api/nfeApi';
import nfceApi from '../api/nfceApi';
import mdfeApi from '../api/mdfeApi';
import cfeApi from '../api/cfeApi';
import cteApi from '../api/cteApi';
import cteosApi from '../api/cteosApi';
import subscriptionApi from '../api/subscriptionApi';
import arrayUtils from '../utils/arrayUtils';
import notificationUtils from '../utils/notificationUtils';
import storageUtils from '../utils/storageUtils';
import nfseApi from '../api/nfseApi';

import { oAuthHttpStatus, repositoryStatus } from '../enums/varitusResponseCodes';

/**
 * Configurations
 */
export const loadConfigurations = () => {
  return function (dispatch) {
    return configurationsApi
      .getAll()
      .then(configurations => {
        if (configurations !== undefined) {
          if (configurations.status === httpStatus.HTTP_STATUS_UNAUTHORIZED
            || configurations === undefined) {
            dispatch(userSignOut());
          }
          if (configurations.status === undefined) {
            let currentCompany = storageUtils.getItem('currentCompany');
            // Menu
            let menuConfig = configurations.find(config => config.type === 'menu');
            let items = [];
            if (menuConfig.data !== undefined) {
              if (menuConfig.data.items !== undefined) {
                items = arrayUtils.sort(menuConfig.data.items, 'order');
              }
            }
            dispatch(loadMenuSuccess(items));
            let logoUrl = '';
            if (currentCompany == null) {
              // Flavour
              let flavourConfig = configurations.find(config => config.type === 'flavour');
              if (flavourConfig.data !== undefined) {
                if (flavourConfig.data.logoUrl !== undefined) {
                  logoUrl = flavourConfig.data.logoUrl;
                }
              }
            } else {
              logoUrl = currentCompany.logoUrl;
            }

            // Company
            let cnpj = '';
            let companyName = '';
            if (currentCompany == null) {
              let companyConfig = configurations.find(config => config.type === 'company');
              if (companyConfig.data !== undefined) {
                if (companyConfig.data.items !== undefined) {
                  // Get first Company By Default
                  let firstCompany = companyConfig.data.items[0];
                  if (firstCompany !== undefined) {
                    storageUtils.setItem('currentCompany', firstCompany);
                    cnpj = firstCompany.cnpj;
                    companyName = firstCompany.companyName;
                  }
                }
              }
            } else {
              cnpj = currentCompany.cnpj;
              companyName = currentCompany.companyName;
            }
            dispatch(loadCertificateDispatcher());
            dispatch(loadAllGrids());
            dispatch(loadCustomerInvoices());

            let topbar = {
              logoUrl: logoUrl,
              cnpj: cnpj,
              companyName: companyName,
              userName: storageUtils.getItem('user').username
            };

            dispatch(loadTopbarSuccess(topbar));
          }
        } else {
          dispatch(userSignOut());
          notificationUtils.error("API de autenticação indisponível!");
        }
      })
      .catch(error => {
        throw error;
      });
  };
};
/**
 * Certificate
 */
export const loadCertificateDispatcher = () => {
  return function (dispatch) {
    certificateApi.getByCompany().then(response => {
      switch (response.status) {
        case oAuthHttpStatus.INVALID_TOKEN:
        case oAuthHttpStatus.INVALID_CLIENT_ID:
        case oAuthHttpStatus.INVALID_CREDENTIALS: {
          notificationUtils.error('Não foi possível carregar certificado!\n\nAcesso não autorizado!');
          break;
        }
        case repositoryStatus.OBJECT_NOT_FOUND: {
          notificationUtils.warn('A empresa atual não possui certificado!');
          dispatch(loadFirstAccess(true));
          break;
        }
        case repositoryStatus.OBJECT_SUCCESS_RETRIEVED: {
          break;
        }
        default:
          notificationUtils.error('Erro inesperado\n\nPor favor, tente novamente!');
          break;
      }
    }).catch(error => {
      throw error;
    });
  };
};


export const loadMenuSuccess = items => {
  return {
    type: types.CONFIGURATION_MENU_IS_LOADED,
    items
  };
};

export const loadTopbarSuccess = topbar => {
  return {
    type: types.TOPBAR_IS_LOADED,
    topbar
  };
};

/**
 * All Grids (NF-e, NFC-e, MDF-e, CT-e, CT-e OS)
 */
export const loadAllGrids = () => {
  return function (dispatch) {
    dispatch(loadNFe());
    dispatch(loadNFCe());
    dispatch(loadNFSe());
    dispatch(loadMDFe());
    dispatch(loadCTe());
    dispatch(loadCTeOS());
    dispatch(loadCFe());
  }
}

export const loadFirstAccess = firstAccess => {
  return {
    type:types.COMPANY_IS_LOADED,
    firstAccess
  }


}



/**
 * NF-e
 */
export const loadNFe = () => {
  return function (dispatch) {
    return nfeApi
      .getAll()
      .then(response => {
        if (response !== undefined) {
          let message = response.message;
          if (message !== undefined) {
            if (message.success) {
              dispatch(loadNFeGrid(response.nfes));
            } else {
              dispatch(loadNFeGrid([]));
            }
          }
        } else {
          notificationUtils.error("API de NF-e indisponível!");
        }
      })
      .catch(error => {
        throw error;
      });
  };
};

export const loadNFeGrid = items => {
  return {
    type: types.NFE_LIST_IS_LOADED,
    items
  };
};

export const loadNFeWhenEnvTypeIsChanged = (envType, dispatch) => {
  let nfeSearchRequest = storageUtils.getItem('nfeSearchRequest');
  nfeApi.filterBy(
    nfeSearchRequest.startDate,
    nfeSearchRequest.endDate,
    nfeSearchRequest.status,
    nfeSearchRequest.serie,
    nfeSearchRequest.startNumber,
    nfeSearchRequest.endNumber,
    nfeSearchRequest.accessKey,
    nfeSearchRequest.companyName,
    global.MAX_RECORDS_PER_PAGE,
    envType
  )
    .then(response => {
      if (response !== undefined) {
        dispatch(varitusApiEnvChanged(envType));
        let message = response.message;
        if (message !== undefined) {
          if (message.success) {
            dispatch(loadNFeGrid(response.nfes));
          } else {
            dispatch(loadNFeGrid([]));
          }
        }
      }
    })
    .catch(error => {
      throw error;
    });
}

/**
 * NFC-e
 */
export const loadNFCe = () => {
  return function (dispatch) {
    return nfceApi
      .findAll()
      .then(response => {
        if (response !== undefined) {
          let message = response.message;
          if (message !== undefined) {
            if (message.success) {
              dispatch(loadNFCeGrid(response.nfes));
            } else {
              dispatch(loadNFCeGrid([]));
            }
          }
        } else {
          notificationUtils.error("API de NFC-e indisponível!");
        }
      })
      .catch(error => {
        throw error;
      });
  };
};

export const loadNFCeGrid = items => {
  return {
    type: types.NFCE_LIST_IS_LOADED,
    items
  };
};

export const loadNFCeWhenEnvTypeIsChanged = (envType, dispatch) => {
  let nfceSearchRequest = storageUtils.getItem('nfceSearchRequest');
  nfceApi.findBy(
    nfceSearchRequest.startDate,
    nfceSearchRequest.endDate,
    nfceSearchRequest.status,
    nfceSearchRequest.serie,
    nfceSearchRequest.startNumber,
    nfceSearchRequest.endNumber,
    nfceSearchRequest.accessKey,
    nfceSearchRequest.companyName,
    global.MAX_RECORDS_PER_PAGE,
    envType
  ).then(response => {
    dispatch(varitusApiEnvChanged(envType));
    if (response !== undefined) {
      let message = response.message;
      if (message !== undefined) {
        if (message.success) {
          dispatch(loadNFCeGrid(response.nfces));
        } else {
          dispatch(loadNFCeGrid([]));
        }
      }
    }
  }).catch(error => {
    throw error;
  });
}

/**
 * MDF-e
 */
export const loadMDFe = () => {
  return function (dispatch) {
    return mdfeApi
      .getAll()
      .then(response => {
        if (response !== undefined) {
          let message = response.message;
          if (message !== undefined) {
            if (message.success) {
              dispatch(loadMDFeGrid(response.mdfes));
            } else {
              dispatch(loadMDFeGrid([]));
            }
          }
        } else {
          notificationUtils.error("API de MDF-e indisponível!");
        }
      })
      .catch(error => {
        throw error;
      });
  };
};

export const loadMDFeGrid = items => {
  return {
    type: types.MDFE_LIST_IS_LOADED,
    items
  };
};

export const loadMDFeWhenEnvTypeIsChanged = (envType, dispatch) => {
  let mdfeSearchRequest = storageUtils.getItem('mdfeSearchRequest');
  mdfeApi.filterBy(
    mdfeSearchRequest.startDate,
    mdfeSearchRequest.endDate,
    mdfeSearchRequest.status,
    mdfeSearchRequest.serie,
    mdfeSearchRequest.startNumber,
    mdfeSearchRequest.endNumber,
    mdfeSearchRequest.accessKey,
    mdfeSearchRequest.companyName,
    global.MAX_RECORDS_PER_PAGE,
    mdfeSearchRequest.periodType,
    mdfeSearchRequest.shipmentState,
    mdfeSearchRequest.landingState,
    mdfeSearchRequest.emissionType,
    mdfeSearchRequest.emailSentStatus,
    mdfeSearchRequest.damdfePrintedStatus,
    envType
  )
    .then(response => {
      dispatch(varitusApiEnvChanged(envType));
      if (response !== undefined) {
        let message = response.message;
        if (message !== undefined) {
          if (message.success) {
            dispatch(loadMDFeGrid(response.mdfes));
          } else {
            dispatch(loadMDFeGrid([]));
          }
        }
      }
    })
    .catch(error => {
      throw error;
    });
}

/**
 * CT-e
 */
export const loadCTe = () => {
  return function (dispatch) {
    return cteApi
      .getAll()
      .then(response => {
        if (response !== undefined) {
          let message = response.message;
          if (message !== undefined) {
            if (message.success) {
              dispatch(loadCTeGrid(response.ctes));
            } else {
              dispatch(loadCTeGrid([]));
            }
          }
        }
        else {
          notificationUtils.error("API de CT-e indisponível!");
        }
      })
      .catch(error => {
        throw error;
      });
  };
};

export const loadCTeGrid = items => {
  return {
    type: types.CTE_LIST_IS_LOADED,
    items
  };
};

export const loadCTeWhenEnvTypeIsChanged = (envType, dispatch) => {
  let cteSearchRequest = storageUtils.getItem('cteSearchRequest');
  cteApi.filterBy(
    cteSearchRequest.startDate,
    cteSearchRequest.endDate,
    cteSearchRequest.status,
    cteSearchRequest.serie,
    cteSearchRequest.startNumber,
    cteSearchRequest.endNumber,
    cteSearchRequest.accessKey,
    global.MAX_RECORDS_PER_PAGE,
    cteSearchRequest.shipmentState,
    cteSearchRequest.landingState,
    cteSearchRequest.emissionType,
    cteSearchRequest.serviceType,
    cteSearchRequest.personRole,
    cteSearchRequest.personCpfCnpj,
    cteSearchRequest.emailSentStatus,
    cteSearchRequest.dactePrintedStatus,
    envType
  )
    .then(response => {
      if (response !== undefined) {
        dispatch(varitusApiEnvChanged(envType));
        let message = response.message;
        if (message !== undefined) {
          if (message.success) {
            dispatch(loadCTeGrid(response.ctes));
          } else {
            dispatch(loadCTeGrid([]));
          }
        }
      }
    })
    .catch(error => {
      throw error;
    });
}

/**
 * CTe- OS
 */
export const loadCTeOS = () => {
  return function (dispatch) {
    return cteosApi
      .getAll()
      .then(response => {
        if (response !== undefined) {
          let message = response.message;
          if (message !== undefined) {
            if (message.success) {
              dispatch(loadCTeOSGrid(response.ctes));
            } else {
              dispatch(loadCTeOSGrid([]));
            }
          }
        } else {
          notificationUtils.error("API de CT-e OS indisponível!");
        }
      })
      .catch(error => {
        throw error;
      });
  };
};

export const loadCTeOSGrid = items => {
  return {
    type: types.CTE_OS_LIST_IS_LOADED,
    items
  };
};

export const loadCTeOSWhenEnvTypeIsChanged = (envType, dispatch) => {
  let cteosSearchRequest = storageUtils.getItem('cteosSearchRequest');
  cteosApi.filterBy(
    cteosSearchRequest.startDate,
    cteosSearchRequest.endDate,
    cteosSearchRequest.status,
    cteosSearchRequest.serie,
    cteosSearchRequest.startNumber,
    cteosSearchRequest.endNumber,
    cteosSearchRequest.accessKey,
    global.MAX_RECORDS_PER_PAGE,
    cteosSearchRequest.shipmentState,
    cteosSearchRequest.landingState,
    cteosSearchRequest.emissionType,
    cteosSearchRequest.serviceType,
    cteosSearchRequest.personRole,
    cteosSearchRequest.personCpfCnpj,
    cteosSearchRequest.emailSentStatus,
    cteosSearchRequest.dactePrintedStatus,
    envType
  )
    .then(response => {
      if (response !== undefined) {
        dispatch(varitusApiEnvChanged(envType));
        let message = response.message;
        if (message !== undefined) {
          if (message.success) {
            dispatch(loadCTeOSGrid(response.ctes));
          } else {
            dispatch(loadCTeOSGrid([]));
          }
        }
      }
    })
    .catch(error => {
      throw error;
    });
}


/**
 * CFe
 */
export const loadCFe = () => {
  return function (dispatch) {
    return cfeApi
      .getAll()
      .then(response => {
        if (response !== undefined) {
          let message = response.message;
          if (message !== undefined) {
            if (message.success) {
              dispatch(loadCFeGrid(response.cfes));
            } else {
              dispatch(loadCFeGrid([]));
            }
          }
        } else {
          notificationUtils.error("API de CF-e indisponível!");
        }
      })
      .catch(error => {
        throw error;
      });
  };
};

export const loadCFeGrid = items => {
  return {
    type: types.CFE_LIST_IS_LOADED,
    items
  };
};

export const loadCFeWhenEnvTypeIsChanged = (envType, dispatch) => {
  let cfeSearchRequest = storageUtils.getItem('cfeSearchRequest');

  cfeApi.filterBy(
    cfeSearchRequest.periodType,
    cfeSearchRequest.startDate,
    cfeSearchRequest.endDate,
    cfeSearchRequest.docType,
    cfeSearchRequest.receiverDocument,
    cfeSearchRequest.status,
    cfeSearchRequest.serie,
    cfeSearchRequest.startNumber,
    cfeSearchRequest.endNumber,
    cfeSearchRequest.emailSentStatus,
    cfeSearchRequest.cfePrintedStatus,
    cfeSearchRequest.accessKey,
    envType
  ).then(response => {
      if (response !== undefined) {
        dispatch(varitusApiEnvChanged(envType));
        let message = response.message;
        if (message !== undefined) {
          if (message.success) {
            dispatch(loadCFeGrid(response.cfes));
          } else {
            dispatch(loadCFeGrid([]));
          }
        }
      }
    })
    .catch(error => {
      throw error;
    });
}


/**
 * NFSe
 */
export const loadNFSe = () => {
  return function (dispatch) {
    return nfseApi
      .getAll()
      .then(response => {
        if (response !== undefined) {
          let message = response.message;
          if (message !== undefined) {
            if (message.success) {
              dispatch(loadNFSeGrid(response.nfses));
            } else {
              dispatch(loadNFSeGrid([]));
            }
          }
        } else {
          notificationUtils.error("API de NFS-e indisponível!");
        }
      })
      .catch(error => {
        throw error;
      });
  };
};

export const loadNFSeGrid = items => {
  return {
    type: types.NFSE_LIST_IS_LOADED,
    items
  };
};

export const loadNFSeWhenEnvTypeIsChanged = (envType, dispatch) => {
  let nfseSearchRequest = storageUtils.getItem('nfseSearchRequest');
  nfseApi.filterBy(
    nfseSearchRequest.periodType,
    nfseSearchRequest.initialDate,
    nfseSearchRequest.finalDate,
    nfseSearchRequest.sendType,
    nfseSearchRequest.nfseLocalStatus,
    nfseSearchRequest.emailSent,
    nfseSearchRequest.serie,
    nfseSearchRequest.nfseInitialNumber,
    nfseSearchRequest.nfseFinalNumber,
    nfseSearchRequest.rpsInitialNumber,
    nfseSearchRequest.rpsFinalNumber,
    nfseSearchRequest.manifest,
    nfseSearchRequest.cpfCnpj,
    nfseSearchRequest.companyName,
    envType    
  ).then(response => {
      if (response !== undefined) {
        dispatch(varitusApiEnvChanged(envType));
        let message = response.message;
        if (message !== undefined) {
          if (message.success) {
            dispatch(loadNFSeGrid(response.nfses));
          } else {
            dispatch(loadNFSeGrid([]));
          }
        }
      }
    })
    .catch(error => {
      throw error;
    });
}

/**
 * User
 */
export const checkLogin = (username, password) => {
  return function (dispatch) {
    usersApi.checkLogin(username, password).then(response => {
      if (response !== undefined) {
        if (response.status !== undefined) {
          notificationUtils.error("Usuário ou senha inválidos!")
          dispatch(userSignOut());
        } else {
          dispatch(userSignInDispatcher(response));
        }
      } else {
        notificationUtils.error("API de autenticação indisponível!")
      }
    });
  };
};

export const checkGoogleLogin = (token) => {
  return function (dispatch) {
    usersApi.checkGoogleLogin(token).then(response => {
      if (response !== undefined) {
        const message = response.message;
        if (!message.success) {
          notificationUtils.error(message.details)
          dispatch(userSignOut());
        } else {
          dispatch(userSignInDispatcher(response.oauthToken));
        }
      } else {
        notificationUtils.error("API de autenticação indisponível!")
      }
    });
  };
};




export const checkTrialPlan = () => {
  return function (dispatch) {
    let user = storageUtils.getItem('user');
    if (user.trialAccessPlanInfo.trialPeriodExpired === true) {
      notificationUtils.warn(
        "Seu período de testes acabou!\n"
        + "Por favor, "
        + "acesse a opção Mensalidade e cadastre o plano + forma de pagamento!"
      );
    }
    if (user.trialAccessPlanInfo.showExpiringTrialPeriodAlert === true) {
      notificationUtils.warn(
        "Seu período de testes termina em " + user.trialAccessPlanInfo.daysLeft + " dias!\n"
        + "Por favor, "
        + "acesse a opção Mensalidade e cadastre o plano + forma de pagamento!"
      );
    }
  }
}

export const userSignInDispatcher = (user = null) => {
  return function (dispatch) {
    dispatch(userSignIn(user));
    dispatch(checkTrialPlan());
    dispatch(loadConfigurations());
  };
}

export const userSignIn = (user = null) => {
  return {
    type: types.USER_SIGN_IN,
    user
  }
}

export const userSignOut = () => {
  return {
    type: types.USER_SIGN_OUT
  }
}

/**
 * Shared
 */
export const varitusApiEnvChangedDispatcher = (envType) => {
  return function (dispatch) {
    dispatch({type: types.NFE_LIST_IS_LOADING});
    dispatch({type: types.NFSE_LIST_IS_LOADING});
    dispatch({type: types.NFCE_LIST_IS_LOADING});
    dispatch({type: types.MDFE_LIST_IS_LOADING});
    dispatch({type: types.CTE_OS_LIST_IS_LOADING});
    dispatch({type: types.CTE_LIST_IS_LOADING});
    dispatch({type: types.CFE_LIST_IS_LOADING});

    loadNFeWhenEnvTypeIsChanged(envType, dispatch);
    loadNFCeWhenEnvTypeIsChanged(envType, dispatch);
    loadNFSeWhenEnvTypeIsChanged(envType, dispatch);
    loadMDFeWhenEnvTypeIsChanged(envType, dispatch);
    loadCTeWhenEnvTypeIsChanged(envType, dispatch);
    loadCTeOSWhenEnvTypeIsChanged(envType, dispatch);
    loadCFeWhenEnvTypeIsChanged(envType, dispatch);
  };
}


export const varitusApiEnvChanged = (envType) => {
  return {
    type: types.VARITUS_API_ENV_CHANGED,
    envType
  }
}

/**
 * Company
 */
export const changeCompanyDispatcher = (company) => {
  return function (dispatch) {
    let user = storageUtils.getItem('user');
    let topbar = {
      logoUrl: company.logoUrl,
      cnpj: company.cnpj,
      companyName: company.companyName,
      userName: user.username
    };
    dispatch(loadCertificateDispatcher());
    dispatch(loadAllGrids());
    dispatch(loadTopbarSuccess(topbar));
    // Login again
    user.isFirstAccess = company.isFirstAccess;
    dispatch(userSignIn(user));
  };
};

/**
 * Invoices
 */
export const loadCustomerInvoices = (limit) => {
  return function (dispatch) {
    subscriptionApi
      .getUserSubscriptionDetails()
      .then(subscriptionDetails => {
        if (subscriptionDetails !== undefined) {
          if (subscriptionDetails.status === httpStatus.HTTP_STATUS_OK) {
            if (subscriptionDetails.data !== undefined) {
              let subscriptionData = subscriptionDetails.data;
              if (subscriptionData.iuguClientId !== null) {
                let customerId = subscriptionData.iuguClientId;
                subscriptionApi.getCustomerInvoices(customerId, limit)
                  .then(invoices => {
                    if (invoices !== undefined) {
                      if (invoices.status === httpStatus.HTTP_STATUS_OK) {
                        if (invoices.data !== undefined) {
                          let data = invoices.data;
                          //
                          let items = data.gridItems;
                          let indebted = data.indebted;
                          dispatch(loadInvoiceGrid(items, indebted));
                        }
                      }
                    }
                  });
              }
            }
          }
        }
      });
  };
};

export const loadInvoiceGrid = (items, indebted) => {
  return {
    type: types.INVOICE_IS_LOADED,
    items,
    indebted
  }
}