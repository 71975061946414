import { connect } from 'react-redux'
import Grid from '../../components/grid/Grid'

const mapStateToProps = (state, ownProps) => ({
  items: ownProps.items,
  headerColumns: ownProps.headerColumns,
  showDetails: (ownProps.showDetails !== undefined 
    && ownProps.showDetails === true) ? true : false,
  translator: ownProps.translator,
  zebraStripedTable: ownProps.zebraStripedTable
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  ...ownProps.availableCallbacks,
  onScrollDown : (page) => ownProps.onScrollDown(page)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Grid)
