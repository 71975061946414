import React from 'react'
import PropTypes from 'prop-types'

import ResponsiveModal from "react-responsive-modal";
import '../../assets/modal/modal.css';

const Modal = (props) => (
  createJsxComponent(props)
)

const createJsxComponent = (props) => {
  const {visible, title, content, showHeader, onCloseClick} = props;

  let header = null;
  if (showHeader) {
    header = (
      <div className="basic-modal-header">
        <h1 className="title">{title}</h1>
        <button className="btn pull-right"
          onClick={onCloseClick}>&times;</button>
      </div>
    );
  }
  let modalJsx = (
    <div>
      <ResponsiveModal open={visible} onClose={x => x} center closeIconSize={0}>
        <div className="basic-modal-wrapper">
          {header}
          <div className="basic-modal-content">
            {content}
          </div>
        </div>
      </ResponsiveModal>
    </div>
  );

  return (
    modalJsx
  );
};

Modal.propTypes = {
  visible: PropTypes.bool.isRequired,
  content: PropTypes.object.isRequired,
  showHeader: PropTypes.bool,
  title: PropTypes.string,
  onCloseClick: PropTypes.func.isRequired
}

export default Modal
