import { connect } from 'react-redux'
import CertificateModal from '../../components/modal/CertificateModal'
import { userSignOut, userSignInDispatcher } from '../../actions'

const mapStateToProps = (state, ownProps) => ({
  visible: (state.user.accessToken !== null && state.user.isFirstAccess)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSignOutClick: () => dispatch(userSignOut()),
  userSignInDispatcher: (user) => dispatch(userSignInDispatcher(user))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CertificateModal)
