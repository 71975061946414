import * as types from '../constants/actionTypes';
import initialState from './initialState';
import dateUtils from '../utils/dateUtils';
import merge from 'lodash/merge'

const cfe = (state = initialState.cfe, action) => {

  switch (action.type) {
    case types.CFE_LIST_IS_LOADED: {
      let cfe = {
        items: handleFormats(action.items),
        isLoading: false
      };
      return merge(state.cfe, cfe);
    }
    case types.CFE_LIST_IS_LOADING: {
      let cfe = {
        items: state.items,
        isLoading: true
      };
      return merge(state.cfe, cfe);
    }
    default:
      return state
  }
}

const handleFormats = (itemList) => {

  if(itemList == null || itemList.length == 0){
    return itemList
  }

  return itemList.map(item => {

    const date = item.data.emissionDate;
    const formattedDate = date.substring(0, date.indexOf("T"));
  
    return {
      ...item,
      data: {
        ...item.data,
        emissionDate: formattedDate
      }
    }

  })

}

export default cfe