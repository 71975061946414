import * as httpMethods from '../constants/httpMethods';
import * as contentTypes from '../constants/contentTypes';
import storageUtils from '../utils/storageUtils';
import axios from 'axios';
/**
 * HTTP utils
 */
class HttpUtils {
  static consume(data, endpoint, method = httpMethods.REQUEST_METHOD_POST, sendAuthHeaders = true) {
    let headers = {}
    if (sendAuthHeaders) { 
      headers = {
        'Content-Type': contentTypes.CONTENT_TYPE_APPLICATION_JSON,
        'Authorization': 'bearer ' + storageUtils.getAccessToken()
      }
    }
    return axios({
      url: endpoint,
      data: data,
      method: method,
      headers: headers
    }).then(response => {
      return response.data;
    }).catch(error => {
      return error.response;
    });
  }
}
export default HttpUtils;
