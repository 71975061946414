import { connect } from 'react-redux'
import CertificateSettings from '../../components/pages/CertificateSettings'
import { loadCertificateDispatcher } from '../../actions';

const mapStateToProps = (state, ownProps) => ({

})

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadCertificateDispatcher: () => dispatch(loadCertificateDispatcher())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CertificateSettings)
