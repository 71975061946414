import * as types from '../constants/actionTypes';
import merge from 'lodash/merge'
import storageUtils from "../utils/storageUtils";
import initialState from './initialState';

const shared = (state = storageUtils.getStateFromLocalStorage('shared'), action) => {
  switch (action.type) {
    case types.VARITUS_API_ENV_CHANGED: {
      let shared = {
        varitusApiEnvType: action.envType
      };
      storageUtils.setItem('shared', shared);
      return merge(state.shared, shared);
    }
    default:
      return state
  }
}
export default shared
