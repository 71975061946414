import * as apiEndpoints from '../constants/apiEndpoints';
import * as global from '../constants/global';
import { serviceTypeValues } from '../enums/serviceTypes';
import { personRoleValues } from '../enums/personRoles';
import { emissionTypeValues } from '../enums/emissionTypes';
import { emailSentStatusValues } from '../enums/emailSentStatus';
import { dactePrintedStatusValues } from '../enums/dactePrintedStatus';
import storageUtils from '../utils/storageUtils';
import dateUtils from '../utils/dateUtils';
import httpUtils from '../utils/httpUtils';

/**
 * CT-e API
 */
class CTeApi {
  static filterBy(
    emissionDateStart,
    emissionDateEnd,
    varitusStatus,
    serie,
    numberStart,
    numberEnd,
    accessKey,
    end = global.MAX_RECORDS_PER_PAGE,
    shipmentState = null,
    landingState = null,
    emissionType = emissionTypeValues.ALL,
    serviceType = serviceTypeValues.ALL,
    personRole = personRoleValues.ALL,
    personCpfCnpj = null,
    emailSentStatus = emailSentStatusValues.BOTH,
    dactePrintedStatus = dactePrintedStatusValues.BOTH,
    environmentType = storageUtils.getEnvironmentType()
  ) {
    let request = {
      'token': storageUtils.getVaritusApiToken(),
      'serie': serie,
      'startNumber': numberStart,
      'endNumber': numberEnd,
      'startDate': dateUtils.formatUTC(emissionDateStart),
      'endDate': dateUtils.formatUTC(emissionDateEnd),
      'environmentType': environmentType,
      'status': varitusStatus,
      'emissionType': emissionType,
      'serviceType': serviceType,
      'shipmentState': shipmentState,
      'landingState': landingState,
      'personRole': personRole,
      'personCpfCnpj': personCpfCnpj,
      'accessKey': accessKey,
      'emailSentStatus': emailSentStatus,
      'dactePrintedStatus': dactePrintedStatus,      
      'pageNumber': 1,
      'pageLimit': end
    };
    storageUtils.setItem('cteSearchRequest', request);
    return httpUtils.consume(request, apiEndpoints.CTE_LIST_ENDPOINT);
  }

  static getAll(end = null) {
    if (end == null)
      end = global.MAX_RECORDS_PER_PAGE;
    return this.filterBy(new Date(), new Date(), null, null, 1, 9999999, null);
  }

  static downloadXml(issuerCnpj, serie, number, emissionDate, environmentType = storageUtils.getEnvironmentType()) {
    let request = {
      "token": storageUtils.getVaritusApiToken(),
      "cteKey": {
        "emissionDate": emissionDate,
        "issuerCnpj": issuerCnpj,
        "serie": serie,
        "number": number,
        "environmentType": environmentType
      }
    }
    return httpUtils.consume(request, apiEndpoints.CTE_EXPORT_ENDPOINT);
  }

  static disaccord(issuerCnpj, serie, number, emissionDate, reason, environmentType = storageUtils.getEnvironmentType()) {
    let request = {
      'token': storageUtils.getVaritusApiToken(),
      'cteKey': {
        'serie': serie,
        'number': number,
        'environmentType': environmentType,
        'issuerCnpj': issuerCnpj,
        'emissionDate': emissionDate
      },
      "reason": reason
    }
    return httpUtils.consume(request, apiEndpoints.CTE_DISACCORD_ENDPOINT);
  }

  static downloadDacte(issuerCnpj, serie, number, emissionDate, environmentType = storageUtils.getEnvironmentType()) {
    let request = {
      'token': storageUtils.getVaritusApiToken(),
      'cteKey': {
        'emissionDate': emissionDate,
        'serie': serie,
        'number': number,
        'environmentType': environmentType,
        'issuerCnpj': issuerCnpj
      }
    }
    return httpUtils.consume(request, apiEndpoints.CTE_DACTE_ENDPOINT);
  }
}
export default CTeApi;