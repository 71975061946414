import React from "react";
import PropTypes from "prop-types";
import ToggleSwitch from "../containers/form/ToggleSwitch";

const SwitchEnvironment = (props) => {
  return createJsxComponent(props);
};

const createJsxComponent = (props) => {
  const { onEnvironmentChanged, startChecked } = props;
  let jsx = (
    <ToggleSwitch label="Produção"
      onChange={(checked) => onEnvironmentChanged((checked) ? '1' : '2')} 
      startChecked={(startChecked == '1' ? true : false)} />
  );
  return jsx;
};

SwitchEnvironment.propTypes = {
  onEnvironmentChanged: PropTypes.func.isRequired,
};

export default SwitchEnvironment;
