/**
 * Translator utils
 */
class TranslatorUtils {
  constructor(translator) {
      this.translator = translator;
  }
  getItem(key) {
    let translations = this.translator['translation'];
    if (translations !== undefined) {
      let foundKey = translations.filter((translation) => {
        return translation.key === key;
      })[0];

      if (foundKey !== undefined) {
        return foundKey.value;
      }
    }
    return key;
  }
}
export default TranslatorUtils;
