import React from "react";
import TopBar from "../containers/TopBar";
import LeftMenu from "../containers/LeftMenu";
import MainContent from "../containers/MainContent";
import PropTypes from "prop-types";


const App = ({ innerContent = null, title = null, breadCrumbs = [] }) => {
  return (
    <div>
      <TopBar logoImage="assets/images/logo-Dark.png" />
      <div className="inner-wrapper">
        <LeftMenu />
        <MainContent
          title={title}
          breadCrumbs={breadCrumbs}
          innerContent={innerContent}
        />
      </div>
    </div>
  );
};

App.propTypes = {
  title: PropTypes.string,
  breadCrumbs: PropTypes.array,
  innerContent: PropTypes.object
};

export default App;
