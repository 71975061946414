/**
 * Mask Utils
 */
class MaskUtils {
  /**
   * Get formatted CNPJ
   * @param {string} cnpj only numbers
   */
  static formatCnpj(cnpj) {
    cnpj = cnpj.replace(/^(\d{2})(\d)/,"$1.$2");
    cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3");
    cnpj = cnpj.replace(/\.(\d{3})(\d)/,".$1/$2");
    cnpj = cnpj.replace(/(\d{4})(\d)/,"$1-$2");
    return cnpj;
  }
}
export default MaskUtils;