import * as apiEndpoints from '../constants/apiEndpoints';
import * as global from '../constants/global';
import * as httpMethods from '../constants/httpMethods';
import * as contentTypes from '../constants/contentTypes';
import storageUtils from "../utils/storageUtils";
import axios from 'axios';

/**
 * NFC-e API
 */
class NFCeApi {

  /**
   * Download do XML autorizado (nfeProc) da NFC-e
   */
  static downloadXml(issuerCnpj, serie, number, environmentType) {
    let endpoint = apiEndpoints.NFCE_EXPORT_XML_ENDPOINT;
    let notafazAccessTokenApi = this.getNotafazAccessTokenApi();
    let request = {
      "token": notafazAccessTokenApi,
      "nfeKey": {
        "issuerCnpj": issuerCnpj,
        "serie": serie,
        "number": number,
        "environmentType": environmentType
      }
    }
    return this.consume(request, endpoint);
  }

  /**
   * Download do DANFCE da NFC-e
   */
  static downloadDanfce(issuerCnpj, serie, number, environmentType) {
    let endpoint = apiEndpoints.NFCE_GENERATE_DANFE_ENDPOINT;
    let notafazAccessTokenApi = this.getNotafazAccessTokenApi();
    let request = {
      "token": notafazAccessTokenApi,
      "issuerCnpj": issuerCnpj,
      "serie": serie,
      "number": number,
      "environmentType": environmentType,
      "danfeType": "FULL",
      "paperType": "TERMICO_80MM"
    }
    return this.consume(request, endpoint);
  }

  /**
   * Filtro de pesquisa
  */
  static findBy(emissionDateStart, emissionDateEnd, varitusStatus, serie, numberStart, numberEnd, accessKey, companyName, end = global.MAX_RECORDS_PER_PAGE, environmentType = storageUtils.getEnvironmentType()) {
    let endpoint = apiEndpoints.NFCE_LIST_ENDPOINT;
    let notafazAcessTokenApi = this.getNotafazAccessTokenApi();
    let request = {
      'token': notafazAcessTokenApi,
      'serie': serie,
      'startNumber': numberStart,
      'endNumber': numberEnd,
      'companyName': companyName,
      'accessKey': accessKey,
      'status': varitusStatus,
      'sendType':  "RECEBIDAS",
      'startDate': this.formatDate(emissionDateStart),
      'endDate': this.formatDate(emissionDateEnd),
      'environmentType': environmentType,
      'pageNumber': 1,
      'pageLimit': end
    };
    storageUtils.setItem('nfceSearchRequest', request);
    return this.consume(request, endpoint);
  }

  /**
   * Recupera todas as NFC-e's da data corrente
   */
  static findAll(end = null) {
    if (end == null)
      end = global.MAX_RECORDS_PER_PAGE;
    return this.findBy(new Date(), new Date(), null, null, 1, 9999999, null, null);
  }

  /**
   * Métodos utilitários
  */
  static formatDate(date) {
    let formattedDate = JSON.parse(JSON.stringify(date).replace(/(\{|,)\s*(.+?)\s*:/g, '$1 "$2":'))
    return formattedDate;
  }

  static getEnvironmentType() {
    let environmentType = storageUtils.getEnvironmentType();
    return environmentType;
  };

  static getAccessToken() {
    let currentUser = storageUtils.getCurrentUser();
    return currentUser.accessToken;
  }

  static getNotafazAccessTokenApi() {
    let currentCompany = storageUtils.getCurrentCompany();
    return currentCompany.varitusApiToken;
  }

  static consume(data, endpoint) {
    let accessToken = this.getAccessToken();
    return axios({
      url: endpoint,
      data: data,
      method: httpMethods.REQUEST_METHOD_POST,
      headers: {
        'Content-Type': contentTypes.CONTENT_TYPE_APPLICATION_JSON,
        'Authorization': 'bearer ' + accessToken
      }
    }).then(response => {
      return response.data;
    }).catch(error => {
      return error.response;
    });
  }
}
export default NFCeApi;  