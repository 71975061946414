/**
 * Define initial states to reducers
 */
import * as global from '../constants/global';

export default {
  topbar: {
    logoUrl: '',
    cnpj: '',
    companyName: '',
    userName: ''
  },
  leftmenu: {
    items: []
  },
  nfe: {
    items: [],
    isLoading: true
  },
  nfce: {
    items: [],
    isLoading: true
  },
  nfse: {
    items: [],
    isLoading: true
  },
  mdfe: {
    items: [],
    isLoading: true
  },
  cte: {
    items: [],
    isLoading: true
  },
  cfe: {
    items: [],
    isLoading: true
  },
  cteos: {
    items: [],
    isLoading: true
  },
  invoice: {
    items: [],
    indebted: false
  },
  user: {
    userId: null, 
    username: null,
    isFirstAccess: false,
    accessToken: null,
    tokenType: null,
    trialAccessPlanInfo: null
  },
  shared: {
    varitusApiEnvType: global.VARITUS_API_PRODUCTION_ENV
  }
}

