import React from "react";
import PropTypes from "prop-types";
import { v1 } from "uuid";

const MainContent = ({ title, breadCrumbs, innerContent}) => (
  createJsxComponent(title, breadCrumbs, innerContent)
)

const createJsxComponent = (title, breadCrumbs, innerContent) => {
  let mainContentJsx = [];
  mainContentJsx.push(
    <section key={v1()} role="main" className="content-body">
      <header className="page-header">
        <h2>{title}</h2>
        <div className="right-wrapper pull-right">
          <ol className="breadcrumbs">
            <li>
              <a href="javascript:void(0);">
                <i className="fa fa-home" />
              </a>
            </li>
            {breadCrumbs.map((breadCrumb, i) => {
              return (
                <li key={v1()}>
                  <span>{breadCrumb}</span>
                </li>
              );
            })}
          </ol>
        </div>
      </header>
      <div className="row">
        <div className="col-md-12">
          {innerContent}
        </div>
      </div>
    </section>
  );
  return mainContentJsx;
};

MainContent.propTypes = {
  title: PropTypes.string,
  breadCrumbs: PropTypes.array,
  innerContent: PropTypes.object
};

export default MainContent;
