/**
 * Route constants
 */
export const FIRST_ACCESS_CERTIFICATE_PATH = '/first-access/certificate';
export const CERTIFICATE_SETTINGS_PATH = '/certificate/settings';
export const NFE_MANAGE_PATH = '/nfe/manage';
export const NFCE_MANAGE_PATH = '/nfce/manage';
export const NFSE_MANAGE_PATH = '/nfse/manage';
export const MDFE_MANAGE_PATH = '/mdfe/manage';
export const CTE_MANAGE_PATH = '/cte/manage';
export const CTE_OS_MANAGE_PATH = '/cte-os/manage';
export const CFE_MANAGE_PATH = '/cfe/manage';
export const SUBSCRIPTION_PATH = '/subscription';
export const INVOICE_PATH = '/invoice';
export const LOGIN_PATH = '/login';
export const HOME_PATH = '/';

// Welcome (Is first route when user is logged)
export const WELCOME_PATH = NFE_MANAGE_PATH;