import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { v1 } from 'uuid';
import Grid from '../../containers/grid/Grid';
import Modal from '../../containers/modal/Modal';
import cfeGridTranslator from '../../config/translators/cfeGridTranslator';
import fileUtils from '../../utils/fileUtils';
import { cfeStatusCss, cfeStatusLabels } from '../../enums/cfeStatus';
import { periodTypeLabels } from '../../enums/periodTypes';
import { cfeDocTypeLabels } from '../../enums/cfeDocType';
import { cfePrintedStatusLabels } from '../../enums/cfePrintedStatus';
import { emailSentStatusLabels } from '../../enums/emailSentStatus';
import cfeApi from '../../api/cfeApi';
import DatePicker from '../../containers/form/DatePicker';
import notificationUtils from '../../utils/notificationUtils';
import LoadingSpinner from '../../containers/LoadingSpinner';
import * as global from '../../constants/global';
import storageUtils from "../../utils/storageUtils";
import { emailSentStatusValues } from '../../enums/emailSentStatus';
import { cfePrintedStatusValues } from '../../enums/cfePrintedStatus';
import { cfeDocTypeValues } from '../../enums/cfeDocType';
import { periodTypeValues } from '../../enums/periodTypes';
import { varitusStatusLabels } from "../../enums/varitusStatus";


import '../../assets/pages/nfeManage.css';
import CFeApi from '../../api/cfeApi';

class CFeManage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      scrollDownCurrentLimit: global.MAX_RECORDS_PER_PAGE,
      showPendenciesModal: false,
      showFiltersModal: false,
      selectedRow: null,
      showLoadingSpinner: false,
      filters: {
        environmentType: storageUtils.getEnvironmentType(),
        companyDocument: storageUtils.getCurrentCompany(),
        periodType: periodTypeValues.EMISSION_DATE,
        startDate: new Date(),
        endDate: new Date(),
        docType: cfeDocTypeValues.BOTH,
        receiverDocument: null,
        statusVaritus: varitusStatusLabels.BOTH,
        serie: null,
        numberStart: 1,
        numberEnd: 9999999,
        emailSent: emailSentStatusValues.BOTH,
        cfePrinted: cfePrintedStatusValues.BOTH,
        accessKey: null,
        end: global.MAX_RECORDS_PER_PAGE
      }
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if(nextProps.isLoading !== null){
      this.setState({
        showLoadingSpinner: nextProps.isLoading
      })
    }
  }

  handleChangeStartDate = (date) => {
    if (new Date(date).toString() !== "Invalid Date") {
      this.setState({
        filters: {
          ...this.state.filters,
          startDate: date
        }
      });
    }
  }

  handleChangeEndDate = (date) => {
    if (new Date(date).toString() !== "Invalid Date") {
      this.setState({
        filters: {
          ...this.state.filters,
          endDate: date
        }
      });
    }
  }

  handleFilters = (type, value) => {

    this.setState({
      filters: {
        ...this.state.filters,
        [type]: (value === '' ? null : value)
      }
    })

  }

  buildFilters() {
    const {
      periodType,
      startDate,
      endDate,
      docType,
      receiverDocument,
      status,
      serie,
      numberStart,
      numberEnd,
      emailSent,
      cfePrinted,
      accessKey
    } = this.state.filters;

    return (
      <form className="form-horizontal form-bordered" onSubmit={
        (e) => {
          e.preventDefault();
          const { loadCFeGrid } = this.props;
          const {
            periodType,
            startDate,
            endDate,
            docType,
            receiverDocument,
            status,
            serie,
            numberStart,
            numberEnd,
            emailSent,
            cfePrinted,
            accessKey
          } = this.state.filters;
          if (startDate !== undefined
            && endDate !== undefined) {
              this.setState({showLoadingSpinner: true});
            CFeApi.filterBy(
              periodType,
              startDate,
              endDate,
              docType,
              receiverDocument,
              status,
              serie,
              numberStart,
              numberEnd,
              emailSent,
              cfePrinted,
              accessKey
            ).then(response => {
              if (response === undefined) {
                notificationUtils.warn('Falha na API da CF-e.');
                return;
              }
              if (response.message === undefined) {
                notificationUtils.warn('Falha na API da CF-e.');
                return;
              }
              let message = response.message;
              if (message.success) {
                loadCFeGrid(response.cfes);
                this.closeFilterModal();
              } else {
                notificationUtils.warn(message.details);
              }
            }).then(() => {this.setState({showLoadingSpinner: false});});
          }
        }
      }>
        <section className="panel">
          <div className="panel-body">
            <div className="form-group">
              <div>
                <label className="col-md-3 control-label">Tipo de período</label>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-9">
                          <select onChange={
                            (e) => this.handleFilters('periodType', e.target.value)
                          } value={periodType} className="form-control mb-md">
                            {
                              Object.keys(periodTypeLabels).map(x => {
                                return <option value={x} key={v1()}>{periodTypeLabels[x]}</option>
                              })
                            }
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <label className="col-md-3 control-label">Datas</label>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <DatePicker selectedDate={startDate} className="form-control" onChange={this.handleChangeStartDate} />
                        </div>
                        <span className="space col-md-1">Até</span>
                        <div className="col-md-4">
                          <DatePicker selectedDate={endDate} className="form-control" onChange={this.handleChangeEndDate} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <div>
                <label className="col-md-3 control-label">Status</label>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <select onChange={
                            (e) => this.handleFilters('status', e.target.value)
                          } value={status} className="form-control mb-md">
                            {
                              Object.keys(cfeStatusLabels).map(x => {
                                return <option value={x} key={v1()}>{cfeStatusLabels[x]}</option>
                              })
                            }
                          </select>
                        </div>
                        <span className="space col-md-1">Série</span>
                        <div className="col-md-4">
                          <input onChange={
                            (e) => this.handleFilters('serie', e.target.value)
                          } value={serie !== null ? serie : ''} type="number" className="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <div>
                <label className="col-md-3 control-label">Número</label>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <input onChange={
                            (e) => this.handleFilters('startNumber', e.target.value)
                          } value={numberStart !== null ? numberStart : ''} type="number" className="form-control" />
                        </div>
                        <span className="space col-md-1">Até</span>
                        <div className="col-md-4">
                          <input onChange={
                            (e) => this.handleFilters('endNumber', e.target.value)
                          } value={numberEnd !== null ? numberEnd : ''} type="number" className="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <div>
                <label className="col-md-3 control-label">Tipo</label>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <select onChange={
                            (e) => this.handleFilters('docType', e.target.value)
                          } value={docType} className="form-control mb-md">
                            {
                              Object.keys(cfeDocTypeLabels).map(x => {
                                return <option value={x} key={v1()}>{cfeDocTypeLabels[x]}</option>
                              })
                            }
                          </select>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <div>
                <label className="col-md-3 control-label">E-mail enviado</label>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <select onChange={
                            (e) => this.handleFilters('emailSent', e.target.value)
                          } value={emailSent} className="form-control mb-md">
                            {
                              Object.keys(emailSentStatusLabels).map(x => {
                                return <option value={x} key={v1()}>{emailSentStatusLabels[x]}</option>
                              })
                            }
                          </select>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <div>
                <label className="col-md-3 control-label">CF-e Impresso</label>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <select onChange={
                            (e) => this.handleFilters('cfePrinted', e.target.value)
                          } value={cfePrinted} className="form-control mb-md">
                            {
                              Object.keys(cfePrintedStatusLabels).map(x => {
                                return <option value={x} key={v1()}>{cfePrintedStatusLabels[x]}</option>
                              })
                            }
                          </select>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className="col-md-3 control-label">Documento do recebedor</label>
              <div className="col-md-5">
                <div className="row">
                  <div className="col-md-12">
                    <input onChange={
                      (e) => this.handleFilters('receiverDocument', e.target.value)
                    } value={receiverDocument !== null ? receiverDocument : ''} className="form-control" />
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className="col-md-3 control-label">Chave de acesso</label>
              <div className="col-md-5">
                <div className="row">
                  <div className="col-md-12">
                    <input onChange={
                      (e) => this.handleFilters('accessKey', e.target.value)
                    } value={accessKey !== null ? accessKey : ''} className="form-control" />
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group pull-right">
              <div className="col-md-12">
                <button className="btn btn-primary">Pesquisar</button>
              </div>
            </div>
          </div>
        </section>
      </form>
    );
  }

  downloadXml = (selectedRow) => {
    let data = selectedRow.data;
    if (data !== undefined) {
      let issuerDocument = data.issuerDocument;
      let serie = data.serie;
      let number = data.number;
      this.setState({ showLoadingSpinner: true }, () => {
        cfeApi.downloadXml(issuerDocument, serie, number).then(response => {
          let message = response.message;
          if (message !== undefined) {
            if (message.success === false) {
              notificationUtils.warn(message.details);
            } else {
              notificationUtils.success(message.details);
              let cfeXmlAsBase64 = response.cfeXmlAsBase64;
              let accessKey = selectedRow.details.accessKey;
              let fu = new fileUtils();
              cfeXmlAsBase64 = "data:application/xml;base64, " + cfeXmlAsBase64;
              let mimeType = fu.getBase64MimeType(cfeXmlAsBase64);
              fu.downloadFile(cfeXmlAsBase64, accessKey + '_' + serie + '_' + number + fu.getExtension(mimeType));

            }
          }
        }).then(() => {
          this.setState({
            showLoadingSpinner: false
          });
        });
      });
    }
  }

  closeFilterModal = () => {
    this.setState({
      showFiltersModal: false
    });
  }

  scrollDown = (endLimit) => {
    this.setState({
      scrollDownCurrentLimit: endLimit,
      showLoadingSpinner: true
      
    });
    this.loadGridWithFilters(endLimit);
  }

  loadGridWithFilters = (endLimit) => {
    const { loadCFeGrid } = this.props;
    const {
      periodType,
      startDate,
      endDate,
      cfeDocType,
      receiverDocument,
      status,
      serie,
      numberStart,
      numberEnd,
      emailSentStatus,
      cfePrintedStatus,
      accessKey,
      end
    } = this.state.filters;

    CFeApi.filterBy(
      periodType,
      startDate,
      endDate,
      cfeDocType,
      receiverDocument,
      status,
      serie,
      numberStart,
      numberEnd,
      emailSentStatus,
      cfePrintedStatus,
      accessKey,
      endLimit
    ).then(response => {
      let message = response.message;
      if (message !== undefined) {
        if (message.success) {
          loadCFeGrid(response.cfes);
        }
      }
    }).then(() => {this.setState({showLoadingSpinner: false});});
  }

  buildGrid() {
    const { items } = this.props;
    let headerColumns = [
      'serie',
      'number',
      'emissionDate',
      'statusVaritus'
    ];

    return (
      <Grid
        items={items}
        headerColumns={headerColumns}
        showDetails={true}
        translator={cfeGridTranslator}
        zebraStripedTable={
          {
            field: 'statusVaritus',
            cssTranslator: cfeStatusCss
          }
        }
        availableCallbacks={
          {
            'downloadXml': this.downloadXml,
          }
        }
        onScrollDown={this.scrollDown}
      />
    );
  }

  createJsxComponent = () => {
    const { selectedRow } = this.state;
    let cfeGridJsx = (
      <div>
        <div style={{ marginBottom: '10px' }}>
          <button className="btn btn-primary" onClick={() => this.setState({ showFiltersModal: true })}>
            <span className="space">Pesquisar Notas</span>
            <i className="fas fa-search"></i>
          </button>
        </div>
        {this.buildGrid()}
        <Modal visible={this.state.showFiltersModal} title="Filtro de Pesquisa" content={this.buildFilters()} onCloseClick={this.closeFilterModal} />
        <LoadingSpinner visible={this.state.showLoadingSpinner} />
      </div>
    );
    return cfeGridJsx;
  }

  render() {
    return this.createJsxComponent()
  }
}

CFeManage.propTypes = {
  items: PropTypes.array,
  loadCFeGrid: PropTypes.func
}

export default CFeManage