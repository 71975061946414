export default {
    'translation': [
    //LABELS
        { 'key': 'insertionDateTime', 'value': 'Data de Emissão' },
        { 'key': 'serieRps', 'value': 'Serie Rps' },
        { 'key': 'numberRps', 'value': 'Numero Rps' },
        { 'key': 'numberNfse', 'value' : 'Numero Nfse'},
        { 'key': 'statusLocal', 'value': 'Situação Varitus'},
        { 'key': 'statusProvider', 'value': 'Situação Prestadora'},
        { 'key': 'companyNameIssuer', 'value': 'Prestador' },
        { 'key': 'document', 'value': 'Documento' },
        { 'key': 'emailSent', 'value': 'Email Enviado' },
        { 'key': 'companyNameTaker', 'value': 'Tomador'},
        { 'key': 'nfseOk', 'value': 'Nfse Ok'},
        { 'key': 'message', 'value': 'Mensagem'},
        { 'key': 'codeServiceCity', 'value': 'Código de Serviço do Municipio'},
    //STATUS
        { 'key': 'PROCESSED', 'value': 'PROCESSADO' },
        { 'key': 'GENERATED', 'value': 'GERADO' },
        { 'key': 'TRANSMITTED', 'value': 'TRANSMITIDO' },
        { 'key': 'INVALID', 'value': 'INVÁLIDO' },
        { 'key': 'CANCELLED', 'value': 'CANCELADO' },
    //EMAIL
        { 'key': 'SENT', 'value': 'ENVIADO' },
        { 'key': 'NOT_SENT', 'value': 'NÃO ENVIADO' },
    // Actions
        { 'key': 'printDanfse', 'value': 'fas fa-print' },
        { 'key': 'downloadXml', 'value': 'fas fa-download' },
        // { 'key': 'cancel', 'value': 'fas fa-exclamation-triangle' }
    ]
  }