export const nfeTypeLabels = {
    EMITIDAS: 'Emitidos',
    RECEBIDAS: 'Recebidos',
    TODOS: 'Ambos'
}

export const nfeTypeValues = {
    EMITIDAS: 'EMITIDAS',
    RECEBIDAS: 'RECEBIDAS',
    TODOS: 'TODOS'
}