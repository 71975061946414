import { connect } from "react-redux";
import SwitchCompany from "../components/SwitchCompany";
import { changeCompanyDispatcher } from '../actions'

const mapStateToProps = (state, ownProps) => ({
  
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeCompanyDispatcher: (company) => dispatch(changeCompanyDispatcher(company))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SwitchCompany);
