import * as apiEndpoints from '../constants/apiEndpoints';
import * as global from '../constants/global';
import { emailSentStatusValues } from '../enums/emailSentStatus';
import { nfseTypeValues } from '../enums/nfseType';
import { periodTypeValues } from '../enums/periodTypes';
import storageUtils from '../utils/storageUtils';
import dateUtils from '../utils/dateUtils';
import httpUtils from '../utils/httpUtils';
import {nfseStatusLabels, nfseStatusValues} from '../enums/nfseStatus';


/**
 *NFS-e APIdas
 */
class NFSeApi {
  
  static filterBy(
    periodType,
    initialDate,
    finalDate,
    sendType,
    nfseLocalStatus,
    emailSent,
    serie,
    nfseInitialNumber,
    nfseFinalNumber,
    rpsInitialNumber,
    rpsFinalNumber,
    manifest,
    cpfCnpj,
    companyName,
    envType = storageUtils.getEnvironmentType(),
    end = global.MAX_RECORDS_PER_PAGE
  ) {
    let request = {
      'token': storageUtils.getVaritusApiToken(),
      'periodType': periodType,
      'initialDate': dateUtils.formatUTC(initialDate),
      'finalDate': dateUtils.formatUTC(finalDate),
      'sendType':  sendType,
      'nfseLocalStatus': nfseLocalStatus,
      'emailSent': emailSent,
      'serie': serie,
      'nfseInitialNumber': nfseInitialNumber,
      'nfseFinalNumber': nfseFinalNumber,
      'rpsInitialNumber': rpsInitialNumber,
      'rpsFinalNumber': rpsFinalNumber,
      'manifest': manifest,
      'cpfCnpj': cpfCnpj,
      'envType': envType,
      'companyName' : companyName,
      'pageNumber': 1,
      'pageLimit': end
    };
    storageUtils.setItem('nfseSearchRequest', request);
    return httpUtils.consume(request, apiEndpoints.NFSE_LIST_ENDPOINT);
  }

  static getAll(end = null) {
    if (end == null)
      end = global.MAX_RECORDS_PER_PAGE;
    return this.filterBy(periodTypeValues.EMISSION_DATE, new Date, new Date, nfseTypeValues.ALL, nfseStatusValues.PROCESSED, emailSentStatusValues.BOTH, null, null, null, 1, 9999999, null);
  }

  static downloadXml(rpsSeries, rpsNumber, nfseEmissionDate, providerCnpj, cityServiceCode, nfseEnvironmentType = storageUtils.getEnvironmentType()) {
    let request = {
      "token": storageUtils.getVaritusApiToken(),
      "rpsSeries": rpsSeries,
      "rpsNumber": rpsNumber,
      "nfseEmissionDate": nfseEmissionDate,
      "providerCnpj": providerCnpj,
      "cityServiceCode": cityServiceCode,
      "nfseEnvironmentType": nfseEnvironmentType
    }
    return httpUtils.consume(request, apiEndpoints.NFSE_EXPORT_XML_ENDPOINT);
  }

  static printDanfse(rpsSeries, rpsNumber, nfseEmissionDate, providerCnpj, cityServiceCode, nfseEnvironmentType = storageUtils.getEnvironmentType()) {
    let request = {
      'token': storageUtils.getVaritusApiToken(),
        'rpsSeries': rpsSeries,
        'rpsNumber': rpsNumber,
        'nfseEmissionDate': nfseEmissionDate,      
        'providerCnpj': providerCnpj,
        'cityServiceCode' : cityServiceCode,
        'nfseEnvironmentType': nfseEnvironmentType
    }
    return httpUtils.consume(request, apiEndpoints.NFSE_GENERATE_DANFSE_ENDPOINT);
  }

  static cancel(rpsSerie, rpsNumber, nfseEmissionDate, providerCnpj, cityServiceCode, justification, nfseEnvironmentType = storageUtils.getEnvironmentType()) {
    let request = {
      'token': storageUtils.getVaritusApiToken(),
        'rpsSerie': rpsSerie,
        'rpsNumber': rpsNumber,
        'nfseEmissionDate': nfseEmissionDate,
        'providerCnpj': providerCnpj,
        'cityServiceCode': cityServiceCode,
        'justification': justification,
        'nfseEnvironmentType': nfseEnvironmentType
    }
    return httpUtils.consume(request, apiEndpoints.NFSE_CANCEL_ENDPOINT);
  }

}
export default NFSeApi;