export const periodTypeLabels = {
    EMISSION_DATE: 'Data de emissão',
    INSERTION_DATE: 'Data de inserção',
    DUE_DATE: 'Data de encerramento',
    DATE_AUT_SEFAZ: 'Data de autorização SEFAZ'
}

export const periodTypeValues = {
    EMISSION_DATE: 'EMISSION_DATE',
    INSERTION_DATE: 'INSERTION_DATE',
    DUE_DATE: 'DUE_DATE',
    DATE_AUT_SEFAZ: 'DATE_AUT_SEFAZ'
}