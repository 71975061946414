import React from "react";
import PropTypes from "prop-types";
import '../assets/topBar.css';
import '../assets/varitusApps.css';
import productsApi from '../api/productsApi';


class VaritusApp extends React.Component {
    constructor(props) {
    super(props);
    this.state = {
      appsStyle: ["divIcon", "modalApps"],
      products: []
    };
  }

  componentWillMount = () => {
    productsApi
    .allProducts()
    .then(response => {
      this.setState({
        ...this.state,
        products: response.products
      })
    })
  }

  toggleApps = () => {
    var classes = this.state.appsStyle;
    classes.includes("openModal") ? classes.pop("openModal") : classes.push("openModal");
    this.setState({
      ...this.state,
      appsStyle: classes
    });
  }
 
  redirect = (redirect) => {
    window.open(redirect, "_blank");
  }

  generateApp = (img, url, name) => {
    return <div className="subModal" onClick={() => this.redirect(url)}>

            {/* <div className="modalImage">
              <img className="appImage" src={img} />
            </div>
            <p className="iconText">{name}</p> */}

              <div className="modalImage">
                <img className="appImage" src={img} />
              </div>
                <div className="modalText">
                    <p className="iconText">{name}</p>
                </div>
           </div>
  }

  generateApps = () => {

    let products = this.state.products;
    const apps = [];
    products = products.filter(value => value.productCode != "XML_SPY");
    
    products.forEach(product => {
      apps.push(this.generateApp(product.logo, product.url, product.description));
    });

   return apps;

  }

  render() {

    const { appsStyle } = this.state;

    return (
      <div className="divIcon">

        <div className="divIcon">
          <i 
            className="fas fa-th menuIcon" 
            onClick={() =>
                this.toggleApps()
            }>
          </i>
        </div>

        <div className={appsStyle.join(" ")}>
          {this.generateApps()}
        </div>

      </div>
    );
  }
}

VaritusApp.propTypes = {

};

export default VaritusApp;
