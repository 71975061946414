
/**
 * CSS Classes
 */
export const nfseStatusCss = {
    PROCESSED: 'label-success',
    CANCELLED: 'label-success',
    TRANSMITTED: 'label-success',
    GENERATED: 'label-default',
    INVALID: 'label-default'
  }

export const nfseStatusLabels = {
      PROCESSED: 'Processado',
      GENERATED: 'Gerado',
      TRANSMITTED: 'Transmitido',
      INVALID: 'Invalido',
      CANCELLED: 'Cancelado'
  }

export const nfseStatusValues = {
      PROCESSED: 'PROCESSED',
      GENERATED: 'GENERATED',
      TRANSMITTED: 'TRANSMITTED',
      INVALID: 'INVALID',
      CANCELLED: 'CANCELLED'
}