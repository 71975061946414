export default {
  'translation': [
      // Labels
      { 'key': 'serie', 'value': 'Série' },
      { 'key': 'number', 'value': 'Número' },
      { 'key': 'emissionDate', 'value': 'Data Emissão' },
      { 'key': 'sender', 'value': 'Emitente' },
      { 'key': 'shipmentState', 'value': 'UF Embarque'},
      { 'key': 'landingState', 'value': 'UF Desembarque'},
      { 'key': 'varitusStatus', 'value': 'Situação Varitus' },
      { 'key': 'sefazAuthorizationDate', 'value': 'Data de Autorização na Sefaz' },
      { 'key': 'damdfeWasPrinted', 'value': 'Damdfe Impresso' },
      { 'key': 'validSignature', 'value': 'Assinatura Válida' }, 
      { 'key': 'emailWasSent', 'value': 'E-mail enviado' },
      { 'key': 'dacteWasPrinted', 'value': 'Dacte impresso' },  
      { 'key': 'accessKey', 'value': 'Chave de Acesso' },
      { 'key': 'invoiceReceiver', 'value': 'Destinatário' },
      { 'key': 'dispatcher', 'value': 'Expedidor' },
      { 'key': 'shipmentReceiver', 'value': 'Recebedor' },
      { 'key': 'hirer', 'value': 'Tomador' },

      { 'key': true, 'value': 'Sim' },
      { 'key': false, 'value': 'Não' },
      { 'key': 'TYPED', 'value': 'DIGITADO' },
      { 'key': 'SIGNED', 'value': 'ASSINADO' },
      { 'key': 'VALIDATED', 'value': 'VALIDADO' },  
      { 'key': 'SENT', 'value': 'TRANSMITIDO' },
      { 'key': 'AUTHORIZED', 'value': 'AUTORIZADO' },
      { 'key': 'CANCELED', 'value': 'CANCELADO' },
      { 'key': 'DENIED', 'value': 'DENEGADO' },
      { 'key': 'REJECTED', 'value': 'REJEITADO' },
      { 'key': 'UNUSED', 'value': 'INUTILIZADO' },
      // Actions
      { 'key': 'downloadDacte', 'value': 'fas fa-print' },
      { 'key': 'downloadXml', 'value': 'fas fa-download' },
      { 'key': 'showPendencies', 'value': 'fas fa-exclamation-triangle' },
      { 'key': 'makeDisaccord', 'value': 'fas fa-bullhorn' }
  ]
}