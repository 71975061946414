import { connect } from 'react-redux'
import PrivateRoute from '../../components/routes/PrivateRoute'

const mapStateToProps = (state, ownProps) => ({
  isLogged: (state.user.accessToken !== null),
  isFirstAccess: state.user.isFirstAccess,
  key: ownProps.key,
  path: ownProps.path,
  render: ownProps.render,
  exact: ownProps.exact,
  trialPeriodExpired: (state.user.trialAccessPlanInfo !== null) ? state.user.trialAccessPlanInfo.trialPeriodExpired : false,
  indebted: state.invoice.indebted
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute)
