export const dactePrintedStatusLabels = {
    PRINTED: 'Todos',
    NOT_PRINTED: 'Normal',
    BOTH: 'Contingência',
}

export const dactePrintedStatusValues = {
    PRINTED: 'PRINTED',
    NOT_PRINTED: 'NOT_PRINTED',
    BOTH: 'BOTH'
}