import { connect } from 'react-redux'
import ConfirmModal from '../../components/modal/ConfirmModal'

const mapStateToProps = (state, ownProps) => ({
  visible: ownProps.visible,
  title: ownProps.title,
  content: ownProps.content
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCloseClick: () => ownProps.onCloseClick(),
  onChooseNotClick: () => ownProps.onChooseNotClick(),
  onChooseYesClick: () => ownProps.onChooseYesClick()
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmModal)
