import * as types from '../constants/actionTypes';
import initialState from './initialState';
import merge from 'lodash/merge'

const nfce = (state = initialState.nfce, action) => {
  switch (action.type) {
    case types.NFCE_LIST_IS_LOADED: {
      let nfce = {
        items: action.items,
        isLoading: false
      };
      return merge(state.nfce, nfce);
    }
    case types.NFCE_LIST_IS_LOADING: {
      let nfce = {
        items: state.items,
        isLoading: true
      };
      return merge(state.nfce, nfce);
    }
    default:
      return state
  }
}
export default nfce