import { connect } from 'react-redux'
import NFSeManage from '../../components/pages/NFSeManage'
import { loadNFSeGrid } from '../../actions';

const mapStateToProps = (state, ownProps) => {
  return {
    items: state.nfse.items ? state.nfse.items : [],
    isLoading: state.nfse.isLoading
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadNFSeGrid: (items) => dispatch(loadNFSeGrid(items))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NFSeManage)