export const cfeDocTypeLabels = {
    ISSUED: 'Emitidos',
    RECEIVED: 'Recebidos',
    BOTH: 'Ambos',
}

export const cfeDocTypeValues = {
    ISSUED: 'ISSUED',
    RECEIVED: 'RECEIVED',
    BOTH: 'BOTH'
}