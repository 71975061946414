import { connect } from 'react-redux'
import CTeManage from '../../components/pages/CTeManage'
import { loadCTeGrid } from '../../actions';

const mapStateToProps = (state, ownProps) => ({
  items: state.cte.items,
  isLoading: state.cte.isLoading
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadCTeGrid: (items) => dispatch(loadCTeGrid(items))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CTeManage)
