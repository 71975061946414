import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from '../../containers/modal/Modal';
import '../../assets/modal/confirmModal.css';

class ConfirmModal extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  createContentJsx = () => {
    const { onCloseClick, onChooseYesClick, content } = this.props;
    return (
      <div id="confirm-modal-container">
        <form>
          <div className="box-content">
            {content}
          </div>
          <div className="box-footer">
            <div className="right">
              <button onClick={() => onCloseClick()} className="btn btn-primary">Não</button>
              <button onClick={() => onChooseYesClick()} className="btn btn-primary">Sim</button>
            </div>
          </div>
        </form>
      </div>
    );
  }

  createJsxComponent = () => {
    const { visible, title, onCloseClick } = this.props;
    return (
      <Modal visible={visible}
        title={title}
        content={this.createContentJsx()}
        onCloseClick={onCloseClick} />
    )
  }

  render() {
    return this.createJsxComponent()
  }
}

ConfirmModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  title: PropTypes.string,
  content: PropTypes.object.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onChooseNotClick: PropTypes.func.isRequired,
  onChooseYesClick: PropTypes.func.isRequired
}

export default ConfirmModal
