import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { v1 } from 'uuid';
import notificationUtils from '../../utils/notificationUtils';
import classNames from 'classnames';
import Grid from '../../containers/grid/Grid';
import invoiceGridTranslator from '../../config/translators/invoiceGridTranslator';
import { invoiceStatusLabels, invoiceStatusCss, invoiceStatusIcons } from '../../enums/invoiceStatus';
import '../../assets/pages/invoiceManage.css';
import invoiceConfig from '../../config/invoice';
import * as global from '../../constants/global';


class InvoiceManage extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    const { indebted, loadCustomerInvoices } = this.props;
    loadCustomerInvoices(global.MAX_RECORDS_PER_PAGE);
    if (indebted === true) {
      notificationUtils.warn(
        "Seu acesso foi bloqueado!\n"
        + "Você possui faturas atrasadas há mais de " + invoiceConfig.DAYS_OF_DEBT_DELAY + " dias\n"
        + "Por favor, "
        + "realize o pagamento!"
      );
    }
  }

  downloadInvoice = (rowData) => {
    let data = rowData.data;
    if (data !== undefined) {
      let secureUrl = data.secureUrl;
      var win = window.open(secureUrl, '_blank');
      win.focus();
    }
  }

  scrollDown = (endLimit) => {
    this.loadGridWithFilters(endLimit);
  }

  loadGridWithFilters = (endLimit) => {
    const { loadCustomerInvoices } = this.props;
    loadCustomerInvoices(endLimit);
  }

  buildLegend() {
    return (
      <section className="panel box-legend">
        <header className="panel-heading">
          <p className="panel-subtitle">
            <strong>Legenda:</strong>
          </p>
        </header>
        <div className="panel-body">
          {
            Object.keys(invoiceStatusLabels).map(x => {
              return (
                <div key={v1()} className={`label-grid ${invoiceStatusCss[x]}`}>
                  <i className={invoiceStatusIcons[x]}></i>
                  <span className="text">{invoiceStatusLabels[x]}</span>
                </div>
              )
            })
          }
        </div>
      </section>
    );
  }

  buildGrid = () => {
    const { items } = this.props;
    let headerColumns = [
      'status',
      'dueDate',
      'formattedPrice',
      'payableWith'
    ];
    return (
      <Grid
        items={items}
        headerColumns={headerColumns}
        showDetails={false}
        translator={invoiceGridTranslator}
        zebraStripedTable={
          {
            field: 'status',
            cssTranslator: invoiceStatusCss
          }
        }
        availableCallbacks={
          {
            'downloadInvoice': this.downloadInvoice
          }
        }
        onScrollDown={this.scrollDown}
      />
    );
  }

  createJsxComponent = () => {
    const { showSignOutButton, onSignOutClick } = this.props;
    return (
      <div>
        <div className={
          classNames({
            'row': true,
            'hide': !showSignOutButton
          })
        }>
          <div className="col-md-12">
            <button onClick={() => onSignOutClick()} type="button" className="mb-xs mt-xs mr-xs btn btn-info">
              <i className="fa fa-power-off"></i>
              &nbsp;
              <span>Sair do sistema</span>
            </button>
          </div>
        </div>
        {this.buildLegend()}
        {this.buildGrid()}
      </div>
    )
  }
  render() {
    return this.createJsxComponent()
  }
}

InvoiceManage.propTypes = {
  items: PropTypes.array,
}

export default InvoiceManage