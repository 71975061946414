import {createStore, applyMiddleware} from 'redux';  
import rootReducer from '../reducers';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router'
import { createHashHistory } from 'history'

export const history = createHashHistory()

const configureStore = () => {  
  return createStore(
    rootReducer(history),
    applyMiddleware(
      routerMiddleware(history),
      thunk
    )
  );
}

export default configureStore