/**
 * Action Types
 */

// Configurations
export const CONFIGURATION_MENU_IS_LOADED = 'CONFIGURATION_MENU_IS_LOADED';  
export const TOPBAR_IS_LOADED =  'TOPBAR_IS_LOADED';
export const COMPANY_IS_LOADED = 'COMPANY_IS_LOADED';



// User
export const USER_SIGN_IN = 'USER_SIGN_IN';
export const USER_SIGN_OUT = 'USER_SIGN_OUT';

// NFe
export const NFE_LIST_IS_LOADED = 'NFE_LIST_IS_LOADED';
export const NFE_LIST_IS_LOADING = 'NFE_LIST_IS_LOADING';

// NFCe
export const NFCE_LIST_IS_LOADED = 'NFCE_LIST_IS_LOADED';
export const NFCE_LIST_IS_LOADING = 'NFCE_LIST_IS_LOADING';

//NFSe
export const NFSE_LIST_IS_LOADED = 'NFSE_LIST_IS_LOADED';
export const NFSE_LIST_IS_LOADING = 'NFSE_LIST_IS_LOADING';

// MDFe
export const MDFE_LIST_IS_LOADED = 'MDFE_LIST_IS_LOADED';
export const MDFE_LIST_IS_LOADING = 'MDFE_LIST_IS_LOADING';

// CTe
export const CTE_LIST_IS_LOADED = 'CTE_LIST_IS_LOADED';
export const CTE_LIST_IS_LOADING = 'CTE_LIST_IS_LOADING';

// CTeOS
export const CTE_OS_LIST_IS_LOADED = 'CTE_OS_LIST_IS_LOADED';
export const CTE_OS_LIST_IS_LOADING = 'CTE_OS_LIST_IS_LOADING';

// CFe
export const CFE_LIST_IS_LOADED = 'CFE_LIST_IS_LOADED';
export const CFE_LIST_IS_LOADING = 'CFE_LIST_IS_LOADING';

// Invoice
export const INVOICE_IS_LOADED = 'INVOICE_IS_LOADED';


// Shared
export const VARITUS_API_ENV_CHANGED = 'VARITUS_API_ENV_CHANGED';