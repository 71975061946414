import * as apiEndpoints from '../constants/apiEndpoints';
import * as global from '../constants/global';
import { periodTypeValues } from '../enums/periodTypes';
import { emissionTypeValues } from '../enums/emissionTypes';
import { emailSentStatusValues } from '../enums/emailSentStatus';
import { damdfePrintedStatusValues } from '../enums/damdfePrintedStatus';
import storageUtils from '../utils/storageUtils';
import dateUtils from '../utils/dateUtils';
import httpUtils from '../utils/httpUtils';

/**
 * MDF-e API
 */
class MDFeApi {
  static filterBy(
    emissionDateStart,
    emissionDateEnd,
    varitusStatus,
    serie,
    numberStart,
    numberEnd,
    accessKey,
    companyName,
    end = global.MAX_RECORDS_PER_PAGE,
    periodType = periodTypeValues.EMISSION_DATE,
    shipmentState = null,
    landingState = null,
    emissionType = emissionTypeValues.ALL,
    emailSentStatus = emailSentStatusValues.BOTH,
    damdfePrintedStatus = damdfePrintedStatusValues.BOTH,
    environmentType = storageUtils.getEnvironmentType()
  ) {
    let request = {
      'token': storageUtils.getVaritusApiToken(),
      'serie': serie,
      'startNumber': numberStart,
      'endNumber': numberEnd,
      'companyName': companyName,
      'accessKey': accessKey,
      'periodType': periodType,
      'shipmentState': shipmentState,
      'landingState': landingState,
      'emissionType': emissionType,
      'emailSentStatus': emailSentStatus,
      'damdfePrintedStatus': damdfePrintedStatus,
      'status': varitusStatus,
      'startDate': dateUtils.formatUTC(emissionDateStart),
      'endDate': dateUtils.formatUTC(emissionDateEnd),
      'environmentType': environmentType,
      'pageNumber': 1,
      'pageLimit': end
    };
    storageUtils.setItem('mdfeSearchRequest', request);
    let response = httpUtils.consume(request, apiEndpoints.MDFE_LIST_ENDPOINT);
    return response;
  }

  static getAll(end = null) {
    if (end == null)
      end = global.MAX_RECORDS_PER_PAGE;
    return this.filterBy(new Date(), new Date(), null, null, 1, 9999999, null, null);
  }

  static downloadXml(issuerCnpj, serie, number, environmentType = storageUtils.getEnvironmentType()) {
    let request = {
      "token": storageUtils.getVaritusApiToken(),
      "mdfeKey": {
        "issuerCnpj": issuerCnpj,
        "serie": serie,
        "number": number,
        "environmentType": environmentType
      }
    }
    return httpUtils.consume(request, apiEndpoints.MDFE_EXPORT_ENDPOINT);
  }

  static downloadDamdfe(issuerCnpj, serie, number, environmentType = storageUtils.getEnvironmentType()) {
    let request = {
      'token': storageUtils.getVaritusApiToken(),
      'mdfeKey': {
        'serie': serie,
        'number': number,
        'environmentType': environmentType,
        'issuerCnpj': issuerCnpj
      },
      'printFormat': 'LANDSCAPE'
    }
    return httpUtils.consume(request, apiEndpoints.MDFE_DAMDFE_ENDPOINT);
  }
}
export default MDFeApi;  