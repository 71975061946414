import React, { Component } from 'react'
import PropTypes from 'prop-types'
import fileUtils from "../../utils/fileUtils";
import notificationUtils from "../../utils/notificationUtils";
import certificateApi from "../../api/certificateApi";
import Modal from '../../containers/modal/Modal';
import '../../assets/modal/certificateModal.css';
import storageUtils from "../../utils/storageUtils";
import { oAuthHttpStatus, repositoryStatus } from "../../enums/varitusResponseCodes";

class CertificateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      password: '',
      emailCert: ''
    }
  }

  toggleShowPassword = () => {
    let caPassword = document.getElementById('ca-password');
    let toggleShowPasswordIcon = document.getElementById('toggle-show-password-icon');
    
    if (caPassword.type === 'password') {
      caPassword.type = 'text';
      toggleShowPasswordIcon.className = 'fas fa-lock';
    } else {
      caPassword.type = 'password';
      toggleShowPasswordIcon.className = 'fas fa-unlock';
    }
  }

  onPasswordEnter = (passwordValue) => {
    this.setState({
      ...this.state.file,
      password: passwordValue,
    });
  }

  onEmailEnter = (emailValue) => {
    this.setState({
      ...this.state.file,
      emailCert: emailValue,
    });
  }

  save = (e) => {
    e.preventDefault();
    const {file, password, emailCert} = this.state;

    if (file == null || password == null || password == '') {
      notificationUtils.warn('É necessário selecionar o certificado e a senha!');
      return;
    }

    let fu = new fileUtils();
    fu.getBase64(file).then(base64File => {
      certificateApi.upload(base64File, password, emailCert).then(response => {
        switch (response.status) {
          case oAuthHttpStatus.INVALID_TOKEN:
          case oAuthHttpStatus.INVALID_CLIENT_ID:
          case oAuthHttpStatus.INVALID_CREDENTIALS: {
            notificationUtils.error('Não foi possível carregar certificado!\n\nAcesso não autorizado!');
            break;
          }
          case repositoryStatus.SUCCESS_SAVED: {
            notificationUtils.success('Certificado cadastrado com sucesso!');
            let user = storageUtils.getItem('user');
            user.isFirstAccess = false;
            const { userSignInDispatcher } = this.props;
            // Login again
            userSignInDispatcher(user); 
            break;
          }
          case repositoryStatus.ERROR_SAVED: {
            notificationUtils.error('Erro ao cadastrar certificado!\n\nPor favor, tente novamente!');
            break;
          }
          default:
            notificationUtils.error('Erro inesperado!');
            break;
        }
      }).catch(error => {
        throw error;
      });
    });
  }

  createCertificateContentJsx = () => {
    const { onSignOutClick } = this.props;
    let certificateModalJsx = (
      <div>
        <div className="box-modal-header">
          <h1>Bem-vindo ao XML Espião</h1>
          <h2>
            <span className="text">Para que você possa desfrutar de todos os nossos benefícios será necessário realizar o upload do seu certificado digital A1:</span>
          </h2>
        </div>
        <div className="box-modal-content">
          <form>
            <div className="form-group">
              <label className="col-md-3 control-label">Upload certificado A1</label>
              <div className="col-md-6 upload-btn-wrapper">
                <button type="button" className="mb-xs mt-xs mr-xs btn btn-primary">
                  <i className="fa fa-cloud-upload"></i>&nbsp; Selecione o arquivo
                </button>
                <span className="file-info">Apenas arquivos (*.pfx)</span>
                <input name="ca-user" id="ca-user" type="file" onChange={(e) => {
                  let file = e.target.files[0];
                  if (file !== undefined) {
                    let fileName = file.name;
                    let fu = new fileUtils();
                    if (!fu.typeIsValid(fileName, ['.pfx'])) {
                      notificationUtils.warn('Formato do arquivo enviado é inválido!');
                    } else {
                      this.setState({
                        ...this.state.password,
                        file: file
                      });
                    }
                  } else {
                    notificationUtils.warn('Não foi possível carregar o arquivo!');
                  }
                }} />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="ca-password" className="col-md-3 control-label">Senha do certificado</label>
              <div className="col-md-6 upload-btn-wrapper">
                <div className="input-group col-md-6">
                  <input className="form-control" type="password" id="ca-password" name="ca-password" autoComplete="ca-password" onChange={(e) => this.onPasswordEnter(e.target.value)} value={this.state.password} />
                  <div title="Clique para ver/ocultar a senha" className="input-group-addon toggle-show-password" onClick={() => this.toggleShowPassword()}>
                    <i id="toggle-show-password-icon" className="fas fa-unlock" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="ca-emailCert" className="col-md-3 control-label">Email para Notificação</label>
              <div className="col-md-8 upload-btn-wrapper">
                <div className="input-group col-md-6">
                  <input className="form-control" type="input" id="ca-emailCert" name="ca-emailCert" autoComplete="ca-emailCert" onChange={(e) => this.onEmailEnter(e.target.value)} value={this.state.emailCert} />
                  </div>
                </div>
              </div>


            <div className="form-group">
              <div className="col-md-6">
                <button type="button" className="mb-xs mt-xs mr-xs btn btn-primary" onClick={(e) => this.save(e)}>
                  <i className="fa fa-save"></i>
                  &nbsp;
                  <span>Cadastrar</span>
                </button>
                <button onClick={() => onSignOutClick()} type="button" className="mb-xs mt-xs mr-xs btn btn-info">
                  <i className="fa fa-power-off"></i>
                  &nbsp;
                  <span>Sair do sistema</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  
    return (
      certificateModalJsx
    );
  }

  createJsxComponent = () => {
    const { visible } = this.props;
    return (
      <Modal visible={visible} width="800" content={this.createCertificateContentJsx()} showHeader={false} />
    )
  }

  render() {
    return this.createJsxComponent()
  }
}

CertificateModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onSignOutClick: PropTypes.func.isRequired
}

export default CertificateModal
