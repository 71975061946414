export const cfePrintedStatusLabels = {
    PRINTED: 'Impresso',
    NOT_PRINTED: 'Não impresso',
    BOTH: 'Ambos',
}

export const cfePrintedStatusValues = {
    PRINTED: 'PRINTED',
    NOT_PRINTED: 'NOT_PRINTED',
    BOTH: 'BOTH'
}