import React, { Component } from "react";
import PropTypes from "prop-types";

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt from 'date-fns/locale/pt';
import { registerLocale } from "react-datepicker";
import MaskedInput from 'react-text-mask'

import '../../assets/form/datepicker.css';

class DatePicker extends Component {
  constructor(props) {
    super(props);
    registerLocale('pt', pt);
  }

  render() {
    return createJsxComponent(this.props);
  }
}

const createJsxComponent = props => {
  const { onChange, className, selectedDate } = props;
  
  return (
    <ReactDatePicker
      selected={selectedDate}
      onChange={onChange.bind(this)}
      onChangeRaw={(event) =>
        onChange(event.target.value)}
      className={className}
      dateFormat="dd/MM/yyyy"
      locale="pt"
      customInput={<MaskedInput
        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      />}
    />
  );
};

DatePicker.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default DatePicker;
