import * as apiEndpoints from '../constants/apiEndpoints';
import httpUtils from '../utils/httpUtils';
import * as httpMethods from '../constants/httpMethods';
import storageUtils from "../utils/storageUtils";
import * as global from '../constants/global';

/**
 * Subscription API
 */
class SubscriptionApi {
  static getAccessPlans() {
    return httpUtils.consume(
      {},
      apiEndpoints.SUBSCRIPTION_PLANS_ENDPOINT,
      httpMethods.REQUEST_METHOD_GET
    );
  }
  static getUserSubscriptionDetails() {
    let userId = storageUtils.getCurrentUser().userId;
    return httpUtils.consume(
      {},
      apiEndpoints.SUBSCRIPTION_USER_DETAILS_ENDPOINT.split('{userId}').join(userId),
      httpMethods.REQUEST_METHOD_GET
    );
  }
  static createOrUpdateCustomer(subscriptionCustomerRequest, customerId = null) {
    let method = httpMethods.REQUEST_METHOD_POST;
    let uri = apiEndpoints.SUBSCRIPTION_CUSTOMER_CREATE_ENDPOINT;
    if (customerId !== null) {
      uri = apiEndpoints.SUBSCRIPTION_CUSTOMER_UPDATE_ENDPOINT.split('{customerId}').join(customerId);
      method = httpMethods.REQUEST_METHOD_PUT;
    }
    return httpUtils.consume(
      subscriptionCustomerRequest,
      uri,
      method
    );
  }

  static updateCustomerPaymentMethodBankSlip(customerId) {
    return httpUtils.consume(
      {},
      apiEndpoints.SUBSCRIPTION_CUSTOMER_PAYMENT_METHOD_BANK_SLIP_ENDPOINT.split('{customerId}').join(customerId),
      httpMethods.REQUEST_METHOD_PUT
    );
  }

  static updateCustomerPaymentMethodCreditCard(subscriptionCustomerPaymentMethodRequest, customerId) {
    return httpUtils.consume(
      subscriptionCustomerPaymentMethodRequest,
      apiEndpoints.SUBSCRIPTION_CUSTOMER_PAYMENT_METHOD_CREDIT_CARD_ENDPOINT.split('{customerId}').join(customerId),
      httpMethods.REQUEST_METHOD_PUT
    );
  }

  static subscribeOrUpdateSubscription(subscriptionRequest, subscriptionId) {
    let method = httpMethods.REQUEST_METHOD_POST;
    let uri = apiEndpoints.SUBSCRIPTION_SUBSCRIBE_ENDPOINT;
    subscriptionRequest.productCode = global.AUTH_PRODUCT_CODE;
    if (subscriptionId !== null) {
      uri = apiEndpoints.SUBSCRIPTION_UPDATE_ENDPOINT.split('{subscriptionId}').join(subscriptionId);
      method = httpMethods.REQUEST_METHOD_PUT;
    }
    return httpUtils.consume(
      subscriptionRequest,
      uri,
      method
    );
  }

  static getCustomerInvoices(customerId, limit) {
    if (limit === undefined) {
      limit = global.MAX_RECORDS_PER_PAGE;
    }
    return httpUtils.consume(
      {},
      apiEndpoints.SUBSCRIPTION_CUSTOMER_INVOICES.split('{customerId}').join(customerId) + '?limit=' + limit,
      httpMethods.REQUEST_METHOD_GET
    );
  }
}
export default SubscriptionApi;