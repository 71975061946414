import { connect } from 'react-redux'
import Modal from '../../components/modal/Modal'

const mapStateToProps = (state, ownProps) => ({
  visible: ownProps.visible,
  title: ownProps.title,
  content: ownProps.content,
  showHeader: (ownProps.showHeader !== undefined) ? ownProps.showHeader : true
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCloseClick: () => ownProps.onCloseClick()
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Modal)
