import React from 'react'
import { Route, Redirect } from 'react-router'
import * as routes from '../../constants/routes';

const PrivateRoute = ({ isLogged, isFirstAccess, key, path, render, exact, trialPeriodExpired, indebted }) => {
  return (
    <Route
      key={key}
      path={path}
      render={() => {
          if (isLogged) {
            if (path !== routes.INVOICE_PATH && indebted === true) {
              return <Redirect to={{ pathname: routes.INVOICE_PATH }} />
            }
            if (path !== routes.SUBSCRIPTION_PATH && trialPeriodExpired === true) {
              return <Redirect to={{ pathname: routes.SUBSCRIPTION_PATH }} />
            }
            if (path !== routes.FIRST_ACCESS_CERTIFICATE_PATH && isFirstAccess) {
              return <Redirect to={{ pathname: routes.FIRST_ACCESS_CERTIFICATE_PATH }} />
            } else if (path === routes.FIRST_ACCESS_CERTIFICATE_PATH && !isFirstAccess) {
              return <Redirect to={{ pathname: routes.WELCOME_PATH }} />
            } else {
              return (render)
            }
          } else {
            return <Redirect to={{ pathname: '/login' }} />
          }
        }
      }
      exact={exact} 
    />
  )
}

export default PrivateRoute