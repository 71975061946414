import React, { Component } from "react";
import PropTypes from "prop-types";
import { v1 } from "uuid";
import configurationsApi from "../api/configurationsApi";
import Modal from '../containers/modal/Modal';
import storageUtils from "../utils/storageUtils";
import '../assets/switchCompany.css';
import Select from 'react-select';

class SwitchCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSwitchCompanyModal: false,
      items: [],
      choosedCompanyId: null
    };
    this.loadCompanies();
  }

  loadCompanies() {
    configurationsApi
      .getAll()
      .then(configurations => {
        if (configurations !== undefined) {
          if (configurations.status === undefined) {
            let companyConfig = configurations.find(config => config.type === 'company');
            if (companyConfig.data !== undefined) {
              if (companyConfig.data.items !== undefined) {
                this.setState({
                  items: companyConfig.data.items
                })
              }
            }
          }
        }
      });
  }

  closeSwitchCompanyModal = () => {
    this.setState({
      showSwitchCompanyModal: false
    });
  }

  chooseCompany(companyId) {
    const { changeCompanyDispatcher } = this.props;
    if (companyId !== 0) {
      this.setState({
        choosedCompanyId: companyId
      })
      const { items } = this.state;
      let selectedCompany = items.filter(c => { return c.id == companyId })[0];
      storageUtils.setItem('currentCompany', selectedCompany);
      changeCompanyDispatcher(selectedCompany);
      this.closeSwitchCompanyModal();
    }
  }

  buildSwitchCompanyModalContent() {
    const { items, choosedCompanyId } = this.state;
    let selectedId = 0;
    if (choosedCompanyId == null) {
      let currentCompany = storageUtils.getItem('currentCompany');
      if (currentCompany !== null) {
        selectedId = currentCompany.id;
      }
    } else {
      selectedId = choosedCompanyId;
    }
    let rsx = (<p>Carregando...</p>);

    let companyOptions = items.map(company => {
      return { value: company.id, label: company.companyName }
    })

    if (items.length > 0) {
      let brandColor = '#0199E6';
      rsx = (
        <Select defaultValue={companyOptions.filter(c => { return c.value == selectedId })} onChange={(e) => this.chooseCompany(e.value)} options={
          companyOptions
        } styles={{
          control: (base, state) => ({
            ...base,
            boxShadow: state.isFocused ? 0 : 0,
            borderColor: state.isFocused
              ? brandColor
              : base.borderColor,
            '&:hover': {
              borderColor: state.isFocused
                ? brandColor
                : base.borderColor,
              boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px rgba(1,153,230, 0.5)'
            }
          })
        }} />
      )
    }
    return (rsx);
  }
  createJsxComponent = () => {
    let componentLabel = 'Trocar Empresa';
    let jsx = (
      <div className="switch-company-box">
        <button type="button" className="mb-xs mt-xs mr-xs btn btn-primary"
          onClick={() => this.setState({ showSwitchCompanyModal: true })}>
          <i className="fas fa-exchange-alt"></i>
          <span>{componentLabel}</span>
        </button>
        <Modal visible={this.state.showSwitchCompanyModal}
          title={componentLabel}
          content={this.buildSwitchCompanyModalContent()}
          onCloseClick={this.closeSwitchCompanyModal} />
      </div>
    );
    return jsx;
  }
  render() {
    return this.createJsxComponent()
  }
}

SwitchCompany.propTypes = {
  changeCompanyDispatcher: PropTypes.func.isRequired
};

export default SwitchCompany;
