
/**
 * CSS Classes
 */
export const nfceStatusCss = {
  AUTORIZADO: 'label-success',
  CANCELADO: 'label-success',
  DENEGADO: 'label-success',
  REJEITADO: 'label-default'
}
