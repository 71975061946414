import * as types from '../constants/actionTypes';
import initialState from './initialState';
import merge from 'lodash/merge'

const mdfe = (state = initialState.mdfe, action) => {
  switch (action.type) {
    case types.MDFE_LIST_IS_LOADED: {
      let mdfe = {
        items: action.items,
        isLoading: false
      };
      return merge(state.mdfe, mdfe);
    }
    case types.MDFE_LIST_IS_LOADING: {
      let mdfe = {
        items: state.items,
        isLoading: true
      };
      return merge(state.mdfe, mdfe);
    }
    default:
      return state
  }
}
export default mdfe