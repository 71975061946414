import { combineReducers } from 'redux'
import topbar from './topbar'
import leftmenu from './leftmenu'
import nfe from './nfe';
import nfce from './nfce';
import nfse from './nfse';
import mdfe from './mdfe';
import cte from './cte';
import cfe from './cfe';
import cteos from './cteos';
import user from './user';
import shared from './shared';
import invoice from './invoice';

import { connectRouter } from 'connected-react-router'

export default (history) => combineReducers({
  router: connectRouter(history),
  topbar,
  leftmenu, 
  nfe,
  nfce,
  nfse,
  mdfe,
  cte,
  cteos,
  cfe,
  user,
  shared,
  invoice
})
