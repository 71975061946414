import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from './SpyXml/store/configureStore';
import routes from './SpyXml/config/routes';
import PublicRoute from './SpyXml/containers/routes/PublicRoute';
import PrivateRoute from './SpyXml/containers/routes/PrivateRoute';
import Notification from './SpyXml/containers/Notification';
import storageUtils from "./SpyXml/utils/storageUtils";
import { 
  loadConfigurations
} from './SpyXml/actions';

// async load data
const store = configureStore();

let loggedUser = storageUtils.getItem('user');
if (loggedUser != null) {
  store.dispatch(loadConfigurations());
}

render(
  <Provider store={store}>
    <Notification />
    <ConnectedRouter history={history}>
      <Switch>
        {routes.public.map((route, index) => (
          <PublicRoute key={index} path={route.path} render={route.render} exact={route.exact} />
        ))}
        {routes.private.map((route, index) => (
          <PrivateRoute key={index} path={route.path} render={route.render} exact={route.exact} />
        ))}
      </Switch>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('varitus-app')
)

