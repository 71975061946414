/**
 * OAuth HTTP Status
 */
export const oAuthHttpStatus = {
    INVALID_TOKEN: 4001,
    INVALID_CLIENT_ID: 4002,
    INVALID_CREDENTIALS: 4003
}
/**
 * Repository Status
 */
export const repositoryStatus = {
    SUCCESS_SAVED: 1001,
	ERROR_SAVED: 1002,
	OBJECT_SUCCESS_RETRIEVED: 1003,
	OBJECT_NOT_FOUND: 1004,
	SUCCESS_UPDATED: 1005,
	ERROR_UPDATED: 1006,
	SUCCESS_DELETED: 1007,
	ERROR_DELETED: 1008
}