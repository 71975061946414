/**
 * Global constants
 */

// Varitus API
export const VARITUS_API_PRODUCTION_ENV = '1';
export const VARITUS_API_STAGING_ENV= '2';

// Global
export const APP_LOCALE = "pt-br";

// URLs
export const AUTHORIZATION_SERVER_URL = process.env.REACT_APP_HOST_AUTH; 
export const NFE_API_URL = process.env.REACT_APP_HOST_NFE + '/nfe';
export const NFCE_API_URL = process.env.REACT_APP_HOST_NFCE + '/nfce';
export const MDFE_API_URL = process.env.REACT_APP_HOST_MDFE + '/mdfe';
export const CTE_API_URL = process.env.REACT_APP_HOST_CTE + '/cte';
export const CTE_OS_API_URL = process.env.REACT_APP_HOST_CTE_OS + '/cteos';
export const CFE_API_URL = process.env.REACT_APP_HOST_CFE + '/cfe'
export const NFSE_API_URL = process.env.REACT_APP_HOST_NFSE + '/nfse'
export const JSON_SERVER_URL = 'http://localhost:3004';
export const VIA_CEP_ENDPOINT = 'https://viacep.com.br'

// Pagination
export const MAX_RECORDS_PER_PAGE = 10;

// JWT Authentication
export const AUTH_CLIENT_ID = 'VARITUS_WEB';
export const AUTH_SECRET = 'tZLd9Zlc4ZGVPU';
export const AUTH_GRANT_TYPE = 'password';
export const AUTH_PRODUCT_CODE = 'XML_SPY';

// Iugu
export const IUGU_ID = '9428A622B4D74A99A530808727B9FBE0';
export const IUGU_TEST_MODE = true;
