import * as global from './global';

/**
 * API Endpoints constants
 */
/* Global */
export const PRODUCTS_ENDPOINT = global.AUTHORIZATION_SERVER_URL + '/product';  
export const CERTIFICATE_UPDATE_ENDPOINT = global.AUTHORIZATION_SERVER_URL + '/certificate/upload';  
export const CERTIFICATE_GET_BY_COMPANY_ENDPOINT = global.AUTHORIZATION_SERVER_URL + '/certificate/company/{companyId}/get';  
export const CONFIGURATIONS_ENDPOINT = global.AUTHORIZATION_SERVER_URL + '/configurations';  
export const TOKEN_ENDPOINT = global.AUTHORIZATION_SERVER_URL + '/oauth/token';
export const GOOGLE_TOKEN_ENDPOINT = global.AUTHORIZATION_SERVER_URL + '/signin/google';


/* Utils */
export const UTILS_GET_ADDRESS_ENDPOINT = global.VIA_CEP_ENDPOINT + '/ws/{zipCode}/json'

/* NF-e */
export const NFE_ENDPOINT = global.JSON_SERVER_URL + '/nfe';
export const NFE_GENERATE_DANFE_ENDPOINT = global.NFE_API_URL + '/danfe';
export const NFE_EXPORT_XML_ENDPOINT = global.NFE_API_URL + '/export/xml';
export const NFE_MANIFEST_ENDPOINT = global.NFE_API_URL + '/manifest';
export const NFE_LIST_ENDPOINT = global.NFE_API_URL + '/list';

/* NFC-e */
export const NFCE_LIST_ENDPOINT = global.NFCE_API_URL + '/list';
export const NFCE_GENERATE_DANFE_ENDPOINT = global.NFCE_API_URL + '/danfce';
export const NFCE_EXPORT_XML_ENDPOINT = global.NFCE_API_URL + '/export';

/* NFSE-e */
export const NFSE_LIST_ENDPOINT = global.NFSE_API_URL + '/list';
export const NFSE_GENERATE_DANFSE_ENDPOINT = global.NFSE_API_URL + '/danfse';
export const NFSE_CANCEL_ENDPOINT = global.NFSE_API_URL + '/cancel';
export const NFSE_EXPORT_XML_ENDPOINT = global.NFSE_API_URL + '/export';


/* MDF-e */
export const MDFE_LIST_ENDPOINT = global.MDFE_API_URL + '/list';
export const MDFE_DAMDFE_ENDPOINT = global.MDFE_API_URL + '/damdfe';
export const MDFE_EXPORT_ENDPOINT = global.MDFE_API_URL + '/export';

/* CT-e */
export const CTE_LIST_ENDPOINT = global.CTE_API_URL + '/list';
export const CTE_DACTE_ENDPOINT = global.CTE_API_URL + '/dacte';
export const CTE_EXPORT_ENDPOINT = global.CTE_API_URL + '/export';
export const CTE_DISACCORD_ENDPOINT = global.CTE_API_URL + '/disaccord';

/* CT-e OS */
export const CTE_OS_LIST_ENDPOINT = global.CTE_OS_API_URL + '/list';
export const CTE_OS_DACTE_ENDPOINT = global.CTE_OS_API_URL + '/dacte';
export const CTE_OS_EXPORT_ENDPOINT = global.CTE_OS_API_URL + '/export';

/* CF-e */
export const CFE_LIST_ENDPOINT = global.CFE_API_URL + '/list';
export const CFE_EXPORT_ENDPOINT = global.CFE_API_URL + '/export';

/* Subscription */
export const SUBSCRIPTION_PLANS_ENDPOINT = global.AUTHORIZATION_SERVER_URL + '/subscription/plans';  
export const SUBSCRIPTION_USER_DETAILS_ENDPOINT = global.AUTHORIZATION_SERVER_URL + '/subscription/users/{userId}/details?product_code=' + global.AUTH_PRODUCT_CODE;
export const SUBSCRIPTION_CUSTOMER_CREATE_ENDPOINT = global.AUTHORIZATION_SERVER_URL + '/subscription/customers';
export const SUBSCRIPTION_CUSTOMER_UPDATE_ENDPOINT = global.AUTHORIZATION_SERVER_URL + '/subscription/customers/{customerId}';
export const SUBSCRIPTION_CUSTOMER_PAYMENT_METHOD_BANK_SLIP_ENDPOINT = global.AUTHORIZATION_SERVER_URL + '/subscription/customers/{customerId}/bankSlip';
export const SUBSCRIPTION_CUSTOMER_PAYMENT_METHOD_CREDIT_CARD_ENDPOINT = global.AUTHORIZATION_SERVER_URL + '/subscription/customers/{customerId}/creditCard';
export const SUBSCRIPTION_CUSTOMER_INVOICES = global.AUTHORIZATION_SERVER_URL + '/subscription/customers/{customerId}/invoices';
export const SUBSCRIPTION_SUBSCRIBE_ENDPOINT = global.AUTHORIZATION_SERVER_URL + '/subscription/subscribe';
export const SUBSCRIPTION_UPDATE_ENDPOINT = global.AUTHORIZATION_SERVER_URL + '/subscription/{subscriptionId}';



