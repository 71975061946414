import { connect } from 'react-redux'
import CFeManage from '../../components/pages/CFeManage'
import { loadCFeGrid } from '../../actions';

const mapStateToProps = (state, ownProps) => {
  return {
    items: state.cfe.items ? state.cfe.items : [],
    isLoading: state.cfe.isLoading
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadCFeGrid: (items) => dispatch(loadCFeGrid(items))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CFeManage)