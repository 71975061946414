import * as apiEndpoints from '../constants/apiEndpoints';
import * as global from '../constants/global';
import { emailSentStatusValues } from '../enums/emailSentStatus';
import { cfePrintedStatusValues } from '../enums/cfePrintedStatus';
import { cfeDocTypeValues } from '../enums/cfeDocType';
import { periodTypeValues } from '../enums/periodTypes';
import storageUtils from '../utils/storageUtils';
import dateUtils from '../utils/dateUtils';
import httpUtils from '../utils/httpUtils';
import { varitusStatusValues } from '../enums/varitusStatus';

/**
 * CF-e API
 */
class CFeApi {
  
  static filterBy(
    periodType,
    startDate,
    endDate,
    cfeDocType,
    receiverDocument,
    status,
    serie,
    numberStart,
    numberEnd,
    emailSentStatus,
    cfePrintedStatus,
    accessKey,
    end = global.MAX_RECORDS_PER_PAGE,
    environmentType = storageUtils.getEnvironmentType(),
    companyDocument = storageUtils.getCurrentCompany().cnpj
  ) {
    let request = {
      'token': storageUtils.getVaritusApiToken(),
      'envType': environmentType,
      'companyDocument': companyDocument,
      'periodType': periodType,
      'startDate': dateUtils.formatUTC(startDate),
      'endDate': dateUtils.formatUTC(endDate),
      'docType': "RECEIVED",
      'receiverDocument': receiverDocument,
      'status': status,
      'serie': serie,
      'startNumber': numberStart,
      'endNumber': numberEnd,
      'emailSent': emailSentStatus,
      'cfePrinted': cfePrintedStatus,
      'accessKey': accessKey,
      'pageNumber': 1,
      'pageLimit': end
    };

    storageUtils.setItem('cfeSearchRequest', request);
    return httpUtils.consume(request, apiEndpoints.CFE_LIST_ENDPOINT);
  }

  static getAll(end = null) {
    if (end == null)
      end = global.MAX_RECORDS_PER_PAGE;
    return this.filterBy(periodTypeValues.INSERTION_DATE, new Date, new Date, cfeDocTypeValues.BOTH, null, varitusStatusValues.ALL, null, 1, 9999999, emailSentStatusValues.BOTH, cfePrintedStatusValues.BOTH, null);
  }

  static downloadXml(issuerDocument, serie, number, environmentType = storageUtils.getEnvironmentType()) {
    let request = {
      "token": storageUtils.getVaritusApiToken(),
      "issuerDocument": issuerDocument,
      "serie": serie,
      "number": number,
      "envType": environmentType
    }
    return httpUtils.consume(request, apiEndpoints.CFE_EXPORT_ENDPOINT);
  }
}

export default CFeApi;