import { connect } from "react-redux";
import SwitchEnvironment from "../components/SwitchEnvironment";
import { varitusApiEnvChangedDispatcher } from "../actions";
import storageUtils from "../utils/storageUtils";

const mapStateToProps = (state, ownProps) => ({
  startChecked: storageUtils.getStateFromLocalStorage('shared').varitusApiEnvType
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onEnvironmentChanged: (envType) => dispatch(varitusApiEnvChangedDispatcher(envType))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SwitchEnvironment);
