import { toast } from "react-toastify";
import React from "react";
import toastConfig from "../config/toast"
import '../assets/notifications.css'


const createNotificationSkeleton = (message, title, type) => {
  let notificationJsx = (
    <section className="notification">
      <div className="icon">
        <i className={toastConfig.toast[type]} />
      </div>
      <div className="content">
        <span className="title">{title}</span>
        <br />
        <span className="message">{message}</span>
      </div>
    </section>
  );
  return notificationJsx;
};

/**
 * Notification utils
 */
class NotificationUtils {
  static success = (message, title = "Sucesso") => {
    toast.success(createNotificationSkeleton(message, title, "success"));
  };

  static error = (message, title = "Erro") => {
    toast.error(createNotificationSkeleton(message, title, "error"));
  };

  static warn = (message, title = "Aviso") => {
    toast.warn(createNotificationSkeleton(message, title, "warning"));
  };

  static info = (message, title = "Informação") => {
    toast.info(createNotificationSkeleton(message, title, "info"));
  };
}

export default NotificationUtils;
