export const varitusStatusLabels = {
    TODOS: 'Todos',
    ASSINADO: 'Assinado',
    AUTORIZADO: 'Autorizado',
    AUTORIZADO_FORA_DO_PRAZO: 'Autorizado fora do prazo',
    CANCELADO: 'Cancelado',
    DENEGADO: 'Denegado',
    DIGITADO: 'Digitado',
    EPEC: 'Epec',
    INVALIDO: 'Inválido',
    REJEITADO: 'Rejeitado',
    TRANSMITIDO: 'Transmitido',
    VALIDADO: 'Validado',
    DOC_LOCALIZADO: 'Documento localizado',
}

export const varitusStatusValues = {
    ALL: 'ALL', 
    SIGNED: 'SIGNED', 
    AUTHORIZED: 'AUTHORIZED', 
    AUTHORIZED_OVERDUE: 'AUTHORIZED_OVERDUE', 
    CANCELED: 'CANCELED', 
    REFUSED: 'REFUSED', 
    TYPED: 'TYPED', 
    EPEC: 'EPEC', 
    INVALID: 'INVALID', 
    REJECTED: 'REJECTED', 
    TRANSMITTED: 'TRANSMITTED', 
    VALIDATED: 'VALIDATED', 
    LOCALIZED_DOC: 'LOCALIZED_DOC',
}
