import { connect } from 'react-redux'
import { userSignOut } from '../actions'
import TopBar from '../components/TopBar'

const mapStateToProps = (state, ownProps) => ({
  // logoImage: state.topbar.logoUrl,
  logoImage: ownProps.logoImage,
  cnpj: state.topbar.cnpj,
  companyName: state.topbar.companyName,
  userName: state.topbar.userName,
  showOverlay: (state.user.trialAccessPlanInfo.trialPeriodExpired) || (state.invoice.indebted)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  userSignOut: () => dispatch(userSignOut())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopBar)
