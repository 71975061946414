import React from 'react'
import App from '../containers/App';
import CertificateModal from '../containers/modal/CertificateModal';
import SigningForm from "../containers/SigningForm";
import NFeManage from '../containers/pages/NFeManage';
import NFCeManage from '../containers/pages/NFCeManage';
import NFSeManage from '../containers/pages/NFSeManage';
import MDFeManage from '../containers/pages/MDFeManage';
import CTeManage from '../containers/pages/CTeManage';
import CTeOSManage from '../containers/pages/CTeOSManage';
import CFeManage from '../containers/pages/CFeManage';
import Subscription from '../containers/pages/Subscription';
import InvoiceManage from '../containers/pages/InvoiceManage';
import CertificateSettings from '../containers/pages/CertificateSettings';
import * as routes from '../constants/routes';

export default {
  private: [
    {
      'path': routes.HOME_PATH,
      'render': (
        <App
          innerContent={
            (<p>&nbsp;</p>)
          }
          title="Início"
        />
      ),
      'exact': true
    },
    {
      'path': routes.NFE_MANAGE_PATH,
      'render': (
        <App
          innerContent={(<NFeManage />)}
          title="Gerenciar NF-e"
          breadCrumbs={["Gerenciar NF-e"]}
        />
      )
    },
    {
      'path': routes.NFCE_MANAGE_PATH,
      'render': (
        <App
          innerContent={(<NFCeManage />)}
          title="Gerenciar NFC-e"
          breadCrumbs={["Gerenciar NFC-e"]}
        />
      )
    },
    {
      'path': routes.NFSE_MANAGE_PATH,
      'render': (
        <App
          innerContent={(<NFSeManage />)}
          title="Gerenciar NFS-e"
          breadCrumbs={["Gerenciar NFS-e"]}
        />
      )
    },
    {
      'path': routes.MDFE_MANAGE_PATH,
      'render': (
        <App
          innerContent={(<MDFeManage />)}
          title="Gerenciar MDF-e"
          breadCrumbs={["Gerenciar MDF-e"]}
        />
      )
    },
    {
      'path': routes.CTE_MANAGE_PATH,
      'render': (
        <App
          innerContent={(<CTeManage />)}
          title="Gerenciar CT-e"
          breadCrumbs={["Gerenciar CT-e"]}
        />
      )
    },
    {
      'path': routes.CTE_OS_MANAGE_PATH,
      'render': (
        <App
          innerContent={(<CTeOSManage />)}
          title="Gerenciar CT-e OS"
          breadCrumbs={["Gerenciar CT-e OS"]}
        />
      )
    },
    {
      'path': routes.CFE_MANAGE_PATH,
      'render': (
        <App
          innerContent={(<CFeManage />)}
          title="Configurar CFe-Sat"
          breadCrumbs={["Configurar CFe-Sat"]}
        />
      )
    },
    {
      'path': routes.SUBSCRIPTION_PATH,
      'render': (
        <App
          innerContent={(<Subscription />)}
          title="Mensalidade"
          breadCrumbs={["Mensalidade"]}
        />
      )
    },
    {
      'path': routes.INVOICE_PATH,
      'render': (
        <App
          innerContent={(<InvoiceManage />)}
          title="Faturas"
          breadCrumbs={["Faturas"]}
        />
      )
    },
    {
      'path': routes.CERTIFICATE_SETTINGS_PATH,
      'render': (
        <App
          innerContent={(<CertificateSettings />)}
          title="Configurar Certificado"
          breadCrumbs={["Configurar Certificado"]}
        />
      )
    },
    {
      'path': routes.FIRST_ACCESS_CERTIFICATE_PATH,
      'render': (
        <CertificateModal />
      )
    }
  ],
  public: [
    {
      'path': routes.LOGIN_PATH,
      'render': (
        <SigningForm logoImage="assets/images/logo.png" />
      )
    }
  ]
}