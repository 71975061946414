import { connect } from "react-redux";
import ToggleSwitch from "../../components/form/ToggleSwitch";

const mapStateToProps = (state, ownProps) => ({
  visible: (ownProps.visible !== undefined) ? ownProps.visible : true,
  label: (ownProps.label !== undefined) ? ownProps.label : '',
  startChecked: (ownProps.startChecked !== undefined) ? ownProps.startChecked: false
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (checked) => ownProps.onChange(checked)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToggleSwitch);
