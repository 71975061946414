import { connect } from 'react-redux'
import LeftMenu from '../components/LeftMenu'
import { push } from 'connected-react-router'

const mapStateToProps = (state, ownProps) => ({
  items: state.leftmenu.items,
  showOverlay: (state.user.trialAccessPlanInfo.trialPeriodExpired) || (state.invoice.indebted)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  changePage: (page) => dispatch(push(page))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeftMenu)
