import { connect } from 'react-redux'
import PublicRoute from '../../components/routes/PublicRoute'

const mapStateToProps = (state, ownProps) => ({
  isLogged: (state.user.accessToken !== null),
  isFirstAccess: state.user.isFirstAccess,
  key: ownProps.key,
  path: ownProps.path,
  render: ownProps.render,
  exact: ownProps.exact
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicRoute)
