import * as types from '../constants/actionTypes';
import initialState from './initialState';
import merge from 'lodash/merge'

const nfe = (state = initialState.nfe, action) => {
  switch (action.type) {
    case types.NFE_LIST_IS_LOADED: {
      let nfe = {
        items: action.items,
        isLoading: false
      };
      return merge(state.nfe, nfe);
    }
    case types.NFE_LIST_IS_LOADING: {
      let nfe = {
        items: state.items,
        isLoading: true
      };
      return merge(state.nfe, nfe);
    }
    default:
      return state
  }
}
export default nfe