import { connect } from 'react-redux'
import MDFeManage from '../../components/pages/MDFeManage'
import { loadMDFeGrid } from '../../actions';

const mapStateToProps = (state, ownProps) => ({
  items: state.mdfe.items,
  isLoading: state.mdfe.isLoading
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadMDFeGrid: (items) => dispatch(loadMDFeGrid(items))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MDFeManage)
