import { connect } from 'react-redux'
import NFeManage from '../../components/pages/NFeManage'
import { loadNFeGrid } from '../../actions';

const mapStateToProps = (state, ownProps) => ({
  items: state.nfe.items,
  isLoading: state.nfe.isLoading
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadNFeGrid: (items) => dispatch(loadNFeGrid(items))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NFeManage)
