import { connect } from 'react-redux'
import CTeOSManage from '../../components/pages/CTeOSManage'
import { loadCTeOSGrid } from '../../actions';

const mapStateToProps = (state, ownProps) => ({
  items: state.cteos.items,
  isLoading: state.cteos.isLoading
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadCTeOSGrid: (items) => dispatch(loadCTeOSGrid(items))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CTeOSManage)