export const personRoleLabels = {
  ALL: 'Todos',
  SENDER: 'Remetente',
  DISPATCHER: 'Expedidor',
  SHIPMENT_RECEIVER: 'Recebedor',
  INVOICE_RECEIVER: 'Destinatário',
  HIRER: 'Tomador',
  ISSUER: 'Emitente'
}
export const personRoleValues = {
  ALL: 'ALL',
  SENDER: 'SENDER',
  DISPATCHER: 'DISPATCHER',
  SHIPMENT_RECEIVER: 'SHIPMENT_RECEIVER',
  INVOICE_RECEIVER: 'INVOICE_RECEIVER',
  HIRER: 'HIRER',
  ISSUER: 'ISSUER'
}