import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { v1 } from 'uuid';
import Grid from '../../containers/grid/Grid';
import Modal from '../../containers/modal/Modal';
import nfseGridTranslator from '../../config/translators/nfseGridTranslator';
import fileUtils from '../../utils/fileUtils';
import { nfseStatusCss, nfseStatusLabels } from '../../enums/nfseStatus';
import { nfseStatusValues } from '../../enums/nfseStatus'
import { periodTypeLabels } from '../../enums/periodTypes';
import { periodTypeValues } from '../../enums/periodTypes';
import { emailSentStatusValues } from '../../enums/emailSentStatus';
import { emailSentStatusLabels } from '../../enums/emailSentStatus';
import nfseApi from '../../api/nfseApi';
import DatePicker from '../../containers/form/DatePicker';
import notificationUtils from '../../utils/notificationUtils';
import LoadingSpinner from '../../containers/LoadingSpinner';
import * as global from '../../constants/global';
import storageUtils from "../../utils/storageUtils";
import { nfseTypeValues } from '../../enums/nfseType';
import { nfseTypeLabels } from '../../enums/nfseType';
import { eventTypeFilterLabels } from "../../enums/eventTypesFilter";


import '../../assets/pages/nfeManage.css';
import NFSeApi from '../../api/nfseApi';

class NFSeManage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      scrollDownCurrentLimit: global.MAX_RECORDS_PER_PAGE,
      showPendenciesModal: false,
      showFiltersModal: false,
      selectedRow: null,
      showLoadingSpinner: this.props.isLoading,
      filters: { 
        periodType: periodTypeValues.EMISSION_DATE,
        initialDate: new Date(),
        finalDate: new Date(),
        sendType: nfseTypeValues.ALL,
        nfseLocalStatus: nfseStatusValues.PROCESSED,
        emailSent: emailSentStatusValues.BOTH,
        serie: null,
        nfseInitialNumber: null,
        nfseFinalNumber: null,
        rpsInitialNumber: 1,
        rpsFinalNumber: 9999999,
        manifest: null,
        cpfCnpj: null,
        companyName: null,
        envType: storageUtils.getEnvironmentType(),
        end: global.MAX_RECORDS_PER_PAGE
      }
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if(nextProps.isLoading !== null){
      this.setState({
        showLoadingSpinner: nextProps.isLoading
      })
    }
  }

  handleChangeStartDate = (date) => {
    if (new Date(date).toString() !== "Invalid Date") {
      this.setState({
        filters: {
          ...this.state.filters,
          initialDate: date
        }
      });
    }
  }

  handleChangeEndDate = (date) => {
    if (new Date(date).toString() !== "Invalid Date") {
      this.setState({
        filters: {
          ...this.state.filters,
          finalDate: date
        }
      });
    }
  }

  handleFilters = (type, value) => {

    this.setState({
      filters: {
        ...this.state.filters,
        [type]: (value === '' ? null : value)
      }
    })

  }

  normalizeNFSes(nfseArray) {

    if(nfseArray){
   nfseArray = nfseArray.map(item => {
      //  item.details.nfseOk = item.details.nfseOk ? "Sim" : "Não";
      return item;
    });

    return nfseArray;
  }}
  
  buildFilters() {
    const {
      periodType,
      initialDate,
      finalDate,
      sendType,
      nfseLocalStatus,
      emailSent,
      serie,
      nfseInitialNumber,
      nfseFinalNumber,
      rpsInitialNumber,
      rpsFinalNumber,
      companyName,
      manifest,
      cpfCnpj,
      envType
    } = this.state.filters;


    return (
      <form className="form-horizontal form-bordered" onSubmit={
        (e) => {
          e.preventDefault();
          const { loadNFSeGrid } = this.props;
          const{
            periodType,
            initialDate,
            finalDate,
            sendType,
            nfseLocalStatus,
            emailSent,
            serie,
            nfseInitialNumber,
            nfseFinalNumber,
            rpsInitialNumber,
            rpsFinalNumber,
            manifest,
            cpfCnpj,
            companyName,
            envType,
          } = this.state.filters;
          if (initialDate !== undefined
            && finalDate !== undefined) {
              this.setState({showLoadingSpinner:true});
            NFSeApi.filterBy(
              periodType,
              initialDate,
              finalDate,
              sendType,
              nfseLocalStatus,
              emailSent,
              serie,
              nfseInitialNumber,
              nfseFinalNumber,
              rpsInitialNumber,
              rpsFinalNumber,
              manifest,
              cpfCnpj,
              companyName,
              envType,
            ).then(response => {
              if (response === undefined) {
                notificationUtils.warn('Falha na API da NFS-e.');
                return;
              }
              if (response.message === undefined) {
                notificationUtils.warn('Falha na API da NFS-e.');
                return;
              }
              let message = response.message;
              if (message.success) {
                  response.nfses = this.normalizeNFSes(response.nfses);
                  loadNFSeGrid(response.nfses);
                this.closeFilterModal();
              } else {
                notificationUtils.warn(message.details);
              }
            }).then(() => {this.setState({showLoadingSpinner: false });});
          }

        }
      }>
        <section className="panel">
          <div className="panel-body">
            <div className="form-group">
              <div>
                <label className="col-md-3 control-label">Tipo de período</label>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-9">
                          <select onChange={
                            (e) => this.handleFilters('periodType', e.target.value)
                          } value={periodType} className="form-control mb-md">
                            {
                              Object.keys(periodTypeLabels).map(x => {
                                return <option value={x} key={v1()}>{periodTypeLabels[x]}</option>
                              })
                            }
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <label className="col-md-3 control-label">Datas</label>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <DatePicker selectedDate={initialDate} className="form-control" onChange={this.handleChangeStartDate} />
                        </div>
                        <span className="space col-md-1">Até</span>
                        <div className="col-md-4">
                          <DatePicker selectedDate={finalDate} className="form-control" onChange={this.handleChangeEndDate} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <div>
              <label className="col-md-3 control-label">Manifesto</label>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <select onChange={
                            (e) => this.handleFilters('manifest', e.target.value)
                          } value={manifest} className="form-control mb-md">
                            {
                              Object.keys(eventTypeFilterLabels).map(x => {
                                return <option value={x} key={v1()}>{eventTypeFilterLabels === 'TODOS'? null : eventTypeFilterLabels[x]}</option>
                              })
                            }
                          </select>
                        </div>
                        <span className="space col-md-1">Série</span>
                        <div className="col-md-4">
                          <input onChange={
                            (e) => this.handleFilters('serie', e.target.value)
                          } value={serie !== null ? serie : ''} type="number" className="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

           

            <div className="form-group">
              <div>
                <label className="col-md-3 control-label">Número</label>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <input onChange={
                            (e) => this.handleFilters('nfseInitialNumber', e.target.value)
                          } value={nfseInitialNumber !== null ? nfseInitialNumber : ''} type="number" className="form-control" />
                        </div>
                        <span className="space col-md-1">Até</span>
                        <div className="col-md-4">
                          <input onChange={
                            (e) => this.handleFilters('nfseFinalNumber', e.target.value)
                          } value={nfseFinalNumber !== null ? nfseFinalNumber : ''} type="number" className="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <div>
                <label className="col-md-3 control-label">Número RPS</label>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <input onChange={
                            (e) => this.handleFilters('rpsInitialNumber', e.target.value)
                          } value={rpsInitialNumber !== null ? rpsInitialNumber : ''} type="number" className="form-control" />
                        </div>
                        <span className="space col-md-1">Até</span>
                        <div className="col-md-4">
                          <input onChange={
                            (e) => this.handleFilters('rpsFinalNumber', e.target.value)
                          } value={rpsFinalNumber !== null ? rpsFinalNumber : ''} type="number" className="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <div>
              <label className="col-md-3 control-label">E-mail enviado</label>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <select onChange={
                            (e) => this.handleFilters('emailSent', e.target.value)
                          } value={emailSent} className="form-control mb-md">
                            {
                              Object.keys(emailSentStatusLabels).map(x => {
                                return <option value={x} key={v1()}>{emailSentStatusLabels[x]}</option>
                              })
                            }
                          </select>
                        </div>
                        <span className="space col-md-1">Status</span>
                        <div className="col-md-4">
                              <select onChange={
                            (e) => this.handleFilters('nfseLocalStatus', e.target.value)
                          } value={nfseLocalStatus} className="form-control mb-md">
                            {
                              Object.keys(nfseStatusLabels).map(x => {
                                return <option value={x} key={v1()}>{nfseStatusLabels[x]}</option>
                              })
                            }
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <div>
              <label className="col-md-3 control-label">Tipo de envio</label>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <select onChange={
                            (e) => this.handleFilters('sendType', e.target.value)
                          } value={sendType} className="form-control mb-md">
                            {
                              Object.keys(nfseTypeLabels).map(x => {
                                return <option value={x} key={v1()}>{nfseTypeLabels[x]}</option>
                              })
                            }
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className="col-md-3 control-label">CPF/CNPJ</label>
              <div className="col-md-7">
                <div className="row">
                  <div className="col-md-12">
                    <input onChange={
                      (e) => this.handleFilters('cpfCnpj', e.target.value)
                    } value={cpfCnpj !== null ? cpfCnpj : ''} className="form-control" />
                  </div>
                </div>
              </div>
            </div>
            
            <div className="form-group">
              <label className="col-md-3 control-label">Razão Social</label>
              <div className="col-md-7  ">
                <div className="row">
                  <div className="col-md-12">
                    <input onChange={
                      (e) => this.handleFilters('companyName', e.target.value)
                    } value={companyName !== null ? companyName : ''} className="form-control" />
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group pull-right">
              <div className="col-md-12">
                <button className="btn btn-primary">Pesquisar</button>
              </div>
            </div>
          </div>
        </section>
      </form>
    );
  }

  downloadXml = (selectedRow) => {
    let data = selectedRow.data;
    let details = selectedRow.details;
    if (data !== undefined) {
      let rpsSeries = data.serieRps;
      let rpsNumber = data.numberRps; 
      let nfseEmissionDate = data.issuerDate;
      let providerCnpj = data.document;
      let cityServiceCode = details.codeServiceCity;
      this.setState({ showLoadingSpinner: true }, () => {
        nfseApi.downloadXml(rpsSeries, rpsNumber, nfseEmissionDate, providerCnpj, cityServiceCode).then(response => {
          let message = response.message;
          if (message !== undefined) {
            if (message.success === false) {
              notificationUtils.warn(message.details);
            } else {
              notificationUtils.success(message.details);
              let zipNFSeAsBase64 = response.zipNFSeAsBase64;
              let fu = new fileUtils();
              let mimeType = fu.getBase64MimeType(zipNFSeAsBase64);
              fu.downloadFile(zipNFSeAsBase64, rpsSeries + '_' + rpsNumber + fu.getExtension(mimeType));


            }
          }
        }).then(() => {this.setState({showLoadingSpinner: false });});
      });
    }
  }
  
  buildPendenciesModalContent(selectedRow) {
    let pendenciesDescription = '';
    if (selectedRow !== null) {
      if (selectedRow.data.pendenciesDescription !== undefined) {
        pendenciesDescription = selectedRow.data.pendenciesDescription;
      }
    }
    return (
      <p>
        {pendenciesDescription}
      </p>
    );
  }
  
  printDanfse = (rowData) => {
    let data = rowData.data;
    let details = rowData.details;
    if (data !== undefined) {
      let rpsSeries = data.serieRps;
      let rpsNumber = data.numberRps; 
      let nfseEmissionDate = data.issuerDate;
      let providerCnpj = data.document;
      let cityServiceCode = details.codeServiceCity;     
      this.setState({ showLoadingSpinner: true }, () => {
        nfseApi.printDanfse(rpsSeries, rpsNumber, nfseEmissionDate, providerCnpj, cityServiceCode).then(response => {
          let message = response.message;
          if (message !== undefined) {
            if (message.success === false) {
              notificationUtils.warn(message.details);
            } else {
              notificationUtils.success(message.details);
              let danfseAsBase64 = response.danfseAsBase64;
              let fu = new fileUtils();
              let mimeType = fu.getBase64MimeType(danfseAsBase64);
              fu.downloadFile(danfseAsBase64, rpsSeries + '_' + rpsNumber + fu.getExtension(mimeType));
            }
          }
        }).then(() => {
          this.setState({
            showLoadingSpinner: false
          });
        });
      });
    }
  }

  cancel = (rowData) => {
    let data = rowData.data;
    let details = rowData.details;
    if (data !== undefined) {
      let rpsSeries = data.serieRps;
      let rpsNumber = data.numberRps; 
      let nfseEmissionDate = data.issuerDate;
      let providerCnpj = data.document;
      let cityServiceCode = details.codeServiceCity;
      let justification;     
      this.setState({ showLoadingSpinner: true }, () => {
        nfseApi.cancel(rpsSeries, rpsNumber, nfseEmissionDate, providerCnpj, justification, cityServiceCode).then(response => {
          let message = response.message;
          if (message !== undefined) {
            if (message.success === false) {
              notificationUtils.warn(message.details);
            } else {
              notificationUtils.success(message.details);
            }
          }
        }).then(() => {
          this.setState({
            showLoadingSpinner: false
          });
        });
      });
    }
  }

  closeFilterModal = () => {
    this.setState({
      showFiltersModal: false
    });
  }

  scrollDown = (endLimit) => {
    this.setState({
      scrollDownCurrentLimit: endLimit,
      showLoadingSpinner: true
    });
    this.loadGridWithFilters(endLimit)
  }
 
  loadGridWithFilters = (endLimit) => {
    const { loadNFSeGrid } = this.props;
    const {
      periodType,
      initialDate,
      finalDate,
      sendType,
      nfseLocalStatus,
      emailSent,
      serie,
      nfseInitialNumber,
      nfseFinalNumber,
      rpsInitialNumber,
      rpsFinalNumber,
      manifest,
      cpfCnpj,
      envType,
      companyName,
      end
    } = this.state.filters;
 
    NFSeApi.filterBy(
      periodType,
      initialDate,
      finalDate,
      sendType,
      nfseLocalStatus,
      emailSent,
      serie,
      nfseInitialNumber,
      nfseFinalNumber,
      rpsInitialNumber,
      rpsFinalNumber,
      manifest,
      cpfCnpj,
      companyName,
      envType,
      endLimit
    ).then(response => {
      let message = response.message;
      if (message !== undefined) {
        if (message.success) {
          loadNFSeGrid(response.nfses);
        }
      }

    }).then(() => {this.setState({showLoadingSpinner: false });});
  }
  
  buildGrid() {
    const { items } = this.props;
    let headerColumns = [
      'insertionDateTime',
      'serieRps',
      'numberRps',
      'numberNfse',
      'document',
      'companyNameIssuer',
      'statusLocal'
    ];
  
    return (
      <Grid
        items={items}
        headerColumns={headerColumns}
        showDetails={true}
        translator={nfseGridTranslator}
        zebraStripedTable={
          {
            field: 'statusLocal',
            cssTranslator: nfseStatusCss
          }
        }
        availableCallbacks={
          {
            'printDanfse': this.printDanfse,
            'downloadXml': this.downloadXml
            // 'cancel': this.cancel
          }
        }
        onScrollDown={this.scrollDown}
      />
    );
  }
  createJsxComponent = () => {
    const { selectedRow } = this.state;
    let nfseGridJsx = (
      <div>
        <div style={{ marginBottom: '10px' }}>
          <button className="btn btn-primary" onClick={() => this.setState({ showFiltersModal: true })}>
            <span className="space">Pesquisar Notas</span>
            <i className="fas fa-search"></i>
          </button>
        </div>
        {this.buildGrid()}
        <Modal visible={this.state.showPendenciesModal} title="Pendências" content={this.buildPendenciesModalContent(selectedRow)} onCloseClick={this.closePendenciesModal} />
        <Modal visible={this.state.showFiltersModal} title="Filtro de Pesquisa" content={this.buildFilters()} onCloseClick={this.closeFilterModal} />
        <LoadingSpinner visible={this.state.showLoadingSpinner} />
      </div>
    );
    return nfseGridJsx;
  }

  render() {
    return this.createJsxComponent()
  }
}

NFSeManage.propTypes = {
  items: PropTypes.array,
  loadNFSeGrid: PropTypes.func
}

export default NFSeManage