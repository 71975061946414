export default {
    'translation': [
        // Labels
        { 'key': 'serie', 'value': 'Série' },
        { 'key': 'number', 'value': 'Número' },
        { 'key': 'emissionDate', 'value': 'Data Emissão' },
        { 'key': 'sender', 'value': 'Emitente' },
        { 'key': 'shipmentState', 'value': 'UF Embarque'},
        { 'key': 'landingState', 'value': 'UF Desembarque'},
        { 'key': 'varitusStatus', 'value': 'Situação Varitus' },
        { 'key': 'sefazAuthorizationDate', 'value': 'Data de Autorização na Sefaz' },
        { 'key': 'damdfeWasPrinted', 'value': 'Damdfe Impresso' },
        { 'key': 'emailWasSent', 'value': 'E-mail Enviado' },
        { 'key': 'validSignature', 'value': 'Assinatura Válida' },   
        { 'key': 'accessKey', 'value': 'Chave de Acesso' },
        { 'key': true, 'value': 'Sim' },
        { 'key': false, 'value': 'Não' },
        { 'key': 'SIGNED', 'value': 'ASSINADO' },
        { 'key': 'AUTHORIZED', 'value': 'AUTORIZADO' },
        { 'key': 'CANCELED', 'value': 'CANCELADO' },
        { 'key': 'DENIED', 'value': 'DENEGADO' },
        { 'key': 'TYPED', 'value': 'DIGITADO' },
        { 'key': 'CLOSED', 'value': 'ENCERRADO' },
        { 'key': 'SENT', 'value': 'TRANSMITIDO' },
        { 'key': 'REJECTED', 'value': 'REJEITADO' },
        { 'key': 'VALIDATED', 'value': 'VALIDADO' },        
        // Actions
        { 'key': 'downloadDamdfe', 'value': 'fas fa-print' },
        { 'key': 'downloadXml', 'value': 'fas fa-download' },
        { 'key': 'showPendencies', 'value': 'fas fa-exclamation-triangle' }
    ]
}