import { connect } from "react-redux";
import DatePicker from "../../components/form/DatePicker";

const mapStateToProps = (state, ownProps) => ({
    selectedDate: (ownProps.selectedDate !== undefined) ? ownProps.selectedDate : new Date(),
    className: ownProps.className
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (ownProps.onChange !== undefined) ? ownProps.onChange : (value) => value
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DatePicker);
