
/**
 * CSS Classes
 */
export const cteStatusCss = {
  AUTHORIZED: 'label-success',
  CANCELED: 'label-success',
  DENIED: 'label-success',
  CLOSED: 'label-success',
  REJECTED: 'label-danger'
}

/**
 * Labels
 */
export const cteStatusLabels = {
  ALL: 'Todos',
	TYPED: 'DIGITADO', 
	SIGNED: 'ASSINADO',
	VALIDATED: 'VALIDADO',
	SENT: 'TRANSMITIDO',
	AUTHORIZED: 'AUTORIZADO', 
	CANCELED: 'CANCELADO',
	DENIED: 'DENEGADO',
	REJECTED: 'REJEITADO',
	UNUSED: 'INUTILIZADO'
}
