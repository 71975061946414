import React from "react";
import PropTypes from "prop-types";
import { Textbox } from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";

const TextBox = ({ ...props }) => {
  return createJsxComponent(props);
};

const createJsxComponent = props => {
  if (props.validationOption.mail === true) {
    props.validationOption.customFunc = email => {
      const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (reg.test(String(email).toLowerCase())) {
        return true;
      } else {
        return "Endereço de e-mail inválido";
      }
    };
  }
  return (<Textbox {...props} locale="pt-BR" />);
};

TextBox.propTypes = {
  autoClose: PropTypes.number
};

export default TextBox;
