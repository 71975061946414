export default {
  'translation': [
      // Labels
      { key: 'status', value: 'Situação' },
      { key: 'dueDate', value: 'Data de vencimento' },
      { key: 'formattedPrice', value: 'Valor (R$)' },
      { key: 'payableWith', value: 'Forma de pagamento' },
      // Status
      { key: 'paid', value: 'Paga' },
      { key: 'pending', value: 'Pendente' },
      { key: 'canceled', value: 'Cancelada' },
      { key: 'draft', value: 'Em processamento' },
      // Payment Methods
      { key: 'bank_slip', value: 'Boleto' },
      { key: 'credit_card', value: 'Cartão de crédito'},
      // Actions
      { 'key': 'downloadInvoice', 'value': 'fas fa-external-link-alt' }
  ]
}