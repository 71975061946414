import * as apiEndpoints from '../constants/apiEndpoints';
import * as httpMethods from '../constants/httpMethods';
import axios from 'axios';
import * as global from '../constants/global';
import * as contentTypes from '../constants/contentTypes';

/**
 * Users API
 */
class UsersApi {
  /**
   * Check login and get token
   * @param {string} username 
   * @param {string} password 
   */
  static checkLogin(username, password) {
    const TOKEN_ENDPOINT = apiEndpoints.TOKEN_ENDPOINT;
  
    var formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    formData.append('grant_type', global.AUTH_GRANT_TYPE);
    formData.append('product_code', global.AUTH_PRODUCT_CODE);
    
    var base64Credentials = btoa(global.AUTH_CLIENT_ID + ':' + global.AUTH_SECRET);
    return axios({
      method: httpMethods.REQUEST_METHOD_POST,
      url: TOKEN_ENDPOINT,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic ' + base64Credentials
      },
      data: formData
    }).then((response) => {
      return response.data;
    }).catch((error) => {
      return error.response;
    });
  }

  static checkGoogleLogin(token) {
    const GOOGLE_TOKEN_ENDPOINT = apiEndpoints.GOOGLE_TOKEN_ENDPOINT;
  
    var formData = {
      'token': token,
      'clientId': global.AUTH_CLIENT_ID,
      'productCode': global.AUTH_PRODUCT_CODE
    };
    //formData.append('token', token);
    //formData.append('grant_type', global.AUTH_GRANT_TYPE);
    //formData.append('clientId', 'VARITUS_WEB');
    //formData.append('productCode', global.AUTH_CLIENT_ID);

    return axios({
      method: httpMethods.REQUEST_METHOD_POST,
      url: GOOGLE_TOKEN_ENDPOINT,
      headers: {
        'Content-Type': contentTypes.CONTENT_TYPE_APPLICATION_JSON,
      },
      data: formData
    }).then((response) => {
      return response.data;
    }).catch((error) => {
      return error.response.data;
    });
  }
}
export default UsersApi;  