import * as apiEndpoints from '../constants/apiEndpoints';
import * as global from '../constants/global';
import * as httpMethods from '../constants/httpMethods';
import * as contentTypes from '../constants/contentTypes';
import storageUtils from "../utils/storageUtils";
import axios from 'axios';

/**
 * NFeApi API
 */
class NFeApi {
  static exportNFeXmlAsZip(issuerCnpj, serie, number) {
    const NFE_EXPORT_XML_ENDPOINT = apiEndpoints.NFE_EXPORT_XML_ENDPOINT;
    let user = storageUtils.getItem('user');
    let currentCompany = storageUtils.getItem('currentCompany');
    let accessToken = '';
    if (user !== null) {
      accessToken = user.accessToken;
    }
    let environmentType = storageUtils.getStateFromLocalStorage('shared').varitusApiEnvType;
    let exportNFeXmlAsZipRequest = {
      "token": currentCompany.varitusApiToken,
      "nfeKey": {
        "issuerCnpj": issuerCnpj,
        "serie": serie,
        "number": number,
        "environmentType": environmentType
      }
    }
    return axios({
      method: httpMethods.REQUEST_METHOD_POST,
      url: NFE_EXPORT_XML_ENDPOINT,
      data: exportNFeXmlAsZipRequest,
      headers: { 
        'Content-Type': contentTypes.CONTENT_TYPE_APPLICATION_JSON,
        'Authorization': 'bearer ' + accessToken
     }
    }).then(response => {
      return response.data;
    }).catch(error => {
      return error.response;
    });
  }

  static generateDanfe(issuerCnpj, serie, number) {
    const NFE_GENERATE_DANFE_ENDPOINT = apiEndpoints.NFE_GENERATE_DANFE_ENDPOINT;
    let user = storageUtils.getItem('user');
    let currentCompany = storageUtils.getItem('currentCompany');
    let accessToken = '';
    if (user !== null) {
      accessToken = user.accessToken;
    }
    let environmentType = storageUtils.getStateFromLocalStorage('shared').varitusApiEnvType;
    let generateDanfeRequest = {
      "token": currentCompany.varitusApiToken,
      "issuerCnpj": issuerCnpj,
      "serie": serie,
      "number": number,
      'environmentType': environmentType,
      'danfeType': 'FULL'
    };
    return axios({
      method: httpMethods.REQUEST_METHOD_POST,
      url: NFE_GENERATE_DANFE_ENDPOINT,
      data: generateDanfeRequest,
      headers: { 
        'Content-Type': contentTypes.CONTENT_TYPE_APPLICATION_JSON,
        'Authorization': 'bearer ' + accessToken
     }
    }).then(response => {
      return response.data;
    }).catch(error => {
      return error.response;
    });
  }
  static getAll(end = null) {
    if (end == null) {
      end = global.MAX_RECORDS_PER_PAGE;
    }
    return this.filterBy(new Date(), new Date(), null, null, 1, 9999999, null, null);
  }
  static manifest(issuerCnpj, serie, number, eventType, justify) {
    const NFE_MANIFEST_ENDPOINT = apiEndpoints.NFE_MANIFEST_ENDPOINT;
    let user = storageUtils.getItem('user');
    let currentCompany = storageUtils.getItem('currentCompany');
    let accessToken = '';
    if (user !== null) {
      accessToken = user.accessToken;
    }
    let environmentType = storageUtils.getStateFromLocalStorage('shared').varitusApiEnvType;
    let manifestRequest = {
      "token": currentCompany.varitusApiToken,
      "issuerCnpj": issuerCnpj,
      "serie": serie,
      "number": number,
      "environmentType": environmentType,
      "typeEvent": eventType
    };
    if (justify.length > 0) {
      manifestRequest['justify'] = justify;
    }
    return axios({
      method: httpMethods.REQUEST_METHOD_POST,
      url: NFE_MANIFEST_ENDPOINT,
      data: manifestRequest,
      headers: { 
        'Content-Type': contentTypes.CONTENT_TYPE_APPLICATION_JSON,
        'Authorization': 'bearer ' + accessToken
     }
    }).then(response => {
      return response.data;
    }).catch(error => {
      return error.response;
    });
  }
  static filterBy(
    emissionDateStart, 
    emissionDateEnd,
    sendType, 
    varitusStatus, 
    serie, 
    numberStart, 
    numberEnd, 
    accessKey, 
    companyName, 
    end = global.MAX_RECORDS_PER_PAGE, 
    environmentType = storageUtils.getStateFromLocalStorage('shared').varitusApiEnvType
  ) {
    // Convert to JSON object date
    emissionDateStart = JSON.parse(JSON.stringify(emissionDateStart).replace(/(\{|,)\s*(.+?)\s*:/g, '$1 "$2":'));
    emissionDateEnd = JSON.parse(JSON.stringify(emissionDateEnd).replace(/(\{|,)\s*(.+?)\s*:/g, '$1 "$2":'));

    let user = storageUtils.getItem('user');
    let currentCompany = storageUtils.getItem('currentCompany'); 
    let searchRequest = {
      'token': currentCompany.varitusApiToken,
      'serie': serie,
      'startNumber': numberStart,
      'endNumber': numberEnd,
      'companyName': companyName,
      'accessKey': accessKey,
      'status': varitusStatus,
      'startDate': emissionDateStart,
      'endDate': emissionDateEnd,
      "sendType": sendType,
      'environmentType': environmentType,
      'pageNumber': 1,
      'pageLimit': end
    };
    // save in localstorage
    storageUtils.setItem('nfeSearchRequest', searchRequest);

    return axios({
      method: httpMethods.REQUEST_METHOD_POST,
      url: apiEndpoints.NFE_LIST_ENDPOINT,
      data: searchRequest,
      headers: { 
        'Content-Type': contentTypes.CONTENT_TYPE_APPLICATION_JSON,
        'Authorization': 'bearer ' + user.accessToken
     }
    }).then(response => {
      return response.data;
    }).catch(error => {
      return error.response;
    });
  }
}
export default NFeApi;  