import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { v1 } from "uuid";
import Grid from '../../containers/grid/Grid';
import Modal from '../../containers/modal/Modal';
import nfceGridTranslator from '../../config/translators/nfceGridTranslator';
import fileUtils from "../../utils/fileUtils";
import { nfceStatusCss } from "../../enums/nfceStatus";
import { varitusStatusLabels } from "../../enums/varitusStatus";
import nfceApi from "../../api/nfceApi";
import DatePicker from '../../containers/form/DatePicker';
import notificationUtils from "../../utils/notificationUtils";
import classNames from 'classnames';
import LoadingSpinner from '../../containers/LoadingSpinner';
import * as global from '../../constants/global';

import '../../assets/pages/nfeManage.css';

class NFCeManage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      scrollDownCurrentLimit: global.MAX_RECORDS_PER_PAGE,
      showPendenciesModal: false,
      showFiltersModal: false,
      selectedRow: null,
      showLoadingSpinner: false,
      filters: {
        emissionDateStart: new Date(),
        emissionDateEnd: new Date(),
        varitusStatus: 'TODOS',
        serie: null,
        numberStart: '1',
        numberEnd: '9999999',
        accessKey: null,
        companyName: null
      }
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if(nextProps.isLoading !== null){
      this.setState({
        showLoadingSpinner: nextProps.isLoading
      })
    }
  }

  handleChangeEmissionDateStart = (date) => {
    if (new Date(date).toString() !== "Invalid Date") {
      this.setState({
        filters: {
          ...this.state.filters,
          emissionDateStart: date
        }
      });
    }
  }

  handleChangeEmissionDateEnd = (date) => {
    if (new Date(date).toString() !== "Invalid Date") {
      this.setState({
        filters: {
          ...this.state.filters,
          emissionDateEnd: date
        }
      });
    }
  }

  handleFilters = (type, value) => {
    switch (type) {
      case 'varitusStatus': {
        this.setState({
          filters: {
            ...this.state.filters,
            'varitusStatus': value
          }
        })
        break;
      }
      case 'serie': {
        this.setState({
          filters: {
            ...this.state.filters,
            'serie': (value === '' ? null : value)
          }
        })
        break;
      }
      case 'numberStart': {
        this.setState({
          filters: {
            ...this.state.filters,
            'numberStart': value
          }
        })
        break;
      }
      case 'numberEnd': {
        this.setState({
          filters: {
            ...this.state.filters,
            'numberEnd': value
          }
        })
        break;
      }
      case 'accessKey': {
        this.setState({
          filters: {
            ...this.state.filters,
            'accessKey': (value === '' ? null : value)
          }
        })
        break;
      }
      case 'companyName': {
        this.setState({
          filters: {
            ...this.state.filters,
            'companyName': (value === '' ? null : value)
          }
        })
        break;
      }
      default: {
        this.setState({
          filters: {
            ...this.state.filters
          }
        })
        break;
      }
    }
  }

  buildFilters() {
    const {
      emissionDateStart,
      emissionDateEnd,
      varitusStatus,
      serie,
      numberStart,
      numberEnd,
      accessKey,
      companyName
    } = this.state.filters;

    return (
      <form className="form-horizontal form-bordered" onSubmit={
        (e) => {
          e.preventDefault();
          const { loadNFCeGrid } = this.props;
          const {
            emissionDateStart,
            emissionDateEnd,
            varitusStatus,
            serie,
            numberStart,
            numberEnd,
            accessKey,
            companyName
          } = this.state.filters;

          if (emissionDateStart !== undefined
            && emissionDateEnd !== undefined) {
              this.setState({showLoadingSpinner:true});
            nfceApi.findBy(
              emissionDateStart,
              emissionDateEnd,
              varitusStatus,
              serie,
              numberStart,
              numberEnd,
              accessKey,
              companyName
            ).then(response => {
              if (response === undefined) {
                notificationUtils.warn('Falha na API da NFC-e.');
                return;
              }
              if (response.message === undefined) {
                notificationUtils.warn('Falha na API da NFC-e.');
                return;
              }
              let message = response.message;
              if (message.success) {
                loadNFCeGrid(response.nfces);
                this.closeFilterModal();
              } else {
                notificationUtils.warn(message.details);
              }
            }).then(() => {this.setState({showLoadingSpinner: false });});
          }
        }
      }>
        <section className="panel">
          <div className="panel-body">
            <div className="form-group">
              <div>
                <label className="col-md-3 control-label">Data Emissão</label>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <DatePicker selectedDate={emissionDateStart} className="form-control" onChange={this.handleChangeEmissionDateStart} />
                        </div>
                        <span className="space col-md-1">Até</span>
                        <div className="col-md-4">
                          <DatePicker selectedDate={emissionDateEnd} className="form-control" onChange={this.handleChangeEmissionDateEnd} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <div>
                <label className="col-md-3 control-label">Status</label>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <select onChange={
                            (e) => this.handleFilters('varitusStatus', e.target.value)
                          } value={varitusStatus} className="form-control mb-md">
                            {
                              Object.keys(varitusStatusLabels).map(x => {
                                return <option value={x} key={v1()}>{varitusStatusLabels[x]}</option>
                              })
                            }
                          </select>
                        </div>
                        <span className="space col-md-1">Série</span>
                        <div className="col-md-4">
                          <input onChange={
                            (e) => this.handleFilters('serie', e.target.value)
                          } value={serie !== null ? serie : ''} type="number" className="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <div>
                <label className="col-md-3 control-label">Número</label>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <input onChange={
                            (e) => this.handleFilters('numberStart', e.target.value)
                          } value={numberStart !== null ? numberStart : ''} type="number" className="form-control" />
                        </div>
                        <span className="space col-md-1">Até</span>
                        <div className="col-md-4">
                          <input onChange={
                            (e) => this.handleFilters('numberEnd', e.target.value)
                          } value={numberEnd !== null ? numberEnd : ''} type="number" className="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className="col-md-3 control-label">Chave de acesso</label>
              <div className="col-md-5">
                <div className="row">
                  <div className="col-md-12">
                    <input onChange={
                      (e) => this.handleFilters('accessKey', e.target.value)
                    } value={accessKey !== null ? accessKey : ''} className="form-control" />
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className="col-md-3 control-label">Razão Social</label>
              <div className="col-md-5">
                <div className="row">
                  <div className="col-md-12">
                    <input onChange={
                      (e) => this.handleFilters('companyName', e.target.value)
                    } value={companyName !== null ? companyName : ''} className="form-control" />
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group pull-right">
              <div className="col-md-12">
                <button className="btn btn-primary">Pesquisar</button>
              </div>
            </div>
          </div>
        </section>
      </form>
    );
  }

  buildPendenciesModalContent(selectedRow) {
    let pendenciesDescription = '';
    if (selectedRow !== null) {
      if (selectedRow.data.pendenciesDescription !== undefined) {
        pendenciesDescription = selectedRow.data.pendenciesDescription;
      }
    }
    return (
      <p>
        {pendenciesDescription}
      </p>
    );
  }

  downloadDanfce = (selectedRow) => {
    let data = selectedRow.data;
    if (data !== undefined) {
      let senderCnpj = data.senderCnpj;
      let number = data.number;
      let serie = data.serie;
      let environmentType = data.environmentType;

      this.setState({ showLoadingSpinner: true }, () => {
        nfceApi.downloadDanfce(senderCnpj, serie, number, environmentType).then(response => {
          let message = response.message;
          if (message !== undefined) {
            if (message.success === false) {
              notificationUtils.warn(message.details);
            } else {
              notificationUtils.success(message.details);
              let danfeBase64 = response.danfeBase64;
              let accessKey = selectedRow.details.accessKey;
              let fu = new fileUtils();
              let mimeType = fu.getBase64MimeType(danfeBase64);
              fu.downloadFile(danfeBase64, accessKey + '_' + serie + '_' + number + fu.getExtension(mimeType));
            }
          }
        }).then(() => {
          this.setState({
            showLoadingSpinner: false
          });
        });
      });
    }
  }

  downloadXml = (selectedRow) => {
    let data = selectedRow.data;
    if (data !== undefined) {
      let senderCnpj = data.senderCnpj;
      let number = data.number;
      let serie = data.serie;
      let environmentType = data.environmentType;

      this.setState({ showLoadingSpinner: true }, () => {
        nfceApi.downloadXml(senderCnpj, serie, number, environmentType).then(response => {
          let message = response.message;
          if (message !== undefined) {
            if (message.success === false) {
              notificationUtils.warn(message.details);
            } else {
              notificationUtils.success(message.details);
              let zipNfeAsBase64 = response.zipNfeAsBase64;
              let accessKey = selectedRow.details.accessKey;
              let fu = new fileUtils();
              let mimeType = fu.getBase64MimeType(zipNfeAsBase64);
              fu.downloadFile(zipNfeAsBase64, accessKey + '_' + serie + '_' + number + fu.getExtension(mimeType));
            }
          }
        }).then(() => {
          this.setState({
            showLoadingSpinner: false
          });
        });
      });
    }
  }

  showPendencies = (rowData) => {
    this.setState({
      showPendenciesModal: true,
      selectedRow: rowData
    });
  }

  closePendenciesModal = () => {
    this.setState({
      showPendenciesModal: false,
      selectedRow: null
    });
  }

  closeFilterModal = () => {
    this.setState({
      showFiltersModal: false
    });
  }

  scrollDown = (endLimit) => {
    this.setState({
      scrollDownCurrentLimit: endLimit,
      showLoadingSpinner: true
    });
    this.loadGridWithFilters(endLimit);
  }

  loadGridWithFilters = (endLimit) => {
    const { loadNFCeGrid } = this.props;
    const {
      emissionDateStart,
      emissionDateEnd,
      varitusStatus,
      serie,
      numberStart,
      numberEnd,
      accessKey,
      companyName
    } = this.state.filters;

    nfceApi.findBy(
      emissionDateStart,
      emissionDateEnd,
      varitusStatus,
      serie,
      numberStart,
      numberEnd,
      accessKey,
      companyName,
      endLimit
    ).then(response => {
      let message = response.message;
      if (message !== undefined) {
        if (message.success) {
          loadNFCeGrid(response.nfces);
        }
      }
    }).then(() => {this.setState({showLoadingSpinner: false });});
  }

  buildGrid() {
    const { items } = this.props;
    let headerColumns = [
      'serie',
      'number',
      'emissionDate',
      'sender',
      'receiver',
      'varitusStatus',
      'sefazStatus',
    ];

    return (
      <Grid
        items={items}
        headerColumns={headerColumns}
        showDetails={true}
        translator={nfceGridTranslator}
        zebraStripedTable={
          {
            field: 'varitusStatus',
            cssTranslator: nfceStatusCss
          }
        }
        availableCallbacks={
          {
            'downloadDanfce': this.downloadDanfce,
            'downloadXml': this.downloadXml,
            'showPendencies': this.showPendencies
          }
        }
        onScrollDown={this.scrollDown}
      />
    );
  }

  createJsxComponent = () => {
    const { selectedRow } = this.state;
    let nfceGridJsx = (
      <div>
        <div style={{ marginBottom: '10px' }}>
          <button className="btn btn-primary" onClick={() => this.setState({ showFiltersModal: true })}>
            <span className="space">Pesquisar Notas</span>
            <i className="fas fa-search"></i>
          </button>
        </div>
        {this.buildGrid()}
        <Modal visible={this.state.showPendenciesModal} title="Pendências" content={this.buildPendenciesModalContent(selectedRow)} onCloseClick={this.closePendenciesModal} />
        <Modal visible={this.state.showFiltersModal} title="Filtro de Pesquisa" content={this.buildFilters()} onCloseClick={this.closeFilterModal} />
        <LoadingSpinner visible={this.state.showLoadingSpinner} />
      </div>
    );
    return nfceGridJsx;
  }

  render() {
    return this.createJsxComponent()
  }
}

NFCeManage.propTypes = {
  items: PropTypes.array,
  loadNFCeGrid: PropTypes.func
}

export default NFCeManage
