import React, { Component } from "react";
import PropTypes from "prop-types";
import { v1 } from "uuid";
import classNames from 'classnames';
import * as global from '../../constants/global';
import translatorUtils from "../../utils/translatorUtils";
import '../../assets/grid/grid.css';

class Grid extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      detailRowsActive: [],
      quantityRecords: global.MAX_RECORDS_PER_PAGE
    };
    this.translationInstance = (props.translator !== undefined)
      ? new translatorUtils(props.translator) : null;
  }

  toggleMoreButton(rowNumber) {
    let rowsActive = this.state.detailRowsActive.slice();
    if (rowsActive.includes(rowNumber)) {
      var idx = rowsActive.indexOf(rowNumber);
      if (idx > -1) {
        rowsActive.splice(idx, 1);
      }
    } else {
      rowsActive.push(rowNumber);
    }
    this.setState({
      detailRowsActive: rowsActive
    });
  }

  scrollHandler = (e) => {
    const { items, onScrollDown } = this.props;
    const NUMBER_OF_RECORDS_TO_MORE = 5;
    if (e.target.scrollTop + NUMBER_OF_RECORDS_TO_MORE >= e.target.scrollHeight - e.target.clientHeight) {

      const { quantityRecords } = this.state;
      let tmp = quantityRecords;
      let newQuantityRecords = tmp += global.MAX_RECORDS_PER_PAGE;
      this.setState({
        quantityRecords: newQuantityRecords
      });

      onScrollDown(newQuantityRecords);
    }
  };

  buildTableHeader() {
    const { items, headerColumns, showDetails, availableCallbacks } = this.props;
    var firstItem = items[0];
    let jsxTableHeader = [];
    if (showDetails)
      jsxTableHeader.push(<th key={v1()}>&nbsp;</th>);
    if (firstItem !== undefined) {
      let rowData = firstItem.data;
      for (var key in rowData) {
        if (headerColumns !== undefined) {
          if (headerColumns.includes(key)) {
            let title = (this.translationInstance !== null) ? this.translationInstance.getItem(key) : key;
            jsxTableHeader.push(<th key={v1()}>{title}</th>);
          }
        }
        else {
          let title = (this.translationInstance !== null) ? this.translationInstance.getItem(key) : key;
          jsxTableHeader.push(<th key={v1()}>{title}</th>);
        }
      }
    }

    if (availableCallbacks !== undefined) {
      jsxTableHeader.push(<th className="text-center" key={v1()}>Ações</th>);
    }

    return jsxTableHeader;
  }

  /**
   * Build details to row of table
   * @param {object} rowDetails Object data of current <tr>
   * @param {number} rowIndex The index of <tr>
   */
  buildDetails(rowDetails, rowIndex, numberOfColumns) {
    let jsxDetailRows = [];
    let isFirstKey = true;
    let firstTrClassName = '';
    const { detailRowsActive } = this.state;
    Object.keys(rowDetails).forEach(detailKey => {
      if (isFirstKey) {
        firstTrClassName = 'b-top-none';
      } else {
        firstTrClassName = null;
      }

      let title = (this.translationInstance !== null) ? this.translationInstance.getItem(detailKey) : detailKey;
      let text = (this.translationInstance !== null) ? this.translationInstance.getItem(rowDetails[detailKey]) : rowDetails[detailKey];

      if (text !== null) {
        let regex = /^\d{4}-\d{1,2}-\d{1,2}$/;
        if (text.toString().split(' ')[0].match(regex)) {
          text = new Date(text).toLocaleString(global.APP_LOCALE);
        }
      }

      jsxDetailRows.push(
        <tr key={v1()} className={firstTrClassName}>
          <td><label className="mb-none"><strong>{title}</strong></label></td>
          <td>{text}</td>
        </tr>
      );

      isFirstKey = false;
    });
    return (
      <tr key={v1()} className={classNames({
        'details': true,
        'details-show': detailRowsActive.includes(rowIndex)
      })}>
        <td className="details" colSpan={numberOfColumns}>
          <table className="table table-details mb-none">
            <tbody>
              {jsxDetailRows}
            </tbody>
          </table>
        </td>
      </tr>
    );
  }

  buildTableBody() {
    const { items, headerColumns, showDetails, availableCallbacks, zebraStripedTable } = this.props;
    const { detailRowsActive } = this.state;
    let jsxTableBody = [];
    var jsxTd = [];
    if (showDetails) {
      jsxTd.push(
        <td key={v1()} className="text-center" onClick={() => this.toggleMoreButton(0)}>
          <i className={
            classNames({
              'fas': true,
              'fa-plus-square': !detailRowsActive.includes(0),
              'fa-minus-square': detailRowsActive.includes(0),
              'text-primary': true,
              'h5': true,
              'm-none': true
            })
          } style={{ cursor: 'pointer' }}></i>
        </td>
      );
    }
    if (items.length > 0) {
      items.forEach((currentLine, index) => {
        let rowData = currentLine.data;
        let numberOfColumns = (Object.keys(rowData).length) + 2;
        Object.keys(rowData).forEach(key => {
          let text = (this.translationInstance !== null) ? this.translationInstance.getItem(rowData[key]) : rowData[key];
          if (text !== null) {
            let regex = /^\d{4}-\d{1,2}-\d{1,2}$/;
            if (text.toString().split(' ')[0].match(regex)) {
              text = new Date(text.toString() + ' 00:00:00').toLocaleString(global.APP_LOCALE, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
              });
            }
          }

          if (headerColumns !== undefined) {
            numberOfColumns = (headerColumns.length) + 2;
            if (headerColumns.includes(key)) {
              jsxTd.push(<td className="td-content" key={v1()}>{text}</td>);
            }
          } else {
            jsxTd.push(<td className="td-content" key={v1()}>{text}</td>);
          }
        });

        if (availableCallbacks !== undefined) {
          if (currentLine.actions !== undefined) {
            let actionButtons = [];
            Object.keys(currentLine.actions).forEach(key => {
              if (currentLine.actions[key] === true) {
                if (availableCallbacks[key] !== undefined) {
                  let jsxActionContent = (this.translationInstance !== null) ?
                    <i className={this.translationInstance.getItem(key)}
                      style={{ cursor: 'pointer' }}></i> : key;

                  actionButtons.push(
                    <a key={v1()} href="javascript:void(0);" onClick={() => availableCallbacks[key](currentLine)}>
                      {jsxActionContent}
                    </a>
                  );
                }
              }
            });

            jsxTd.push(
              <td key={v1()} className="text-center">
                <div className="box-actions">
                  {actionButtons}
                </div>
              </td>
            );
            actionButtons = [];
          }
        }

        // Zebra striped table
        var cssClass = '';
        if (zebraStripedTable !== undefined) {
          Object.keys(rowData).forEach(key => {
            if (zebraStripedTable.field === undefined)
              throw new Error('zebraStripedTable propery requires `field` to print rows!');
            if (key === zebraStripedTable.field) {
              if (zebraStripedTable.cssTranslator === undefined)
                throw new Error('zebraStripedTable propery requires `cssTranslator` enum!');
              cssClass = zebraStripedTable.cssTranslator[rowData[key]];
            }
          });
        }

        jsxTableBody.push(<tr className={`outside-row ${cssClass}`} key={v1()}>{jsxTd}</tr>);

        if (showDetails && currentLine.details !== undefined) {
          jsxTableBody.push(this.buildDetails(currentLine.details, index, numberOfColumns));
        }

        if (showDetails) {
          jsxTd = [(
            <td key={v1()} className="text-center" onClick={() => this.toggleMoreButton(index + 1)}>
              <i className={
                classNames({
                  'fas': true,
                  'fa-plus-square': !detailRowsActive.includes(index + 1),
                  'fa-minus-square': detailRowsActive.includes(index + 1),
                  'text-primary': true,
                  'h5': true,
                  'm-none': true
                })
              } style={{ cursor: 'pointer' }}></i>
            </td>
          )];
        } else {
          jsxTd = [];
        }
      });
    } else {
      jsxTableBody.push(
        <tr key={v1()}>
          <td colSpan={headerColumns.length+1} style={{textAlign: 'center', background: '#F6F6F6', verticalAlign: 'middle'}}>
            Não há registros
          </td>
        </tr>
      );      
    }
    return jsxTableBody;
  }

  render() {
    let wrapperStyles = {
      height: '550px',
      overflow: 'auto',
      display: 'block',
      width: '100%'
    }

    return (
      <div onScroll={this.scrollHandler} style={wrapperStyles}>
        <table className="table table-fixed table-bordered mb-none" id="datatable-details">
          <thead>
            <tr>
              {this.buildTableHeader()}
            </tr>
          </thead>
          <tbody>
            {this.buildTableBody()}
          </tbody>
        </table>
      </div>
    );
  }
}

Grid.propTypes = {
  items: PropTypes.array.isRequired,
  headerColumns: PropTypes.array,
  showDetails: PropTypes.bool.isRequired
};

export default Grid;