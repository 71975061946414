import { connect } from 'react-redux'
import Subscription from '../../components/pages/Subscription'
import { userSignOut, userSignInDispatcher } from '../../actions'

const mapStateToProps = (state, ownProps) => ({
  showSignOutButton: state.user.trialAccessPlanInfo.trialPeriodExpired
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSignOutClick: () => dispatch(userSignOut()),
  userSignInDispatcher: (user) => dispatch(userSignInDispatcher(user))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Subscription)
