export const serviceTypeLabels = {
  ALL: 'Todos',
  NORMAL: 'Normal',
  SUBCONTRACTING: 'Subcontratação',
  REDISPATCH: 'Redespacho',
  INTERMEDIATE_REDISPATCH: 'Redespacho intermediário', 
  // CT-e OS only
  MULTI_MODAL: 'Serviço Vinculado a Multimodal',
	PEOPLE_TRANSPORT: 'Transporte de Pessoas',
	SECURITIES_TRANSPORT: 'Transporte de Valores',
	BAGGAGE_EXCESS: 'Excesso de Bagagem'
}

export const serviceTypeValues = {
  ALL: 'ALL',
  NORMAL: 'NORMAL',
  SUBCONTRACTING: 'SUBCONTRACTING',
  REDISPATCH: 'REDISPATCH',
  INTERMEDIATE_REDISPATCH: 'INTERMEDIATE_REDISPATCH',
  // CT-e OS only
  MULTI_MODAL: 'MULTI_MODAL',
	PEOPLE_TRANSPORT: 'PEOPLE_TRANSPORT',
	SECURITIES_TRANSPORT: 'SECURITIES_TRANSPORT',
	BAGGAGE_EXCESS: 'BAGGAGE_EXCESS'
}