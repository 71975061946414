export default {
    'translation': [
        // Labels
        { 'key': 'serie', 'value': 'Série' },
        { 'key': 'number', 'value': 'Número' },
        { 'key': 'emissionDate', 'value': 'Data Emissão' },
        { 'key': 'sender', 'value': 'Emitente' },
        { 'key': 'receiver', 'value': 'Destinatário' },
        { 'key': 'varitusStatus', 'value': 'Situação Varitus' },
        { 'key': 'sefazStatus', 'value': 'Situação Sefaz' },
        { 'key': 'receiverCnpj', 'value': 'CNPJ Destinatário' },
        { 'key': 'sefazAuthorizationDate', 'value': 'Data de Autorização na Sefaz' },
        { 'key': 'emailWasSent', 'value': 'E-mail enviado' },
        { 'key': 'danfeWasPrinted', 'value': 'Danfe Impresso' },
        { 'key': 'validSignature', 'value': 'Assinatura Válida' },   
        { 'key': 'accessKey', 'value': 'Chave de Acesso' },
        { 'key': true, 'value': 'Sim' },
        { 'key': false, 'value': 'Não' },
        // Actions
        { 'key': 'printDanfe', 'value': 'fas fa-print' },
        { 'key': 'downloadXML', 'value': 'fas fa-download' },
        { 'key': 'makeMDe', 'value': 'fas fa-bullhorn' },
        { 'key': 'showPendencies', 'value': 'fas fa-exclamation-triangle' }
    ]
}