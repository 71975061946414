import React, { Component } from 'react'
import PropTypes from 'prop-types'
import fileUtils from "../../utils/fileUtils";
import notificationUtils from "../../utils/notificationUtils";
import certificateApi from "../../api/certificateApi";
import classNames from 'classnames';
import '../../assets/pages/certificateSettings.css';

import { oAuthHttpStatus, repositoryStatus } from "../../enums/varitusResponseCodes";

class CertificateSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      password:'',
      base64File: ''
    }
  }

  download = (e) => {
    e.preventDefault();
    let fu = new fileUtils();
    let mimeType = fu.getBase64MimeType(this.state.base64File);
    fu.downloadFile(this.state.base64File, 'certificado.' + fu.getExtension(mimeType));
  }

  toggleShowPassword = () => {
    let caPassword = document.getElementById('ca-password');
    let toggleShowPasswordIcon = document.getElementById('toggle-show-password-icon');
    
    if (caPassword.type === 'password') {
      caPassword.type = 'text';
      toggleShowPasswordIcon.className = 'fas fa-lock';
    } else {
      caPassword.type = 'password';
      toggleShowPasswordIcon.className = 'fas fa-unlock';
    }
  }

  save = (e) => {
    e.preventDefault();
    const {file, password} = this.state;

    if (file == null || password == null || password == '') {
      notificationUtils.warn('É necessário selecionar o certificado e a senha!');
      return;
    }

    let fu = new fileUtils();
    fu.getBase64(file).then(base64File => {
      certificateApi.upload(base64File, password).then(response => {
        switch (response.status) {
          case oAuthHttpStatus.INVALID_TOKEN:
          case oAuthHttpStatus.INVALID_CLIENT_ID:
          case oAuthHttpStatus.INVALID_CREDENTIALS: {
            notificationUtils.error('Não foi possível carregar certificado!\n\nAcesso não autorizado!');
            break;
          }
          case repositoryStatus.SUCCESS_SAVED: {
            notificationUtils.success('Certificado atualizado com sucesso!');
            const { loadCertificateDispatcher }  = this.props;
            loadCertificateDispatcher();
            break;
          }
          case repositoryStatus.ERROR_SAVED: {
            notificationUtils.error('Erro ao atualizar certificado!\n\nPor favor, tente novamente!');
            break;
          }
          default:
            notificationUtils.error('Erro inesperado!');
            break;
        }
      }).catch(error => {
        throw error;
      });
    });
  }

  onPasswordEnter = (passwordValue) => {
    this.setState({
      ...this.state.file,
      password: passwordValue,
    });
  }

  createJsxComponent = () => {
    return (
      <section className="panel">
        <header className="panel-heading">
          <p className="panel-subtitle">
            <strong>Certificado Digital:</strong>
          </p>
        </header>
        <div className="panel-body">
          <form>
            <div className="form-group">
              <label className="col-md-3 control-label">Upload certificado A1</label>
              <div className="col-md-6 upload-btn-wrapper">
                <button type="button" className="mb-xs mt-xs mr-xs btn btn-primary">
                  <i className="fa fa-cloud-upload"></i>&nbsp; Selecione o arquivo
                </button>
                <span className="file-info">Apenas arquivos (*.pfx)</span>
                <input name="ca-user" id="ca-user" type="file" onChange={(e) => {
                  let file = e.target.files[0];
                  if (file !== undefined) {
                    let fileName = file.name;
                    let fu = new fileUtils();
                    if (!fu.typeIsValid(fileName, ['.pfx'])) {
                      notificationUtils.warn('Formato do arquivo enviado é inválido!');
                    } else {
                      this.setState({
                        ...this.state.password,
                        file: file
                      });
                    }
                  } else {
                    notificationUtils.warn('Não foi possível carregar o arquivo!');
                  }
                }} />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="ca-password" className="col-md-3 control-label">Senha do certificado</label>
              <div className="col-md-6">
                <div className="input-group col-md-6">
                  <input className="form-control" type="password" id="ca-password" name="ca-password" autoComplete="ca-password" onChange={(e) => this.onPasswordEnter(e.target.value)} value={this.state.password} />
                  <div title="Clique para ver/ocultar a senha" className="input-group-addon toggle-show-password" onClick={this.toggleShowPassword}>
                    <i id="toggle-show-password-icon" className="fas fa-unlock" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>

            <div className={classNames({
              'form-group': true,
              'hide': this.state.base64File == null
            })}>
                <label className="col-md-3 control-label">&nbsp;</label>
                <div className="col-md-6">
                  <button className="btn btn-link" onClick={(e) => this.download(e)}>
                    Download do arquivo (.pfx)
                  </button>
                </div>
            </div>

            <div className="form-group">
              <div className="col-md-6">
                <button type="button" className="mb-xs mt-xs mr-xs btn btn-primary" onClick={(e) => this.save(e)}>
                  <i className="fa fa-save"></i>
                  &nbsp;
                  <span>Salvar</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    );
  }

  render() {
    return this.createJsxComponent()
  }
}

CertificateSettings.propTypes = {
  password: PropTypes.string,
  base64File: PropTypes.string,
  loadCertificateDispatcher: PropTypes.func
}

export default CertificateSettings
