
/**
 * CSS Classes
 */
export const mdfeStatusCss = {
  AUTHORIZED: 'label-default',
  CANCELED: 'label-success',
  DENIED: 'label-success',
  CLOSED: 'label-success',
  REJECTED: 'label-danger'
}

/**
 * Labels
 */
export const mdfeStatusLabels = {
  ALL: 'Todos',
  SIGNED: 'Assinado',
  AUTHORIZED: 'Autorizado',
  CANCELED: 'Cancelado',
  DENIED: 'Denegado',
  TYPED: 'Digitado',
  CLOSED: 'Encerrado',
  SENT: 'Transmitido',
  REJECTED: 'Rejeitado',
  VALIDATED: 'Validado'
}
