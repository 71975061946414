import React from "react";
import PropTypes from "prop-types";
import { v1 } from "uuid";
import menuConfig from '../config/menu';
import classNames from 'classnames';
import storageUtils from "../utils/storageUtils";
import '../assets/leftMenu.css';

class LeftMenu extends React.Component {
  constructor(props) {
    super(props)
    let expandedMenuItems = (storageUtils.getItem('expandedMenuItems') !== null) ?
      storageUtils.getItem('expandedMenuItems') : [];
    this.state = {
      collapsed: false,
      expandedMenuItems: expandedMenuItems
    }
  }

  buildMenu = (items) => {
    let menuJsx = [];
    if (items !== undefined) {
      items.forEach(item => {
        menuJsx.push(this.createMenuItem(item, [], item.option));
      });
    }
    return menuJsx;
  }

  changePage = (path) => {
    const { changePage } = this.props;
    changePage(path);
    let classList = document.documentElement.classList;
    if (Object.values(classList).includes('sidebar-left-opened')) {
      document.documentElement.classList.remove('sidebar-left-opened');
    }
  }

  toggleExpandedMenuItem(menuItem) {
    let expandedMenuItems = this.state.expandedMenuItems.slice();
    if (expandedMenuItems.includes(menuItem)) {
      var idx = expandedMenuItems.indexOf(menuItem);
      if (idx > -1) {
        expandedMenuItems.splice(idx, 1);
      }
    } else {
      expandedMenuItems.push(menuItem);
    }
    storageUtils.setItem('expandedMenuItems', expandedMenuItems);
    this.setState({
      expandedMenuItems: expandedMenuItems
    });
  }

  createMenuItem = (item, array = [], topLevelMenuItem = null) => {
    const { expandedMenuItems } = this.state;
    let config = menuConfig.menu[item.option] || null;
    if(config == null) return;
    if (item.show === true) {
      if (item.childrens) {
        let rsx = [];

        item.childrens.forEach(element => {
          rsx.push(this.createMenuItem(element, [...array, item.option]));
        });
        let x = (
          <li key={v1()} className={
            classNames({
              'nav-parent': true,
              'nav-expanded': expandedMenuItems.includes(topLevelMenuItem)
            })
          }>
            <a href="javascript:;" onClick={() => this.toggleExpandedMenuItem(topLevelMenuItem)}>
              <i className={config.icon} aria-hidden="true"></i>
              <span>{config.text}</span>
            </a>
            <ul className="nav nav-children"> {rsx} </ul>
          </li>
        );
        return x;
      } else {
        let x = (
          <li key={v1()}>
            <a href="javascript:void(0);" onClick={() => this.changePage(config.path)}>
              <i className={config.icon} aria-hidden="true"></i>
              <span> {config.text} </span>
            </a>
          </li>
        );
        return x;
      }
    }
  }

  toggleSidebar = () => {
    const { collapsed } = this.state;
    if (!collapsed)
      document.documentElement.classList.add('sidebar-left-collapsed')
    else
      document.documentElement.classList.remove('sidebar-left-collapsed')
    this.setState({
      collapsed: !collapsed
    });
  }

  buildOverlay = () => {
    const { showOverlay } = this.props;
    let overlayRsx = (<div></div>);
    if (showOverlay === true) {
      overlayRsx = (<div className="overlay"></div>);
    }
    return overlayRsx;
  }

  render() {
    const { items } = this.props;
    
    return (
      <div id="box-left-menu">
        {this.buildOverlay()}
        <aside id="sidebar-left" className="sidebar-left">
          <div className="sidebar-header">
            <div className="sidebar-title">MENU</div>
            <div
              className="sidebar-toggle hidden-xs"
              onClick={() => this.toggleSidebar()}
            >
              <i className="fas fa-bars" aria-label="Toggle sidebar" />
            </div>
          </div>
          <div className="nano">
            <div className="nano-content">
              <nav id="menu" className="nav-main" role="navigation">
                <ul className="nav nav-main" style={{ zIndex: '-1' }}>
                  {this.buildMenu(items)}
                </ul>
              </nav>
            </div>
          </div>
        </aside>
      </div>
    )
  }
}

LeftMenu.propTypes = {
  items: PropTypes.array.isRequired
};

export default LeftMenu;
