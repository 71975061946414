import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { v1 } from 'uuid';
import Grid from '../../containers/grid/Grid';
import Modal from '../../containers/modal/Modal';
import cteGridTranslator from '../../config/translators/cteGridTranslator';
import fileUtils from '../../utils/fileUtils';
import { cteStatusCss, cteStatusLabels } from '../../enums/cteStatus';
import cteApi from '../../api/cteApi';
import DatePicker from '../../containers/form/DatePicker';
import notificationUtils from '../../utils/notificationUtils';
import LoadingSpinner from '../../containers/LoadingSpinner';
import * as global from '../../constants/global';

import '../../assets/pages/nfeManage.css';

class CTeManage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      scrollDownCurrentLimit: global.MAX_RECORDS_PER_PAGE,
      showDisacordModal: false,
      showPendenciesModal: false,
      showFiltersModal: false,
      selectedRow: null,
      showLoadingSpinner: false,
      filters: {
        emissionDateStart: new Date(),
        emissionDateEnd: new Date(),
        varitusStatus: 'ALL',
        serie: null,
        numberStart: 1,
        numberEnd: 9999999,
        accessKey: null
      }
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if(nextProps.isLoading !== null){
      this.setState({
        showLoadingSpinner: nextProps.isLoading
      })
    }
  }

  handleChangeEmissionDateStart = (date) => {
    if (new Date(date).toString() !== "Invalid Date") {
      this.setState({
        filters: {
          ...this.state.filters,
          emissionDateStart: date
        }
      });
    }
  }

  handleChangeEmissionDateEnd = (date) => {
    if (new Date(date).toString() !== "Invalid Date") {
      this.setState({
        filters: {
          ...this.state.filters,
          emissionDateEnd: date
        }
      });
    }
  }

  handleFilters = (type, value) => {
    switch (type) {
      case 'varitusStatus': {
        this.setState({
          filters: {
            ...this.state.filters,
            'varitusStatus': value
          }
        })
        break;
      }
      case 'serie': {
        this.setState({
          filters: {
            ...this.state.filters,
            'serie': (value === '' ? null : value)
          }
        })
        break;
      }
      case 'numberStart': {
        this.setState({
          filters: {
            ...this.state.filters,
            'numberStart': value
          }
        })
        break;
      }
      case 'numberEnd': {
        this.setState({
          filters: {
            ...this.state.filters,
            'numberEnd': value
          }
        })
        break;
      }
      case 'accessKey': {
        this.setState({
          filters: {
            ...this.state.filters,
            'accessKey': (value === '' ? null : value)
          }
        })
        break;
      }
      default: {
        this.setState({
          filters: {
            ...this.state.filters
          }
        })
        break;
      }
    }
  }

  buildFilters() {
    const {
      emissionDateStart,
      emissionDateEnd,
      varitusStatus,
      serie,
      numberStart,
      numberEnd,
      accessKey
    } = this.state.filters;

    return (
      <form className="form-horizontal form-bordered" onSubmit={
        (e) => {
          e.preventDefault();
          const { loadCTeGrid } = this.props;
          const {
            emissionDateStart,
            emissionDateEnd,
            varitusStatus,
            serie,
            numberStart,
            numberEnd,
            accessKey
          } = this.state.filters;

          if (emissionDateStart !== undefined
            && emissionDateEnd !== undefined) {
              this.setState({showLoadingSpinner: true});
            cteApi.filterBy(
              emissionDateStart,
              emissionDateEnd,
              varitusStatus,
              serie,
              numberStart,
              numberEnd,
              accessKey
            ).then(response => {
              if (response === undefined) {
                notificationUtils.warn('Falha na API da CT-e.');
                return;
              }
              if (response.message === undefined) {
                notificationUtils.warn('Falha na API da CT-e.');
                return;
              }
              let message = response.message;
              if (message.success) {
                loadCTeGrid(response.ctes);
                this.closeFilterModal();
              } else {
                notificationUtils.warn(message.details);
              }
            }).then(() => {this.setState({showLoadingSpinner: false});});
          }
        }
      }>
        <section className="panel">
          <div className="panel-body">
            <div className="form-group">
              <div>
                <label className="col-md-3 control-label">Data Emissão</label>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <DatePicker selectedDate={emissionDateStart} className="form-control" onChange={this.handleChangeEmissionDateStart} />
                        </div>
                        <span className="space col-md-1">Até</span>
                        <div className="col-md-4">
                          <DatePicker selectedDate={emissionDateEnd} className="form-control" onChange={this.handleChangeEmissionDateEnd} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <div>
                <label className="col-md-3 control-label">Status</label>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <select onChange={
                            (e) => this.handleFilters('varitusStatus', e.target.value)
                          } value={varitusStatus} className="form-control mb-md">
                            {
                              Object.keys(cteStatusLabels).map(x => {
                                return <option value={x} key={v1()}>{cteStatusLabels[x]}</option>
                              })
                            }
                          </select>
                        </div>
                        <span className="space col-md-1">Série</span>
                        <div className="col-md-4">
                          <input onChange={
                            (e) => this.handleFilters('serie', e.target.value)
                          } value={serie !== null ? serie : ''} type="number" className="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <div>
                <label className="col-md-3 control-label">Número</label>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <input onChange={
                            (e) => this.handleFilters('numberStart', e.target.value)
                          } value={numberStart !== null ? numberStart : ''} type="number" className="form-control" />
                        </div>
                        <span className="space col-md-1">Até</span>
                        <div className="col-md-4">
                          <input onChange={
                            (e) => this.handleFilters('numberEnd', e.target.value)
                          } value={numberEnd !== null ? numberEnd : ''} type="number" className="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className="col-md-3 control-label">Chave de acesso</label>
              <div className="col-md-5">
                <div className="row">
                  <div className="col-md-12">
                    <input onChange={
                      (e) => this.handleFilters('accessKey', e.target.value)
                    } value={accessKey !== null ? accessKey : ''} className="form-control" />
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group pull-right">
              <div className="col-md-12">
                <button className="btn btn-primary">Pesquisar</button>
              </div>
            </div>
          </div>
        </section>
      </form>
    );
  }

  buildDisaccordModalContent(selectedRow) {
    let reasonText = null;
    return (
      <form onSubmit={
        (e) => {
          e.preventDefault();
          if (selectedRow !== undefined) {
            let reason = reasonText.value;
            let data = selectedRow.data;
            let issuerCnpj = data.issuerCnpj;
            let number = data.number;
            let serie = data.serie;
            let emissionDate = data.emissionDate;

            this.setState({ showLoadingSpinner: true }, () => {
              cteApi.disaccord(issuerCnpj, serie, number, emissionDate, reason).then(response => {
                let message = response.message;
                if (message !== undefined) {
                  if (message.success === true) {
                    notificationUtils.success(message.details);
                    this.closeDisaccordModal();
                  } else {
                    notificationUtils.warn(message.details);
                  }
                }
              }).then(() => {
                this.setState({
                  showLoadingSpinner: false
                });
              });
            })
          }
        }
      }>
        <div className='form-group'>
          <label>Motivo</label>
          <textarea className="form-control mb-md" ref={object => reasonText = object}></textarea>
        </div>
        <div className="form-group">
          <button className="btn btn-primary">Confirmar</button>
        </div>
      </form >
    );
  }

  buildPendenciesModalContent(selectedRow) {
    let pendenciesDescription = '';
    if (selectedRow !== null) {
      if (selectedRow.data.pendenciesDescription !== undefined) {
        pendenciesDescription = selectedRow.data.pendenciesDescription;
      }
    }
    return (
      <p>
        {pendenciesDescription}
      </p>
    );
  }

  downloadDacte = (selectedRow) => {
    let data = selectedRow.data;
    if (data !== undefined) {
      let issuerCnpj = data.issuerCnpj;
      let serie = data.serie;
      let number = data.number;
      let emissionDate = data.emissionDate;
      this.setState({ showLoadingSpinner: true }, () => {
        cteApi.downloadDacte(issuerCnpj, serie, number, emissionDate).then(response => {
          let message = response.message;
          if (message !== undefined) {
            if (message.success === false) {
              notificationUtils.warn(message.details);
            } else {
              notificationUtils.success(message.details);
              let dacteAsBase64 = response.dacteAsBase64;
              let accessKey = selectedRow.details.accessKey;
              let fu = new fileUtils();
              let mimeType = fu.getBase64MimeType(dacteAsBase64);
              fu.downloadFile(dacteAsBase64, accessKey + '_' + serie + '_' + number + fu.getExtension(mimeType));
            }
          }
        }).then(() => {
          this.setState({
            showLoadingSpinner: false
          });
        });
      });
    }
  }

  downloadXml = (selectedRow) => {
    let data = selectedRow.data;
    if (data !== undefined) {
      let issuerCnpj = data.issuerCnpj;
      let serie = data.serie;
      let number = data.number;
      let emissionDate = data.emissionDate;
      this.setState({ showLoadingSpinner: true }, () => {
        cteApi.downloadXml(issuerCnpj, serie, number, emissionDate).then(response => {
          let message = response.message;
          if (message !== undefined) {
            if (message.success === false) {
              notificationUtils.warn(message.details);
            } else {
              notificationUtils.success(message.details);
              let zipMdfeAsBase64 = response.zipCteAsBase64;
              let accessKey = selectedRow.details.accessKey;
              let fu = new fileUtils();
              let mimeType = fu.getBase64MimeType(zipMdfeAsBase64);
              fu.downloadFile(zipMdfeAsBase64, accessKey + '_' + serie + '_' + number + fu.getExtension(mimeType));
            }
          }
        }).then(() => {
          this.setState({
            showLoadingSpinner: false
          });
        });
      });
    }
  }

  makeDisaccord = (rowData) => {
    this.setState({
      showDisacordModal: true,
      selectedRow: rowData
    });
  }

  closeDisaccordModal = () => {
    this.setState({
      showDisacordModal: false,
      selectedRow: null
    });
  }

  showPendencies = (rowData) => {
    this.setState({
      showPendenciesModal: true,
      selectedRow: rowData
    });
  }

  closePendenciesModal = () => {
    this.setState({
      showPendenciesModal: false,
      selectedRow: null
    });
  }

  closeFilterModal = () => {
    this.setState({
      showFiltersModal: false
    });
  }

  scrollDown = (endLimit) => {
    this.setState({
      scrollDownCurrentLimit: endLimit,
      showLoadingSpinner: true
    });
    this.loadGridWithFilters(endLimit);
  }

  loadGridWithFilters = (endLimit) => {
    const { loadCTeGrid } = this.props;
    const {
      emissionDateStart,
      emissionDateEnd,
      varitusStatus,
      serie,
      numberStart,
      numberEnd,
      accessKey
    } = this.state.filters;

    cteApi.filterBy(
      emissionDateStart,
      emissionDateEnd,
      varitusStatus,
      serie,
      numberStart,
      numberEnd,
      accessKey,
      endLimit
    ).then(response => {
      let message = response.message;
      if (message !== undefined) {
        if (message.success) {
          loadCTeGrid(response.ctes);
        }
      }
    }).then(() => {this.setState({showLoadingSpinner: false });});
  }

  buildGrid() {
    const { items } = this.props;
    let headerColumns = [
      'serie',
      'number',
      'emissionDate',
      'sender',
      'shipmentState',
      'landingState',
      'varitusStatus'
    ];

    return (
      <Grid
        items={items}
        headerColumns={headerColumns}
        showDetails={true}
        translator={cteGridTranslator}
        zebraStripedTable={
          {
            field: 'varitusStatus',
            cssTranslator: cteStatusCss
          }
        }
        availableCallbacks={
          {
            'downloadDacte': this.downloadDacte,
            'downloadXml': this.downloadXml,
            'makeDisaccord': this.makeDisaccord,
            'showPendencies': this.showPendencies
          }
        }
        onScrollDown={this.scrollDown}
      />
    );
  }

  createJsxComponent = () => {
    const { selectedRow } = this.state;
    let cteGridJsx = (
      <div>
        <div style={{ marginBottom: '10px' }}>
          <button className="btn btn-primary" onClick={() => this.setState({ showFiltersModal: true })}>
            <span className="space">Pesquisar Notas</span>
            <i className="fas fa-search"></i>
          </button>
        </div>
        {this.buildGrid()}
        <Modal visible={this.state.showDisacordModal} title="Desacordo" content={this.buildDisaccordModalContent(selectedRow)} onCloseClick={this.closeDisaccordModal} />
        <Modal visible={this.state.showPendenciesModal} title="Pendências" content={this.buildPendenciesModalContent(selectedRow)} onCloseClick={this.closePendenciesModal} />
        <Modal visible={this.state.showFiltersModal} title="Filtro de Pesquisa" content={this.buildFilters()} onCloseClick={this.closeFilterModal} />
        <LoadingSpinner visible={this.state.showLoadingSpinner} />
      </div>
    );
    return cteGridJsx;
  }

  render() {
    return this.createJsxComponent()
  }
}

CTeManage.propTypes = {
  items: PropTypes.array,
  loadCTeGrid: PropTypes.func
}

export default CTeManage
