export default {
    'translation': [
        // Labels
        { 'key': 'serie', 'value': 'Série' },
        { 'key': 'number', 'value': 'Número' },
        { 'key': 'emissionDate', 'value': 'Data Emissão' },
        { 'key': 'statusVaritus', 'value': 'Situação Varitus' },
        { 'key': 'receiverName', 'value': 'Nome do recebedor' },
        { 'key': 'cancelAccessKey', 'value': 'Chave de acesso de cancelamento' },
        { 'key': 'cancelDate', 'value': 'Data de cancelamento' },
        { 'key': 'wasEmailSent', 'value': 'E-mail enviado' },
        { 'key': 'accessKey', 'value': 'Chave de Acesso' },
  
        { 'key': true, 'value': 'Sim' },
        { 'key': false, 'value': 'Não' },

        { 'key': 'ALL', 'value': 'TODOS' },
        { 'key': 'AUTHORIZED', 'value': 'AUTORIZADO' },  
        { 'key': 'CANCELED', 'value': 'CANCELADO' },
        
        // Actions
        { 'key': 'downloadDacte', 'value': 'fas fa-print' },
        { 'key': 'downloadXml', 'value': 'fas fa-download' },
        { 'key': 'showPendencies', 'value': 'fas fa-exclamation-triangle' },
        { 'key': 'makeDisaccord', 'value': 'fas fa-bullhorn' }
    ]
  }