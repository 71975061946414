import * as types from '../constants/actionTypes';
import initialState from './initialState';
import merge from 'lodash/merge'

const nfse = (state = initialState.nfse, action) => {

  switch (action.type) {
    case types.NFSE_LIST_IS_LOADED: {
      let nfse = {
        items: action.items,
        isLoading: false
        // items: handleFormats(action.items)
      };
      return merge(state.nfse, nfse);
    }
    case types.NFSE_LIST_IS_LOADING: {
      let nfse = {
        items: state.items,
        isLoading: true
      };
      return merge(state.nfse, nfse);
    }
    default:
      return state
  }
}

// const handleFormats = (itemList) => {

//   if(itemList == null || itemList.length == 0){
//     return itemList
//   }

//   return itemList.map(item => {

//     const date = item.data.emissionDate;
//     const formattedDate = date.substring(0, date.indexOf("T"));
  
//     return {
//       ...item,
//       data: {
//         ...item.data,
//         emissionDate: formattedDate
//       }
//     }

//   })

// }

export default nfse