import React, { Component } from 'react'
import PropTypes from "prop-types";
import classNames from 'classnames';
import '../../assets/form/toggleSwitch.css';

class ToggleSwitch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: this.props.startChecked
    }
  }
  toggleSwitch = () => {
    const { onChange } = this.props;
    const { checked } = this.state;
    this.setState({
      checked: !checked
    });
    onChange(!checked);
  }
  createJsxComponent = () => {
    const { visible, label } = this.props;
    const { checked } = this.state;
    let rsx = (<div></div>);
    if (visible) {
      rsx = (
        <div className="switch switch-sm switch-primary" onClick={() => this.toggleSwitch()}>
          <div className="switch-box">
            <div className={
              classNames({
                'ios-switch': true,
                'on': checked,
                'off': !checked
              })
            }>
              <div className="on-background background-fill"></div>
              <div className="state-background background-fill"></div>
              <div className="handle"></div>
            </div>
          </div>
          <span className="title">{label}</span>
        </div>
      );
    }
    return (
      rsx
    );
  }
  render() {
    return this.createJsxComponent()
  }
}

ToggleSwitch.propTypes = {
  visible: PropTypes.bool,
  text: PropTypes.string,
  startChecked: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default ToggleSwitch;


