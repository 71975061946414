import * as routes from '../constants/routes';
export default {
  menu: {
    'home': {
      text: 'Início',
      path: routes.HOME_PATH,
      icon: 'fas fa-home'
    },
    'nfe/manage': {
      text: 'Gerenciar NF-e',
      path: routes.NFE_MANAGE_PATH,
      icon: 'fas fa-tasks'
    },
    'nfce/manage': {
      text: 'Gerenciar NFC-e',
      path: routes.NFCE_MANAGE_PATH,
      icon: 'fas fa-tasks'
    },
    'nfse/manage': {
      text: 'Gerenciar NFS-e',
      path: routes.NFSE_MANAGE_PATH,
      icon: 'fas fa-tasks'
    },
    'mdfe/manage': {
      text: 'Gerenciar MDF-e',
      path: routes.MDFE_MANAGE_PATH,
      icon: 'fas fa-shipping-fast'
    },
    'cte/manage': {
      text: 'Gerenciar CT-e',
      path: routes.CTE_MANAGE_PATH,
      icon: 'fas fa-shipping-fast'
    },
    'cte-os/manage': {
      text: 'Gerenciar CT-e OS',
      path: routes.CTE_OS_MANAGE_PATH,
      icon: 'fas fa-shipping-fast'
    },
    'cfe/manage': {
      text: 'Configurar CFe-Sat',
      path: routes.CFE_MANAGE_PATH,
      icon: 'fas fa-lock'
    },
    'subscription': {
      text: 'Mensalidade',
      path: routes.SUBSCRIPTION_PATH,
      icon: 'fas fa-dollar-sign'
    },
    'invoice': {
      text: 'Faturas',
      path: routes.INVOICE_PATH,
      icon: 'fas fa-file-invoice-dollar'
    },
    'certificate-settings': {
      text: 'Configurar Certificado',
      path: routes.CERTIFICATE_SETTINGS_PATH,
      icon: 'fas fa-lock'
    }
  }
}

