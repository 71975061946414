import { connect } from 'react-redux'
import InvoiceManage from '../../components/pages/InvoiceManage'
import { userSignOut, userSignInDispatcher, loadCustomerInvoices } from '../../actions'

const mapStateToProps = (state, ownProps) => ({
  items: state.invoice.items,
  indebted: state.invoice.indebted,
  showSignOutButton: state.invoice.indebted
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadCustomerInvoices: (limit) => dispatch(loadCustomerInvoices(limit)),
  onSignOutClick: () => dispatch(userSignOut()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceManage)
