
/**
 * Labels
 */
export const invoiceStatusLabels = {
  draft: 'Em processamento',
  paid: 'Paga',
  pending: 'Pendente',
  canceled: 'Cancelada'
}

/**
 * CSS Classes
 */
export const invoiceStatusCss = {
  draft: 'label-info',
  paid: 'label-success',
  pending: 'label-warning',
  canceled: 'label-default'
}

/**
 * Icons
 */
export const invoiceStatusIcons = {
  draft: 'fas fa-cogs',
  paid: 'fas fa-check',
  pending: 'fas fa-exclamation-circle',
  canceled: 'fas fa-times'
}