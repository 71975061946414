import * as apiEndpoints from '../constants/apiEndpoints';
import httpUtils from '../utils/httpUtils';
import * as httpMethods from '../constants/httpMethods';
import axios from 'axios';
import * as contentTypes from '../constants/contentTypes';
/**
 * Utils API
 */
class UtilsApi {
  static getAddressByZipCode(zipCode) {
    return httpUtils.consume(
      {},
      apiEndpoints.UTILS_GET_ADDRESS_ENDPOINT.split('{zipCode}').join(zipCode),
      httpMethods.REQUEST_METHOD_GET,
      false
    );
  }
}
export default UtilsApi;

