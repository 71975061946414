import * as types from '../constants/actionTypes';
import initialState from './initialState';
import merge from 'lodash/merge'

const cteos = (state = initialState.cteos, action) => {
  switch (action.type) {
    case types.CTE_OS_LIST_IS_LOADED: {
      let cteos = {
        items: action.items,
        isLoading: false
      };
      return merge(state.cteos, cteos);
    }
    case types.CTE_OS_LIST_IS_LOADING: {
      let cteos = {
        items: state.items,
        isLoading: true
      };
      return merge(state.cteos, cteos);
    }
    default:
      return state
  }
}
export default cteos