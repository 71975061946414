import * as apiEndpoints from '../constants/apiEndpoints';
import * as httpMethods from '../constants/httpMethods';
import * as contentTypes from '../constants/contentTypes';
import storageUtils from "../utils/storageUtils";
import axios from 'axios';

/**
 * Certificate API
 */
class CertificateApi {  
    static upload(base64File, password, emailCert) {
      const CERTIFICATE_UPDATE_ENDPOINT = apiEndpoints.CERTIFICATE_UPDATE_ENDPOINT;
      let user = storageUtils.getItem('user');
      let currentCompany = storageUtils.getItem('currentCompany');
      let token = '';
      let tokenNotafaz = currentCompany.varitusApiToken;
      if (user !== null) {
        token = user.accessToken;
      }

      let uploadCertificateRequest = {
        "token": token,
        "base64File": base64File,
        "password": password,
        "company": currentCompany,
        "emailCert": emailCert,
        "tokenNotafaz":tokenNotafaz
      };
      
      return axios({
        method: httpMethods.REQUEST_METHOD_PUT,
        url: CERTIFICATE_UPDATE_ENDPOINT,
        data: uploadCertificateRequest,
        headers: { 'Content-Type': contentTypes.CONTENT_TYPE_APPLICATION_JSON }
      }).then((response) => {
        return response.data;
      }).catch((error) => {
        return error.response.data;
      });
    }

    static getByCompany() {
      let user = storageUtils.getItem('user');
      let currentCompany = storageUtils.getItem('currentCompany');
      const CERTIFICATE_GET_BY_COMPANY_ENDPOINT = apiEndpoints.CERTIFICATE_GET_BY_COMPANY_ENDPOINT.split('{companyId}').join(currentCompany.id);
      let token = '';
      
      if (user !== null) {
        token = user.accessToken;
      }
      var formData = new FormData();
      formData.append('token', token);


      return axios({
        method: httpMethods.REQUEST_METHOD_POST,
        url: CERTIFICATE_GET_BY_COMPANY_ENDPOINT,
        data: formData
      }).then((response) => {
        return response.data;
      }).catch((error) => {
        return error.response.data;
      });
    }
}
export default CertificateApi;  