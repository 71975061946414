export const emissionTypeLabels = {
    ALL: 'Todos',
    NORMAL: 'Normal',
    CONTINGENCY: 'Contingência',
}

export const emissionTypeValues = {
    ALL: 'ALL',
    NORMAL: 'NORMAL',
    CONTINGENCY: 'CONTINGENCY'
}