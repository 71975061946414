import initialState from '../reducers/initialState';
/**
 * Storage utils
 */
class StorageUtils {

  static setItem(key, obj) {
    localStorage.setItem(key, JSON.stringify(obj));
  }

  static getItem(key) {
    let obj = localStorage.getItem(key);
    if (obj !== undefined) {
      return JSON.parse(obj);
    }
    return null;
  }

  static removeItem(key) {
    localStorage.removeItem(key);
  }

  static clearAll() {
    localStorage.clear();
  }

  static getCurrentCompany() {
    let currentCompany = this.getItem('currentCompany');
    return currentCompany;
  }

  static getVaritusApiToken() {
    return this.getCurrentCompany().varitusApiToken;
  }

  static getCurrentUser() {
    let currentUser = this.getItem('user');
    return currentUser;
  }

  static getAccessToken() {
    return this.getCurrentUser().accessToken;
  }

  static getEnvironmentType() {
    let shared = this.getStateFromLocalStorage('shared');
    return shared.varitusApiEnvType;
  }

  /**
   * Manage global state of application
   * between localStorage and redux state
   * 
   * @param {string} key
   * @return {object} The state object
   */
  static getStateFromLocalStorage = (key) => {
    switch (key) {
      case 'user':
        return this.getItem(key) || initialState.user
      case 'shared':
        return this.getItem(key) || initialState.shared
      default:
        return this.getItem(key) || {}
    }
  }
}
export default StorageUtils;
