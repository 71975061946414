/**
 * Labels
 */
export const eventTypeFilterLabels = {
    TODOS: 'Todos',
    CIENCIA_OPERACAO: 'Ciência da Operação',
    CONFIRMACAO_OPERACAO: 'Confirmação da Operação',
    DESCONHECIMENTO_OPERACAO: 'Desconhecimento da Operação',
    OPERACAO_NAO_REALIZADA: 'Operação não Realizada'
}
