export const nfseTypeLabels = {
    ISSUED: 'Emitidos',
    RECEIVED: 'Recebidos',
    ALL: 'Ambos'
}

export const nfseTypeValues = {
    ISSUED: 'ISSUED',
    RECEIVED: 'RECEIVED',
    ALL: 'ALL'
}