import React, { Component } from "react";
import PropTypes from "prop-types";
import subscriptionApi from '../../api/subscriptionApi';
import utilsApi from '../../api/utilsApi';
import * as httpStatus from '../../constants/httpStatus';
import { v1 } from "uuid";
import ToggleSwitch from '../../containers/form/ToggleSwitch';
import '../../assets/pages/subscription.css';
import classNames from 'classnames';
import notificationUtils from '../../utils/notificationUtils';
import storageUtils from '../../utils/storageUtils';
import LoadingSpinner from '../../containers/LoadingSpinner';
import * as global from '../../constants/global';
import ConfirmModal from '../../containers/modal/ConfirmModal'
import Modal from '../../containers/modal/Modal'


class Subscription extends Component {
  constructor(props) {
    super(props);
    this.zipCodeInput = React.createRef();
    this.streetInput = React.createRef();
    this.numberInput = React.createRef();
    this.districtInput = React.createRef();
    this.complementInput = React.createRef();
    this.cityInput = React.createRef();;
    this.stateInput = React.createRef();
    this.phonePrefixInput = React.createRef();
    this.phoneNumberInput = React.createRef();
    // Credit Card
    this.creditCardNumberInput = React.createRef();
    this.creditCardCvvInput = React.createRef();
    this.creditCardNameInput = React.createRef();
    this.creditCardExpirationInput = React.createRef();

    this.state = {
      subscriptionDetails: null,
      monthPlans: [],
      yearPlans: [],
      showYearPlans: false,
      activeTab: 'access-plan-box',
      activeBillingBox: null,
      accessPlanDescription: 'Carregando...',
      showLoadingSpinner: false,
      selectedAccessPlan: null,
      showConfirmModal: false,
      showAlertModal: false,
      showCreditCardInfo: false,
      companyData: {
        address: {
          zipCode: null
        }
      }
    };
    this.loadAccessPlanDescription();
    this.loadSubscriptionDetails();
    this.loadAccessPlans();
  }

  loadSubscriptionDetails() {
    return subscriptionApi
      .getUserSubscriptionDetails()
      .then(subscriptionDetails => {
        if (subscriptionDetails !== undefined) {
          if (subscriptionDetails.status === httpStatus.HTTP_STATUS_OK) {
            if (subscriptionDetails.data !== undefined) {
              let subscriptionData = subscriptionDetails.data;
              let company = subscriptionData.company;
              if (company !== undefined) {
                if (this !== null && this !== undefined) {
                  if (this.streetInput !== null)
                    this.streetInput.current.value = company.street;
                  if (this.districtInput !== null)
                    this.districtInput.current.value = company.district;
                  if (this.cityInput !== null)
                    this.cityInput.current.value = (company.city !== null) ? company.city.name : null;
                  if (this.stateInput !== null)
                    this.stateInput.current.value = (company.city !== null) ? company.city.state.acronym : null;
                  if (this.zipCodeInput !== null)
                    this.zipCodeInput.current.value = company.zipCode;
                  if (this.numberInput !== null)
                    this.numberInput.current.value = company.addressNumber;
                  if (this.complementInput !== null)
                    this.complementInput.current.value = company.complement;
                  if (this.phonePrefixInput !== null)
                    this.phonePrefixInput.current.value = (company.phone !== null) ? company.phone.substr(0, 2) : null;
                  if (this.phoneNumberInput !== null)
                    this.phoneNumberInput.current.value = (company.phone !== null) ? company.phone.substr(2, company.phone.length) : null;
                }
              }

              if (subscriptionData.creditCard !== null) {
                console.log(subscriptionData);
                let creditCardData = subscriptionData.creditCard.data;
                if (creditCardData !== null && subscriptionData.paymentMethod == 'credit_card') {
                  this.setState({
                    showCreditCardInfo: true
                  });
                }
              }

              this.setState({
                subscriptionDetails: subscriptionData,
                activeBillingBox: (subscriptionData.paymentMethod !== null) ? (subscriptionData.paymentMethod == 'bank_slip' ? 'manual-billing' : 'automatic-billing') : null
              });
            }
          }
        }
      });
  }

  loadAccessPlans() {
    subscriptionApi
      .getAccessPlans()
      .then(accessPlans => {
        if (accessPlans !== undefined) {
          if (accessPlans.status === httpStatus.HTTP_STATUS_OK) {
            if (accessPlans.data !== undefined) {
              let accessPlansData = accessPlans.data;
              if (accessPlansData.items !== undefined) {
                // year plans
                let privateYearPlan = accessPlansData.items.filter(accessPlan => {
                  return accessPlan.identifier.split('_').includes('YEAR')
                    && accessPlan.identifier.split('_').includes('PRIVATE')
                })[0];

                let yearPlans = accessPlansData.items.filter(accessPlan => {
                  return accessPlan.identifier.split('_').includes('YEAR')
                    && !accessPlan.identifier.split('_').includes('PRIVATE')
                }).sort((a, b) => {
                  if (a.prices[0].value > b.prices[0].value) {
                    return 1;
                  } else if (a.prices[0].value < b.prices[0].value) {
                    return -1;
                  }
                  return 0;
                });
                yearPlans.push(privateYearPlan);

                // month plans
                let privateMonthPlan = accessPlansData.items.filter(accessPlan => {
                  return !accessPlan.identifier.split('_').includes('YEAR')
                    && accessPlan.identifier.split('_').includes('PRIVATE')
                })[0];

                let monthPlans = accessPlansData.items.filter(accessPlan => {
                  return !accessPlan.identifier.split('_').includes('YEAR')
                    && !accessPlan.identifier.split('_').includes('TRIAL')
                    && !accessPlan.identifier.split('_').includes('PRIVATE')
                }).sort((a, b) => {
                  if (a.prices[0].value > b.prices[0].value) {
                    return 1;
                  } else if (a.prices[0].value < b.prices[0].value) {
                    return -1;
                  }
                  return 0;
                });
                monthPlans.push(privateMonthPlan);

                this.setState({
                  monthPlans: monthPlans,
                  yearPlans: yearPlans
                })
              }
            }
          }
        }
      });
  }

  numberToReal = (number) => {
    if (number == 0) {
      return 'Consulte';
    }
    var number = number.toFixed(2).split('.');
    number[0] = "R$ " + number[0].split(/(?=(?:...)*$)/).join('.');
    return number.join(',');
  }

  changeShowYearPlans = (show) => {
    this.setState({
      showYearPlans: show
    });
  }

  changeTab = (tabName) => {
    this.setState({
      activeTab: tabName
    });
  }

  updateTrialPlanInfo = () => {
    let trialAccessPlanInfo = {
      trialPeriodExpired: false,
      showExpiringTrialPeriodAlert: false,
      daysLeft: 0,
      hasTrialPlan: false
    };
    let user = storageUtils.getItem('user');
    user.trialAccessPlanInfo = trialAccessPlanInfo;
    const { userSignInDispatcher } = this.props;
    // Login again
    userSignInDispatcher(user);
  }

  calculateExpiresAt = () => {
    let expiresAt = new Date();
    let user = storageUtils.getItem('user');
    let daysLeft = 0;
    if (user.trialAccessPlanInfo.daysLeft !== null) {
      daysLeft = user.trialAccessPlanInfo.daysLeft;
    }
    if (daysLeft > 0) {
      let time = new Date();
      expiresAt.setDate(time.getDate() + daysLeft);
    }
    return expiresAt.toISOString().split('T')[0];
  }

  subscribe = (accessPlan) => {
    this.loadSubscriptionDetails().then(response => {
      const { subscriptionDetails } = this.state;

      let identifier = accessPlan.identifier;
      if (identifier.split('_').includes('PRIVATE')) {
        // Consulte-nos
        var win = window.open('https://www.xmlespiao.com.br/', '_blank');
        win.focus();
        return;
      }

      if (subscriptionDetails.iuguSubscription !== null) {
        if (subscriptionDetails.iuguSubscription.accessPlanCode === accessPlan.identifier) {
          this.setState({
            showAlertModal: true
          })
        } else {
          this.setState({
            showConfirmModal: true,
            selectedAccessPlan: accessPlan
          })
        }
      } else {
        this.setState({
          showConfirmModal: true,
          selectedAccessPlan: accessPlan
        })
      }
    });
  }

  executeSubscription = (planIdentifier, paymentMethod, iuguClientId, iuguSubscriptionId, showMessage = true) => {
    //console.log('executeSubscription');
    let request = {
      planIdentifier: planIdentifier,
      payableWith: paymentMethod,
      expiresAt: this.calculateExpiresAt()
    };
    if (iuguClientId !== null) {
      request.customerId = iuguClientId;
    }
    let subscriptionId = null;
    if (iuguSubscriptionId !== null) {
      subscriptionId = iuguSubscriptionId;
    }
    let updateSubscriptionPromise = null;
    this.setState({ showLoadingSpinner: true }, () => {
      updateSubscriptionPromise = this.updateSubscription(request, subscriptionId).then(response => {
        if (response !== null) {
          if (showMessage === true) {
            notificationUtils.success('Sua assinatura foi atualizada com sucesso!');
          }
          this.updateTrialPlanInfo();
          this.loadAccessPlanDescription();
          if (paymentMethod === 'credit_card') {
            this.setState({
              showLoadingSpinner: false,
              activeTab: 'access-plan-box',
              showCreditCardInfo: true
            });
          } else {
            this.setState({
              showLoadingSpinner: false,
              activeTab: 'access-plan-box'
            });
          }

          //this.loadSubscriptionDetails();
          return response;
        }
      });
    });
    return updateSubscriptionPromise;
  }

  searchAddress = () => {
    const { companyData } = this.state;
    if (companyData.address !== null) {
      if (companyData.address.zipCode !== null) {
        let address = companyData.address;
        utilsApi.getAddressByZipCode(address.zipCode)
          .then(address => {
            if (address !== undefined) {
              if (address.erro !== undefined) {
                notificationUtils.warn('CEP não encontrado!');
                this.streetInput.current.value = '';
                this.districtInput.current.value = '';
                this.cityInput.current.value = '';
                this.stateInput.current.value = '';
              } else {
                this.streetInput.current.value = address.logradouro;
                this.districtInput.current.value = address.bairro;
                this.cityInput.current.value = address.localidade;
                this.stateInput.current.value = address.uf;
              }
            }
          }).catch(error => {
            console.log(error);
          });
      }
    }
  }

  updateSubscription = (subscriptionRequest, subscriptionId) => {
    return subscriptionApi.subscribeOrUpdateSubscription(subscriptionRequest, subscriptionId)
      .then(subscriptionResponse => {
        if (subscriptionResponse !== undefined) {
          if (subscriptionResponse.status === httpStatus.HTTP_STATUS_OK) {
            if (subscriptionResponse.data !== undefined) {
              let responseData = subscriptionResponse.data;
              if (responseData !== null) {
                return responseData;
              } else {
                return null;
              }
            }
          }
        }
      }).catch(error => {
        console.log(error);
      });
  }

  updateCustomer = (customerId) => {
    let subscriptionCustomerRequest = {
      name: storageUtils.getCurrentCompany().companyName,
      email: storageUtils.getCurrentUser().username,
      phone: this.phoneNumberInput.current.value,
      phonePrefix: this.phonePrefixInput.current.value,
      cpfCnpj: storageUtils.getCurrentCompany().cnpj,
      zipCode: this.zipCodeInput.current.value,
      street: this.streetInput.current.value,
      number: this.numberInput.current.value,
      city: this.cityInput.current.value,
      state: this.stateInput.current.value,
      district: this.districtInput.current.value,
      complement: this.complementInput.current.value
    }

    return subscriptionApi.createOrUpdateCustomer(subscriptionCustomerRequest, customerId)
      .then(subscriptionCustomerResponseDTO => {
        if (subscriptionCustomerResponseDTO !== undefined) {
          if (subscriptionCustomerResponseDTO.status === httpStatus.HTTP_STATUS_OK) {
            if (subscriptionCustomerResponseDTO.data !== undefined) {
              let responseData = subscriptionCustomerResponseDTO.data;
              let customerId = responseData.id;
              if (customerId !== null) {
                return customerId;
              }
            }
          }
        }
      }).catch(error => {
        console.log(error);
      });
  }

  loadAccessPlanDescription = () => {
    subscriptionApi
      .getUserSubscriptionDetails()
      .then(subscriptionDetails => {
        if (subscriptionDetails !== undefined) {
          if (subscriptionDetails.status === httpStatus.HTTP_STATUS_OK) {
            if (subscriptionDetails.data !== undefined) {
              let subscriptionAccessPlanCode = null;
              if (subscriptionDetails.data.iuguSubscription !== null) {
                subscriptionAccessPlanCode = subscriptionDetails.data.iuguSubscription.accessPlanCode;
              }
              if (subscriptionAccessPlanCode === null) {
                subscriptionAccessPlanCode = 'XML_SPY_TRIAL';
              }
              let accessPlanCodes = [];
              accessPlanCodes['XML_SPY_LIGHT_YEAR'] = 'LIGHT Com pagamento anual';
              accessPlanCodes['XML_SPY_PREMIUM_YEAR'] = 'PREMIUM Com pagamento anual';
              accessPlanCodes['XML_SPY_PRIVATE_YEAR'] = 'PRIVATE Com pagamento anual';
              accessPlanCodes['XML_SPY_CONTROL_YEAR'] = 'CONTROL Com pagamento anual';
              accessPlanCodes['XML_SPY_TRIAL'] = 'Em periodo de testes';
              accessPlanCodes['XML_SPY_PRIVATE'] = 'PRIVATE Com pagamento mensal';
              accessPlanCodes['XML_SPY_PREMIUM'] = 'PREMIUM Com pagamento mensal';
              accessPlanCodes['XML_SPY_CONTROL'] = 'CONTROL Com pagamento mensal';
              accessPlanCodes['XML_SPY_LIGHT'] = 'LIGHT Com pagamento mensal';
              this.setState({
                accessPlanDescription: accessPlanCodes[subscriptionAccessPlanCode]
              });
            }
          }
        }
      });
  }

  chooseYesConfirmModal = () => {
    this.setState({
      showConfirmModal: false
    });
    this.loadSubscriptionDetails().then(response => {
      const { subscriptionDetails, selectedAccessPlan } = this.state;
      let identifier = selectedAccessPlan.identifier;
      if (subscriptionDetails.iuguClientId === null
        || subscriptionDetails.paymentMethod === null) {
        this.setState({
          activeTab: 'billing-box',
          //selectedAccessPlan: selectedAccessPlan
        });
        notificationUtils.warn('Você não possui forma de pagamento!\nPor favor cadastre uma das opções.');
      } else {
        let iuguSubscriptionId = null;
        let iuguClientId = null;
        if (subscriptionDetails.iuguSubscription === null) {
          iuguClientId = subscriptionDetails.iuguClientId;
        } else {
          iuguSubscriptionId = subscriptionDetails.iuguSubscription.iuguSubscriptionId;
        }
        this.executeSubscription(identifier, subscriptionDetails.paymentMethod, iuguClientId, iuguSubscriptionId);
      }
    });
  }

  closeConfirmModal = () => {
    this.setState({
      showConfirmModal: false
    });
  }

  closeAlertModal = () => {
    this.setState({
      showAlertModal: false
    })
  }

  buildConfirmModalContent = () => {
    const { subscriptionDetails, selectedAccessPlan } = this.state;
    let contentDescription = "";
    if (subscriptionDetails !== null && selectedAccessPlan !== null) {
      let period = (selectedAccessPlan.interval === 12) ? '(Plano anual)' : '(Plano mensal)';
      if (subscriptionDetails.iuguClientId === null
        || subscriptionDetails.iuguSubscription === null) {
        contentDescription = (
          <p>
            Deseja assinar o plano {selectedAccessPlan.name} {period} - Valor: {this.numberToReal(selectedAccessPlan.prices[0].valueCents / 100)}?
          </p>
        )
      } else {
        contentDescription = (
          <p>
            Você já possui um plano vigente<br />
            Caso queira continuar o plano atual será Cancelado e você iniciará o novo plano {selectedAccessPlan.name} {period}&nbsp;
            - Valor: {this.numberToReal(selectedAccessPlan.prices[0].valueCents / 100)}<br />
            Deseja continuar?
          </p>
        );
      }
    }
    return (<div>{contentDescription}</div>);
  }

  buildConfirmModalTitle = () => {
    return "Confirmação";
  }

  createJsxComponent = () => {
    const {
      yearPlans,
      monthPlans,
      showYearPlans,
      activeTab,
      activeBillingBox,
      subscriptionDetails,
      accessPlanDescription,
      selectedAccessPlan,
      showConfirmModal,
      showAlertModal,
      showCreditCardInfo
    } = this.state;

    const { showSignOutButton, onSignOutClick } = this.props;
    let accessPlansJSX = (
      <div className="col-md-12 center">
        Carregando planos...
      </div>
    );
    let explainYearPrice = (<span></span>);
    let accessPlans = (showYearPlans) ? yearPlans : monthPlans;
    if (accessPlans.length > 0) {
      accessPlansJSX = accessPlans.map(accessPlan => {
        let periodDescription = 'mensal';
        let price = accessPlan.prices[0].value;
        if (showYearPlans) {
          periodDescription = 'anual';
          explainYearPrice = (
            <strong>
              <span style={{ display: `block`, marginTop: `10px` }}>
                Total: {this.numberToReal(price)}
              </span>
            </strong>
          );
          price = price / 12;
        }
        return (
          <div className="accessplan-block col-sm-3 col-xs-3" key={v1()}>
            <div className="accessplan-inner">
              <div className="plan-description pricing-column ">
                <h3 className="accessplan-name mg-bottom">{accessPlan.name}</h3>
                <div className="accessplan-price mg-bottom">
                  <span className="value mg-bottom">{this.numberToReal(price)}</span>
                  <span className="interval">por mês no pagamento {periodDescription}</span>
                  {explainYearPrice}
                </div>
                <div className="action">
                  <a href="javascript:void(0);"
                    onClick={() => this.subscribe(accessPlan)}
                    className="btn btn-primary">Assinar</a>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }

    return (
      <div className="col-md-12 col-lg-12">
        <LoadingSpinner visible={this.state.showLoadingSpinner} />
        <div className="tabs">
          <ul className="nav nav-tabs tabs-primary">
            <li className={
              classNames({
                'active': (activeTab === 'access-plan-box')
              })
            }>
              <a href="javascript:void(0);" onClick={() => this.changeTab('access-plan-box')}>Plano de Acesso</a>
            </li>
            <li className={
              classNames({
                'active': (activeTab === 'billing-box')
              })
            }>
              <a href="javascript:void(0);" onClick={() => this.changeTab('billing-box')}>Dados de Cobrança</a>
            </li>
          </ul>
          <div className="tab-content">
            <div className={
              classNames({
                'row': true,
                'pull-right': true,
                'hide': !showSignOutButton
              })
            }>
              <div className="col-md-12">
                <button onClick={() => onSignOutClick()} type="button" className="mb-xs mt-xs mr-xs btn btn-info">
                  <i className="fa fa-power-off"></i>
                  &nbsp;
              <span>Sair do sistema</span>
                </button>
              </div>
            </div>
            <div id="access-plan-box" className={
              classNames({
                'tab-pane': true,
                'active': (activeTab === 'access-plan-box')
              })
            }>
              <div className="row">
                <Modal visible={showAlertModal}
                  title="Aviso"
                  content={<p>Este ja é seu plano atual!</p>}
                  onCloseClick={() => this.closeAlertModal()} />
                <ConfirmModal visible={showConfirmModal}
                  title={this.buildConfirmModalTitle()}
                  content={this.buildConfirmModalContent()}
                  onCloseClick={() => this.closeConfirmModal()}
                  onChooseNotClick={() => this.closeConfirmModal()}
                  onChooseYesClick={() => this.chooseYesConfirmModal()} />
                <div className="col-md-12">
                  <span>Seu plano de acesso atual é &nbsp;
                  <strong>{accessPlanDescription}</strong>
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 center">
                  <ToggleSwitch label="Planos de pagamento anual"
                    onChange={(checked) => this.changeShowYearPlans(checked)}
                    startChecked={showYearPlans} />
                </div>
              </div>
              <div className="row plans-row box-padding">
                {accessPlansJSX}
              </div>
            </div>

            <div id="billing-box" className={
              classNames({
                'tab-pane': true,
                'active': (activeTab === 'billing-box')
              })
            }>
              <div className="row">
                <div className="col-md-12">
                  <div>
                    <span style={{ display: 'block', paddingBottom: '10px', fontWeight: 'bold' }}>Tipo de Cobrança</span>
                    <div className="radio-custom radio-primary">
                      <input type="radio" id="manual-billing" name="billingType" value="manual-billing" onChange={(e) => this.setState({
                        activeBillingBox: e.target.value
                      })}
                        checked={activeBillingBox === 'manual-billing'} />
                      <label htmlFor="manual-billing">Cobrança manual</label>
                    </div>
                    <div className="radio-custom radio-primary">
                      <input type="radio" id="automatic-billing" name="billingType" value="automatic-billing" onChange={(e) => this.setState({
                        activeBillingBox: e.target.value
                      })}
                        checked={activeBillingBox === 'automatic-billing'} />
                      <label htmlFor="automatic-billing">Cobrança automática</label>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div id="box-bank-slip" className={
                    classNames({
                      'box-padding': true,
                      'active': activeBillingBox == null ? ((subscriptionDetails !== null) && subscriptionDetails.paymentMethod === 'bank_slip') : (activeBillingBox === 'manual-billing')
                    })
                  }>
                    <form onSubmit={
                      (e) => {
                        e.preventDefault();
                        if (this.zipCodeInput.current.value.length == 0) {
                          notificationUtils.warn('Informe o CEP do endereço');
                          return;
                        }
                        if (this.numberInput.current.value.length == 0) {
                          notificationUtils.warn('Informe o número do endereço');
                          return;
                        }
                        if (this.phonePrefixInput.current.value.length == 0) {
                          notificationUtils.warn('Informe o DDD');
                          return;
                        }
                        if (this.phoneNumberInput.current.value.length == 0) {
                          notificationUtils.warn('Informe o número do telefone');
                          return;
                        }
                        this.setState({ showLoadingSpinner: true }, () => {
                          this.updateCustomer(subscriptionDetails.iuguClientId).then(customerId => {
                            subscriptionApi.updateCustomerPaymentMethodBankSlip(customerId)
                              .then(response => {
                                let updatePaymentMethodResponseMessage = response.message;
                                // Create or update subscription 
                                let iuguClientId = null;
                                let iuguSubscriptionId = null;
                                let accessPlanIdentifier = null;
                                let showSubscriptionMessage = true;
                                if (selectedAccessPlan !== null || subscriptionDetails.iuguSubscription !== null) {
                                  if (subscriptionDetails.iuguSubscription === null) {
                                    iuguClientId = customerId;
                                    accessPlanIdentifier = selectedAccessPlan.identifier;
                                  } else {
                                    accessPlanIdentifier = subscriptionDetails.accessPlanCode;
                                    iuguSubscriptionId = subscriptionDetails.iuguSubscription.iuguSubscriptionId;
                                    showSubscriptionMessage = false;
                                  }
                                  this.executeSubscription(accessPlanIdentifier, 'bank_slip', iuguClientId, iuguSubscriptionId, showSubscriptionMessage).then(response => {
                                    notificationUtils.success(updatePaymentMethodResponseMessage);
                                  });
                                } else {
                                  notificationUtils.success(updatePaymentMethodResponseMessage);
                                  this.setState({
                                    showLoadingSpinner: false,
                                    activeTab: 'access-plan-box'
                                  });
                                  this.loadSubscriptionDetails();
                                }
                              }).catch(error => {
                                console.log(error);
                              });
                          });
                        });
                      }
                    }>
                      <div className="form-group">
                        <label className="col-md-1 control-label" htmlFor="zipCode">Endereço</label>
                        <div className="col-md-3">
                          <div className="input-group">
                            <span className="input-group-addon">CEP <strong>*</strong></span>
                            <input ref={this.zipCodeInput} id="zipCode" name="zipCode" className="form-control input-md" type="number" onKeyUp={
                              (e) => {
                                this.setState({
                                  ...this.state,
                                  companyData: {
                                    address: {
                                      zipCode: e.target.value
                                    }
                                  }
                                });
                                if (e.target.value.length > 8) {
                                  e.target.value = e.target.value.slice(0, 8);
                                }
                              }
                            } />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <button type="button" className="btn btn-primary" onClick={() => this.searchAddress()}>Pesquisar</button>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-md-1 control-label" htmlFor="prependedtext"></label>
                        <div className="col-md-5">
                          <div className="input-group">
                            <span className="input-group-addon">Endereço</span>
                            <input ref={this.streetInput} id="street" name="street" className="form-control" readOnly="readonly" type="text" />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="input-group">
                            <span className="input-group-addon">Nº <strong>*</strong></span>
                            <input ref={this.numberInput} id="number" name="number" className="form-control" type="text" />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="input-group">
                            <span className="input-group-addon">Bairro</span>
                            <input ref={this.districtInput} id="district" name="district" className="form-control" readOnly="readonly" type="text" />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-md-1 control-label" htmlFor="prependedtext"></label>
                        <div className="col-md-5">
                          <div className="input-group">
                            <span className="input-group-addon">Complemento</span>
                            <input ref={this.complementInput} id="complement" name="complement" className="form-control" type="text" />
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="input-group">
                            <span className="input-group-addon">UF</span>
                            <input ref={this.stateInput} id="state" name="state" className="form-control" placeholder="" required="" readOnly="readonly" type="text" />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="input-group">
                            <span className="input-group-addon">Cidade</span>
                            <input ref={this.cityInput} id="city" name="city" className="form-control" readOnly="readonly" type="text" />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="col-md-1 control-label" htmlFor="zipCode">Telefone</label>
                        <div className="col-md-3">
                          <div className="input-group">
                            <span className="input-group-addon">DDD <strong>*</strong></span>
                            <input ref={this.phonePrefixInput} id="zipCode" name="zipCode" className="form-control input-md" type="number" />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="input-group">
                            <span className="input-group-addon">Número <strong>*</strong></span>
                            <input ref={this.phoneNumberInput} id="phone-number" name="phone-number" className="form-control" type="number" />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-md-1 control-label" htmlFor="prependedtext"></label>
                        <div className="col-md-11">
                          <button className="btn btn-primary">Confirmar</button>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div id="box-credit-card" className={
                    classNames({
                      'box-padding': true,
                      'active': activeBillingBox == null ? ((subscriptionDetails !== null) && subscriptionDetails.paymentMethod === 'credit_card') : (activeBillingBox === 'automatic-billing')
                    })
                  }>
                    <div id="credit-card-info" className={
                      classNames({
                        'hide': !showCreditCardInfo
                      })
                    }>
                      {this.buildCreditCardInfo()}
                    </div>
                    <form id="payment-form" className={
                      classNames({
                        'hide': showCreditCardInfo
                      })
                    } onSubmit={
                      (e) => {
                        e.preventDefault();
                        window.Iugu.setup();
                        window.Iugu.setAccountID(global.IUGU_ID);
                        window.Iugu.setTestMode(global.IUGU_TEST_MODE);

                        // Credit card object
                        let creditCardNumber = this.creditCardNumberInput.current.value;
                        let creditCardCvv = this.creditCardCvvInput.current.value;
                        let creditCardName = this.creditCardNameInput.current.value;
                        let creditCardExpiration = this.creditCardExpirationInput.current.value;

                        if (creditCardNumber.length == 0 || creditCardCvv.length == 0
                          || creditCardName.length == 0 || creditCardExpiration.length == 0) {
                          notificationUtils.warn('Todos os dados do cartão devem ser preenchidos!');
                          return;
                        }

                        if (creditCardName.split(' ').length == 1) {
                          notificationUtils.warn('Informe o nome do titular como consta no cartão!');
                          return;
                        }

                        let creditCardExpirationParts = creditCardExpiration.split('/');
                        let creditCardNameParts = creditCardName.split(' ');

                        var cc = window.Iugu.CreditCard(creditCardNumber.replace(/\s/g, ''),
                          creditCardExpirationParts[0],
                          creditCardExpirationParts[1],
                          creditCardNameParts[0],
                          creditCardNameParts[creditCardNameParts.length - 1],
                          creditCardCvv);

                        let obj = this;
                        let updateCustomerCallback = this.updateCustomer;

                        var tokenResponseHandler = function (response) {
                          if (response.errors) {
                            if (response.errors.number === 'is_invalid') {
                              notificationUtils.warn('Número do cartão inválido!');
                              return false;
                            }
                            if (response.errors.verification_value === 'is_invalid') {
                              notificationUtils.warn('Código de verificação do cartão inválido!');
                              return false;
                            }
                            if (response.errors.expiration === 'is_invalid') {
                              notificationUtils.warn('Data de expiração inválida!');
                              return false;
                            }
                          } else {
                            let creditCardToken = response.id;
                            obj.setState({ showLoadingSpinner: true }, () => {
                              updateCustomerCallback(subscriptionDetails.iuguClientId).then(customerId => {
                                let subscriptionCustomerPaymentMethodRequest = {
                                  description: 'Cartão de crédito do cliente ' + customerId,
                                  token: creditCardToken
                                };
                                subscriptionApi.updateCustomerPaymentMethodCreditCard(subscriptionCustomerPaymentMethodRequest, customerId)
                                  .then(response => {
                                    let updatePaymentMethodResponseMessage = response.message;
                                    // Create or update subscription 
                                    let iuguClientId = null;
                                    let iuguSubscriptionId = null;
                                    let accessPlanIdentifier = null;
                                    let showSubscriptionMessage = true;
                                    if (selectedAccessPlan !== null || subscriptionDetails.iuguSubscription !== null) {
                                      if (subscriptionDetails.iuguSubscription === null) {
                                        iuguClientId = customerId;
                                        accessPlanIdentifier = selectedAccessPlan.identifier;
                                      } else {
                                        accessPlanIdentifier = subscriptionDetails.accessPlanCode;
                                        iuguSubscriptionId = subscriptionDetails.iuguSubscription.iuguSubscriptionId;
                                        showSubscriptionMessage = false;
                                      }
                                      obj.executeSubscription(accessPlanIdentifier, 'credit_card', iuguClientId, iuguSubscriptionId, showSubscriptionMessage).then(response => {
                                        notificationUtils.success(updatePaymentMethodResponseMessage);
                                      });
                                      obj.loadSubscriptionDetails();
                                    } else {
                                      notificationUtils.success(updatePaymentMethodResponseMessage);
                                      obj.setState({
                                        showLoadingSpinner: false,
                                        activeTab: 'access-plan-box',
                                        showCreditCardInfo: true
                                      });
                                      obj.loadSubscriptionDetails();
                                    }
                                  }).catch(error => {
                                    console.log(error);
                                  });
                              });
                            });
                          }
                        };
                        window.Iugu.createPaymentToken(cc, tokenResponseHandler);
                      }
                    }>
                      <div className="usable-creditcard-form">
                        <div className="wrapper">
                          <div className="input-group nmb_a">
                            <div className="icon ccic-brand"></div>
                            <input ref={this.creditCardNumberInput} autoComplete="off" className="credit_card_number" data-iugu="number" placeholder="Número do Cartão"
                              type="text" defaultValue="" />
                          </div>
                          <div className="input-group nmb_b">
                            <div className="icon ccic-cvv"></div>
                            <input ref={this.creditCardCvvInput} autoComplete="off" className="credit_card_cvv" data-iugu="verification_value" placeholder="CVV" type="text"
                              defaultValue="" />
                          </div>
                          <div className="input-group nmb_c">
                            <div className="icon ccic-name"></div>
                            <input ref={this.creditCardNameInput} className="credit_card_name" data-iugu="full_name" placeholder="Titular do Cartão" type="text" />
                          </div>
                          <div className="input-group nmb_d">
                            <div className="icon ccic-exp"></div>
                            <input ref={this.creditCardExpirationInput} autoComplete="off" className="credit_card_expiration" data-iugu="expiration" placeholder="MM/AA"
                              type="text" defaultValue="" />
                          </div>
                        </div>
                        <div className="footer">
                          <img
                            src="http://storage.pupui.com.br/9CA0F40E971643D1B7C8DE46BBC18396/assets/cc-icons.e8f4c6b4db3cc0869fa93ad535acbfe7.png"
                            alt="Visa, Master, Diners. Amex" border="0" />
                          <a className="iugu-btn" href="http://iugu.com" tabIndex="-1"><img
                            src="http://storage.pupui.com.br/9CA0F40E971643D1B7C8DE46BBC18396/assets/payments-by-iugu.1df7caaf6958f1b5774579fa807b5e7f.png"
                            alt="Pagamentos por Iugu" border="0" /></a>
                        </div>
                      </div>
                      <div>
                        <button className="btn btn-primary" type="submit">Confirmar</button>
                      </div>
                    </form>
                  </div>

                  <span style={{ display: 'block', marginTop: '10px' }}>
                    Se a opção <strong>Cobrança automática:</strong> estiver marcada, será debitado automaticamente em seu cartão de crédito.
                    <br />
                    Na <strong>Cobrança manual</strong>, você executa o pagamento todo mês, podendo escolher pagar por boleto ou cartão de crédito
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  buildCreditCardInfo = () => {
    const { subscriptionDetails } = this.state;
    let rsxContent = "Carregando informações do cartão...";
    if (subscriptionDetails !== null) {
      if (subscriptionDetails.creditCard !== null) {
        let creditCardData = subscriptionDetails.creditCard.data;
        rsxContent = (
          <div>
            <i style={{ fontSize: '17px', verticalAlign: 'middle' }} className="far fa-credit-card"></i>
            <span style={{ marginLeft: '10px', verticalAlign: 'middle' }}>{creditCardData.brand} - {creditCardData.displayNumber}</span>
            <div className="form-group" style={{ marginTop: '10px' }}>
              <button className="btn btn-primary" onClick={
                () => this.setState({ showCreditCardInfo: false })
              }>
                <i className="fas fa-edit"></i>
                &nbsp;
                Substituir cartão
              </button>
            </div>
          </div>
        )
      }
    }
    return rsxContent;
  }

  render() {
    return this.createJsxComponent();
  }
}

Subscription.propTypes = {

};

export default Subscription;
