export const emailSentStatusLabels = {
    SENT: 'Enviado',
    NOT_SENT: 'Não enviado',
    BOTH: 'Ambos',
}

export const emailSentStatusValues = {
    SENT: 'SENT',
    NOT_SENT: 'NOT_SENT',
    BOTH: 'BOTH'
}