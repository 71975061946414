import React from "react";
import PropTypes from "prop-types";
import SwitchEnvironment from "../containers/SwitchEnvironment";
import SwitchCompany from "../containers/SwitchCompany";
import maskUtils from "../utils/maskUtils";
import '../assets/topBar.css';
import VaritusApps from "../containers/VaritusApps";

class TopBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profileBoxClasses: ["userbox"],
      notificationBoxClasses: [""],
      sidebarLeftOpened: false
    };
  }

  toggleSidebarLeft = () => {
    const { sidebarLeftOpened } = this.state;
    if (!sidebarLeftOpened)
      document.documentElement.classList.add('sidebar-left-opened')
    else
      document.documentElement.classList.remove('sidebar-left-opened')
    this.setState({
      ...this.state,
      sidebarLeftOpened: !sidebarLeftOpened
    });
  }

  toggleBoxClassClick = (classes, type) => {
    let objClasses = {};
    switch (type) {
      case "notification":
        objClasses = {
          notificationBoxClasses: classes
        };
        break;

      case "profile":
        objClasses = {
          profileBoxClasses: classes
        };
        break;
      default:
        objClasses = {};
        break;
    }
    classes.includes("open") ? classes.pop("open") : classes.push("open");
    this.setState({
      objClasses
    });
  }

  buildOverlay = () => {
    const { showOverlay } = this.props;
    let overlayRsx = (<div></div>);
    if (showOverlay === true) {
      overlayRsx = (<div className="overlay"></div>);
    }
    return overlayRsx;
  }

  render() {
    const { logoImage, cnpj, companyName, userName, userSignOut } = this.props;
    const { profileBoxClasses, notificationBoxClasses } = this.state;
    return (
      <div id="box-header">
        {this.buildOverlay()}
        <header className="header">
          <div className="logo-container">
            <a href="javascript:void(0)" className="logo">
              <img
                src={logoImage}
                height="35"
                alt="XML Espião"
                title="XML Espião"
              />
            </a>
            <div
              onClick={() => this.toggleSidebarLeft()}
              className="visible-xs toggle-sidebar-left"
              data-target="html"
            >
              <i className="fa fa-bars" aria-label="Toggle sidebar" />
            </div>
          </div>
          <div className="header-right">
            {/* <form action="/" className="search nav-form">
            <div className="input-group input-search">
              <input
                type="text"
                className="form-control"
                name="q"
                id="q"
                placeholder="Busca..."
              />
              <span className="input-group-btn">
                <button className="btn btn-default" type="submit">
                  <i className="fa fa-search" />
                </button>
              </span>
            </div>
          </form> */}
            {/* <span className="separator" /> */}
            {/* <ul className="notifications">
            <li>
              <a href="javascript:void(0);" className="dropdown-toggle notification-icon" data-toggle="dropdown">
                <i className="fa fa-tasks"></i>
                <span className="badge">3</span>
              </a>
              <div className="dropdown-menu notification-menu large">
                <div className="notification-title">
                  <span className="pull-right label label-default">3</span>
                  Tasks
                    </div>
                <div className="content">
                  <ul>
                    <li>
                      <p className="clearfix mb-xs">
                        <span className="message pull-left">Generating Sales Report</span>
                        <span className="message pull-right text-dark">60%</span>
                      </p>
                      <div className="progress progress-xs light">
                        <div className="progress-bar" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{ width: '60%' }}></div>
                      </div>
                    </li>
                    <li>
                      <p className="clearfix mb-xs">
                        <span className="message pull-left">Importing Contacts</span>
                        <span className="message pull-right text-dark">98%</span>
                      </p>
                      <div className="progress progress-xs light">
                        <div className="progress-bar" role="progressbar" aria-valuenow="98" aria-valuemin="0" aria-valuemax="100" style={{ width: '98%' }}></div>
                      </div>
                    </li>
                    <li>
                      <p className="clearfix mb-xs">
                        <span className="message pull-left">Uploading something big</span>
                        <span className="message pull-right text-dark">33%</span>
                      </p>
                      <div className="progress progress-xs light mb-xs">
                        <div className="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100" style={{ width: '33%' }}></div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <a href="javascript:void(0);" className="dropdown-toggle notification-icon" data-toggle="dropdown">
                <i className="fa fa-envelope"></i>
                <span className="badge">4</span>
              </a>
              <div className="dropdown-menu notification-menu">
                <div className="notification-title">
                  <span className="pull-right label label-default">230</span>
                  Messages
                    </div>
                <div className="content">
                  <ul>
                    <li>
                      <a href="#" className="clearfix">
                        <figure className="image">
                          <img src="assets/images/!sample-user.jpg" alt="Joseph Doe Junior" className="img-circle" />
                        </figure>
                        <span className="title">Joseph Doe</span>
                        <span className="message">Lorem ipsum dolor sit.</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="clearfix">
                        <figure className="image">
                          <img src="assets/images/!sample-user.jpg" alt="Joseph Junior" className="img-circle" />
                        </figure>
                        <span className="title">Joseph Junior</span>
                        <span className="message truncate">Truncated message. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sit amet lacinia orci. Proin vestibulum eget risus non luctus. Nunc cursus lacinia lacinia. Nulla molestie malesuada est ac tincidunt. Quisque eget convallis diam, nec venenatis risus. Vestibulum blandit faucibus est et malesuada. Sed interdum cursus dui nec venenatis. Pellentesque non nisi lobortis, rutrum eros ut, convallis nisi. Sed tellus turpis, dignissim sit amet tristique quis, pretium id est. Sed aliquam diam diam, sit amet faucibus tellus ultricies eu. Aliquam lacinia nibh a metus bibendum, eu commodo eros commodo. Sed commodo molestie elit, a molestie lacus porttitor id. Donec facilisis varius sapien, ac fringilla velit porttitor et. Nam tincidunt gravida dui, sed pharetra odio pharetra nec. Duis consectetur venenatis pharetra. Vestibulum egestas nisi quis elementum elementum.</span>
                      </a>
                    </li>
                  </ul>
                  <hr />
  
                  <div className="text-right">
                    <a href="#" className="view-more">View All</a>
                  </div>
                </div>
              </div>
            </li>
            <li
              className={notificationBoxClasses.join(" ")}
              onClick={() =>
                this.toggleBoxClassClick(notificationBoxClasses, "notification")
              }
            >
              <a
                href="javascript:void(0);"
                className="dropdown-toggle notification-icon"
                data-toggle="dropdown"
              >
                <i className="fa fa-bell" />
                <span className="badge">3</span>
              </a>
              <div className="dropdown-menu notification-menu">
                <div className="notification-title">
                  <span className="pull-right label label-default">3</span>
                  alertas
                </div>
                <div className="content">
                  <ul>
                    <li>
                      <a href="#" className="clearfix">
                        <div className="image">
                          <i className="fa fa-thumbs-down bg-danger" />
                        </div>
                        <span className="title">Server is Down!</span>
                        <span className="message">Just now</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="clearfix">
                        <div className="image">
                          <i className="fa fa-lock bg-warning" />
                        </div>
                        <span className="title">User Locked</span>
                        <span className="message">15 minutes ago</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="clearfix">
                        <div className="image">
                          <i className="fa fa-signal bg-success" />
                        </div>
                        <span className="title">Connection Restaured</span>
                        <span className="message">10/10/2014</span>
                      </a>
                    </li>
                  </ul>
                  <hr />
                  <div className="text-right">
                    <a href="#" className="view-more">
                      View All
                    </a>
                  </div>
                </div>
              </div>
            </li>
          </ul> */}

            <span className="separator" />
            <SwitchCompany />
            <span className="separator" />
            <SwitchEnvironment />
            <span className="separator" />
            <VaritusApps></VaritusApps>

            <div
              id="userbox"
              className={profileBoxClasses.join(" ")}
              onClick={() =>
                this.toggleBoxClassClick(profileBoxClasses, "profile")
              }
            >
              <a href="javascript:void(0);" data-toggle="dropdown">
                {/* <figure className="profile-picture">
                <img src="https://varitus.com.br/src/img/logo.png" alt="Varitus Brasil"
                />
              </figure> */}
                <div className="profile-info">
                  <span className="name">
                    {userName}
                  </span>
                  <span className="role">
                    {maskUtils.formatCnpj(cnpj)} - {companyName}
                  </span>
                </div>
                <i className="fa custom-caret" />
              </a>
              <div className="dropdown-menu">
                <ul className="list-unstyled">
                  <li className="divider" />
                  {/* <li>
                  <a
                    role="menuitem"
                    tabIndex="-1"
                    href="pages-user-profile.html"
                  >
                    <i className="fa fa-user" /> My Profile
                  </a>
                </li> */}
                  {/* <li>
                        <a role="menuitem" tabIndex="-1" href="#" data-lock-screen="true"><i className="fa fa-lock"></i> Lock Screen</a>
                    </li> */}
                  <li>
                    <a
                      href="javascript:void(0);"
                      onClick={() => userSignOut()}
                      role="menuitem"
                      tabIndex="-1"
                    >
                      <i className="fas fa-power-off" /> Sair
                  </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

TopBar.propTypes = {
  logoImage: PropTypes.string,
  userSignOut: PropTypes.func.isRequired
};

export default TopBar;
