import React, { Component } from "react";
import PropTypes from "prop-types";
import TextBox from "../containers/form/TextBox";

import GoogleLogin from 'react-google-login';

class SigningForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: ""
    };
  }

  checkHasError() {
    const { username, password } = this.state;
    if (username && password) {
      return false;
    } else {
      return true;
    }
  }

 responseGoogle = (response) => {
   var token = response.tokenId;
   this.props.googleLogin(token);
}

  render() {
    const { logoImage, login } = this.props;
    const { username, password } = this.state;
    return (
      <section role="main">
        <div className="row">
          <div className="login-component">
            <section className="body-sign">
              <div className="center-sign">
                <a href="/" className="logo pull-left">
                  <img src={logoImage} height="54" alt="Xml Espião" style={{height:"85px",position: "relative", bottom: "20px"}}/>
                </a>
                <div className="panel panel-sign">
                  <div className="panel-title-sign mt-xl text-right">
                    <h2 className="title text-uppercase text-bold m-none">
                      <i className="fas fa-user mr-xs" /> Acessar
                    </h2>
                  </div>
                  <div className="panel-body">
                    <form
                      noValidate
                      className="form"
                      onSubmit={event => {
                        event.preventDefault();
                        login(username, password);
                      }}
                    >
                      <div className="form-group mb-lg">
                        <label>E-mail</label>
                        <div>
                          <div className="input-group input-group-icon">
                            <TextBox
                              id="email"
                              tabIndex="1"
                              name="E-mail"
                              type="text"
                              className="form-control input-lg"
                              value={username}
                              onChange={username =>
                                this.setState(state => ({ ...state, username }))
                              }
                              onBlur={() => {}}
                              validationOption={{
                                name: "E-mail",
                                check: true,
                                required: true,
                                mail: true
                              }}
                            />
                            <span className="input-group-addon">
                              <span className="icon icon-lg">
                                <i className="fas fa-envelope" />
                              </span>
                            </span>
                          </div>
                        </div>
                        <div />
                      </div>

                      <div className="form-group mb-lg">
                        <div className="clearfix">
                          <label className="pull-left">Senha</label>
                        </div>
                        <div className="input-group input-group-icon">
                          <TextBox
                            id="password"
                            tabIndex="2"
                            name="pwd"
                            type="password"
                            className="form-control input-lg"
                            value={password}
                            onChange={password =>
                              this.setState(state => ({ ...state, password }))
                            }
                            onBlur={() => {}}
                            validationOption={{
                              name: "Senha",
                              check: true,
                              required: true
                            }}
                          />
                          <span className="input-group-addon">
                            <span className="icon icon-lg">
                              <i className="fas fa-lock" />
                            </span>
                          </span>
                        </div>
                      </div>

                      <div className="row">
                         {/* <div className="col-sm-8 g-signin2" data-onsuccess={this.onSignIn} data-theme="dark">
                         <div className="checkbox-custom checkbox-default">
                            <input
                              id="RememberMe"
                              name="rememberme"
                              type="checkbox"
                            />
                            <label htmlFor="RememberMe">Lembrar</label>
                          </div> 
                        </div> */}
                        <div className="col-sm-12">
                          <button
                            tabIndex="3"
                            type="submit"
                            className="btn btn-primary btn-block hidden-xs button"
                            disabled={this.checkHasError()}
                          >
                            Acessar
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary btn-block btn-lg visible-xs mt-lg button"
                          >
                            Acessar
                          </button>
                        </div>
                      </div>

                      <GoogleLogin
                        clientId="116043844426-6d6drlfceqisme9v9dav3m3o9glfa29p.apps.googleusercontent.com"
                        buttonText="Login com Google"
                        className="google-login"
                        onSuccess={this.responseGoogle}
                        onFailure={this.responseGoogle}
                      />

                    </form>


                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    );
  }
}

SigningForm.propTypes = {
  logoImage: PropTypes.string.isRequired
};

export default SigningForm;
